import Features from "../../Config/Features";

const menuItems = [
  {
    items: [
      {
        link: "/dashboard",
        text: "Live Matches",
        translation: "livematches",
        icon: "fas fa-play dashboard",
        conditions: [
          { variable: "isAuthenticated", evaluator: true },
          { variable: "hasAccess", evaluator: true },
          { variable: "isStaffScope", evaluator: true }
        ]
      },
      {
        link: "/configuration",
        text: "Configuration",
        translation: "configuration",
        conditions: [
          { variable: "isAuthenticated", evaluator: true },
          { variable: "hasAccess", evaluator: true }
        ],
        children: [
          {
            link: "/configuration/organizations",
            text: "Organizations",
            translation: "organizations",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true }
            ]
          },
          {
            link: "/configuration/bundles",
            text: "Bundles",
            translation: "bundles",
            conditions: [
              { variable: "isAuthenticated", evaluator: true },
              { variable: "hasAccess", evaluator: true },
              { variable: "isStaffScope", evaluator: true }
            ]
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        link: "#",
        text: "Login",
        icon: "fas fa-sign-in-alt",
        function: "login",
        conditions: [{ variable: "isAuthenticated", evaluator: false }]
      },
      {
        link: "#",
        text: "UserProfile",
        component: "userprofile",
        conditions: [{ variable: "isAuthenticated", evaluator: true }]
      },
      {
        link: "#",
        text: "LanguageSelect",
        component: "languageselect"
      },
      {
        link: "#",
        text: "",
        icon: "fas fa-sign-out-alt",
        function: "logout",
        conditions: [{ variable: "isAuthenticated", evaluator: true }]
      }
    ]
  }
];

if (Features.isAvailable("integrations")) {
  // Add to Configuration menu - after Bundles
  menuItems[0].items[1].children.push({
    link: "/configuration/integrations",
    text: "Integrations",
    translation: "integrations",
    conditions: [
      { variable: "isAuthenticated", evaluator: true },
      { variable: "hasAccess", evaluator: true },
      { variable: "isStaffScope", evaluator: true }
    ]
  });
}

export default menuItems;
