import { useMemo } from "react";

/**
 * Describes the rules for displaying certain option types
 * for a given field
 */
const filters = {
  outputResolution: { value: "ADV", isVisible: false },
  feedType: { value: "Primary", isVisible: true },
  audio: { value: "Combined", isVisible: true }
};

/**
 * Performs filtering for a field options list
 * based on the filtering rules
 * @param {object} field
 * @param {object} filters
 */
const adjustFields = (field, filters) => {
  const { value, isVisible } = filters[field.name];
  const source = typeof field.options === "function" ? field.options(field, value, filters) : field.options;
  return {
    ...field,
    options: source.filter((o) => o.text.includes(value) === isVisible)
  };
};

/**
 * Goes through each form and find the fields that need to be adjusted
 * @param {object} filters Set of rules that describes visibility of options
 * @param  {...any} forms Forms that contain resolution info
 */
const adjustForm = (filters, ...forms) =>
  forms.map((form) => ({
    ...form,
    fields: form.fields.map((field) => (filters.hasOwnProperty(field.name) ? adjustFields(field, filters) : field))
  }));

/**
 * Removes restricted video resolutions (ADV-prefixed) for [user-*] scopes;
 * If scope is [staff-*] return the original setup
 * @param {object} formSetup
 * @param {boolean} isStaffScope
 * @returns {Object}
 */
export function withUserAccessRestrictions(formSetup, isStaffScope) {
  if (isStaffScope) {
    return formSetup;
  }
  const [AddSubscription, EditSubscription] = adjustForm(
    filters,
    formSetup.forms.AddSubscription,
    formSetup.forms.EditSubscription
  );
  return {
    ...formSetup,
    forms: { ...formSetup.forms, AddSubscription, EditSubscription }
  };
}

/**
 * Hook-ed version of access control
 * @param {object} formSetup
 * @param {boolean} isStaffScope
 */
export function useUserAccessRestrictions(formSetup, isStaffScope) {
  return useMemo(() => withUserAccessRestrictions(formSetup, isStaffScope), [isStaffScope, formSetup]);
}
