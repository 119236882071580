import { useCallback, useContext, useState, useEffect, useRef } from "react";
import { useToken } from "./token";
import { EnvironmentContext, environments } from "../../../Config/EnvironmentContext";

const defaultReadBody = (body) => body.json();

function combineData(currentData, addedData) {
  let data = currentData.data.concat(addedData.data);
  currentData.data = data;
  return currentData;
}

export const useFetch = (input, opts, refreshFlag = true, location = "location", all = false) => {
  const { token } = useToken();
  const method = opts?.method || "GET";
  const values = opts?.values;
  const readBody = opts?.readyBody || defaultReadBody;

  const [state, setState] = useState({ error: null, loading: refreshFlag, data: null });

  const env = useContext(EnvironmentContext);

  const abortControllerRef = useRef(new AbortController());
  const abortController = abortControllerRef.current;
  const signal = abortController.signal;
  const url = environments[location][env] + input;

  const getData = useCallback(
    async function () {
      // enter pending mode
      const tokenType = token.tokenType.charAt(0).toUpperCase() + token.tokenType.substr(1).toLowerCase();
      const request = {
        method: method || "GET",
        headers: {
          Authorization: tokenType + " " + token.token
        },
        signal: signal,
        body: JSON.stringify(values)
      };
      try {
        const response = await fetch(url, request);
        if (response.ok) {
          const body = await readBody(response);
          // console.debug("Response success", response, body);
          return body;
        }
        console.error("Response error", response);
        const body = await readBody(response);
        const error = new Error("Response error");
        error.detail = { data: body.error };
        throw error;
      } catch (e) {
        if (e.message === "Response error") {
          throw e;
        }
        if (request.signal.aborted) {
          const error = new Error("Request aborted");
          error.detail = { data: { code: 0, message: "Request aborted", errors: [] } };
          throw error;
        } else {
          if (e.message === "Failed to fetch") {
            console.warn("Request internal error", e.message, { url, ...request });
          } else {
            console.error("Request internal error", e.message, e.stack, { url, ...request });
          }
          const error = new Error("Request internal error");
          error.detail = { data: { code: 0, message: "Request internal error", errors: [] } };
          throw error;
        }
      }
    },
    [url, method, values, readBody, signal, token]
  );

  const getAllData = useCallback(
    async function () {
      const results = await getData();
      if (results) {
        if (results.links && results.links.next && all) {
          return combineData(results, await getAllData(results.links.next));
        }
      }
      return results;
    },
    [all, getData]
  );

  useEffect(() => {
    (async () => {
      if (input && refreshFlag && token) {
        // console.debug("Loading starting");
        setState((prev) => ({
          ...prev,
          loading: true
        }));
        try {
          const allData = await getAllData();
          // console.debug("Loading done - success", allData);
          setState((prev) => ({
            ...prev,
            loading: false,
            error: null,
            data: allData
          }));
        } catch (error) {
          // console.debug("Loading done - failure");
          setState((prev) => ({
            ...prev,
            loading: false,
            error: error.detail?.data?.message,
            data: null
          }));
        }
      }
    })();
  }, [input, token, refreshFlag, getAllData]);

  useEffect(() => {
    const abortController = abortControllerRef.current;
    return () => {
      if (abortController) {
        try {
          abortController.abort();
        } catch (error) {
          console.error("Request abort internal error", error.message, error.stack);
        }
      }
    };
  }, []);

  // console.debug("Updating state", { ...state });

  return { ...state };
};
