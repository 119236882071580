import React, { useEffect, useState } from "react";

const InputsRow = (props) => {
  const { result } = props;
  const [tableData, setTableData] = useState();

  useEffect(() => {
    if (result && result.inputs) {
      setTableData(null);

      result.inputs.sort(function (a, b) {
        var aMixed = a.sourceNumber;
        var bMixed = b.sourceNumber;
        return aMixed < bMixed ? -1 : 1;
      });

      let inputsList = result.inputs.map((input, index) => (
        <React.Fragment key={index}>
          <tr>
            <td>
              {input.sourceNumber} - {input.provider} - {input.inputResolution} - {input.feedType}
            </td>
            <td>{input.inputURL}</td>
            <td>{input.streamName}</td>
          </tr>
        </React.Fragment>
      ));

      setTableData(inputsList);
    }
    // eslint-disable-next-line
  }, [result]);

  return (
    <React.Fragment>
      <tr>
        <td colSpan="3">
          <strong>
            {result.teamHome} v. {result.teamAway} - {result.startTimeLocal.replace("T", " ").replace(":00", "")}
          </strong>
        </td>
      </tr>
      {tableData}
    </React.Fragment>
  );
};

export default InputsRow;
