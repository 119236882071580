import React, { useCallback, useEffect, useRef, useState } from "react";
import { Badge, Button } from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { FormBuilder } from "@atriumsports/coreui";

import { useApi } from "../Base/Hooks/api";
import { useFetch } from "../Base/Hooks/fetch";
import { useInterval } from "../Base/Hooks/interval";
import TableDisplay from "../Base/Shared/TableDisplay";

import formSetup from "./formSetup";
import FixtureSubscriptionRow from "./FixtureSubscriptionRow";

export const FixtureSubscriptionsToggle = injectIntl(({ subscriptions, subscriptionsOpen, setSubscriptionsOpen }) => {
  const [badgeColor, setBadgeColor] = useState("danger");
  useEffect(() => {
    if (subscriptions.length > 0) {
      setBadgeColor("success");
    } else {
      setBadgeColor("danger");
    }
  }, [subscriptions]);
  return (
    <React.Fragment>
      {!subscriptionsOpen && (
        <Button
          color="outline-secondary"
          className="outline btn-sm"
          onClick={() => setSubscriptionsOpen(true)}
          data-action="toggle.subscriptions"
        >
          <Badge color={badgeColor} className="badge-pill">
            {subscriptions.length}
          </Badge>
          <FormattedMessage
            id="subscriptions.view"
            defaultMessage="View Subscriptions"
            description="View Subscriptions"
          />
          <i className="fas fa-caret-down"></i>
        </Button>
      )}
      {subscriptionsOpen && (
        <Button
          color="outline-secondary"
          className="outline btn-sm"
          onClick={() => setSubscriptionsOpen(false)}
          data-action="toggle.subscriptions"
        >
          <Badge color={badgeColor} className="badge-pill">
            {subscriptions.length}
          </Badge>
          <FormattedMessage
            id="subscriptions.close"
            defaultMessage="Close Subscriptions"
            description="Close Subscriptions"
          />
          <i className="fas fa-caret-up"></i>
        </Button>
      )}
    </React.Fragment>
  );
});

export const FixtureSubscriptions = injectIntl((props) => {
  const {
    fixtureData,
    sport,
    organizationId,
    seasonId,
    pageRef,
    isOpen,
    intl,
    setRefreshFlag,
    setSilentLoading,
    customerId,
    isStaffScope,
    onBundledAdded
  } = props;
  const api = useApi();
  const { formatMessage } = intl;
  const [tableData, setTableData] = useState([]);
  const [tableDisabled, setTableDisabled] = useState(false);
  const [bundleFormData, setBundleFormData] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const fixtureId = fixtureData.fixtureId;
  const { error, loading, data } = useFetch(
    "/v1/" + sport + "/organizations/" + organizationId + "/fixtures/" + fixtureId + "/output",
    "",
    refreshData
  );

  const [subscriptionData, setSubscriptionData] = useState(null);

  const columns = [
    formatMessage({
      id: "name",
      defaultMessage: "Name"
    }) +
      " / " +
      formatMessage({
        id: "url",
        defaultMessage: "URL"
      }),
    formatMessage({
      id: "stream.name",
      defaultMessage: "Stream Name / Key"
    }),
    formatMessage({
      id: "outputformat",
      defaultMessage: "Output Format"
    }) +
      " / " +
      formatMessage({
        id: "resolution",
        defaultMessage: "Resolution"
      }),
    formatMessage({
      id: "content",
      defaultMessage: "Content"
    }),
    formatMessage({
      id: "audio",
      defaultMessage: "Audio"
    }),
    formatMessage({
      id: "locale",
      defaultMessage: "Locale"
    }),
    ""
  ];

  useInterval(() => {
    if (isOpen) {
      setRefreshData(true);
    }
  }, 7000);

  useEffect(() => {
    if (fixtureData) {
      if (!data) {
        setSubscriptionData(fixtureData.subscriptions);
      }
    }
    // eslint-disable-next-line
  }, [fixtureData]);

  useEffect(() => {
    if (data) {
      setSubscriptionData(data.data);
    }
  }, [data, fixtureId]);

  useEffect(() => {
    if (subscriptionData && Array.isArray(subscriptionData)) {
      subscriptionData.sort(function (a, b) {
        var aMixed = a.subscriptionName;
        var bMixed = b.subscriptionName;
        return aMixed < bMixed ? -1 : 1;
      });
      let tableList = subscriptionData.map((result, index) => (
        <FixtureSubscriptionRow
          key={result.subscriptionId}
          result={result}
          sport={sport}
          fixtureId={fixtureId}
          organizationId={organizationId}
          seasonId={seasonId}
          pageRef={pageRef}
          refreshRows={refreshRows}
          setSilentLoading={setSilentLoading}
          customerId={customerId}
          rowLoading={loading}
          isStaffScope={isStaffScope}
        />
      ));
      setRefreshData(false);
      setTableData(tableList);
      setTableDisabled(false);
      setBundleFormData({
        organizationId: organizationId,
        fixtureId: fixtureId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData]);

  function refreshRows() {
    setRefreshFlag(true);
    setRefreshData(true);
    // setTableDisabled(disableTable);
  }

  const onBundledAddedAction = useCallback(() => {
    setRefreshFlag(true);
    setRefreshData(true);
    if (onBundledAdded) {
      onBundledAdded(fixtureId);
    }
  }, [fixtureId, setRefreshFlag, setRefreshData, onBundledAdded]);

  return (
    <React.Fragment>
      <div className="row col col-md-12">
        <div className={"subscriptions-table add-bundle-form-row disabled-" + tableDisabled}>
          {isStaffScope && bundleFormData && (
            <FormBuilder
              api={api}
              form="AddBundle"
              action={onBundledAddedAction}
              formSetup={formSetup}
              pageRef={pageRef}
              formData={bundleFormData}
            />
          )}
        </div>
        <div className={"col col-md-12 subscriptions-table disabled-" + tableDisabled}>
          <h5>
            <FormattedMessage id="subscriptions" defaultMessage="Subscriptions" description="Subscriptions" />
          </h5>
          <TableDisplay
            table="fixture.subscriptions"
            containerClass="table-responsive"
            columns={columns}
            rows={tableData}
            loading={tableDisabled}
          />
        </div>
      </div>
      {error}
    </React.Fragment>
  );
});
