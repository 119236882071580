import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const SeasonRow = (props) => {
  const { itemIndex, result, match } = props;

  function updateLeague(id, name) {
    props.updateState("currentSeasonId", id);
    props.updateState("currentSeasonName", name);
  }

  return (
    <tr key={itemIndex}>
      <td>
        <Link
          to={
            "/configuration/" +
            match.params.sport +
            "/organizations/" +
            match.params.organizationId +
            "/seasons/" +
            result.seasonId +
            "/fixtures"
          }
          onClick={(e) => updateLeague(result.seasonId, result.seasonName)}
          className="link"
        >
          {result.seasonName}
        </Link>
      </td>
      <td className="text-right controls-3">
        <Link
          to={
            "/configuration/" +
            match.params.sport +
            "/organizations/" +
            match.params.organizationId +
            "/seasons/" +
            result.seasonId +
            "/fixtures"
          }
          onClick={(e) => updateLeague(result.seasonId, result.seasonName)}
          className="btn btn-outline-secondary btn-sm btn-action"
        >
          <FormattedMessage id="fixtures" defaultMessage="Fixtures" description="Fixtures" />
          <i className="fas fa-chevron-right" />
        </Link>
      </td>
    </tr>
  );
};

export default SeasonRow;
