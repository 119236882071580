import React, { useEffect, useState, useRef, useMemo } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useApi } from "../Base/Hooks/api";
import { useFetch } from "../Base/Hooks/fetch";
import formSetup from "./formSetup";

import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import SearchFilter from "../Base/Shared/SearchFilter";

import BundleItemRow from "./BundleItemRow";

import { FormBuilder } from "@atriumsports/coreui";

const SEARCH_COLUMNS = [
  "subscriptionName",
  "streamName",
  "outputURL",
  "provider",
  "content",
  "feedType",
  "audio",
  "locale",
  "sourceNumber",
  "outputResolution"
];

const BundleItems = (props) => {
  const { title, updateState, history, intl, match, layout, setLayout } = props;
  const { formatMessage } = intl;

  const [searchText, setSearchText] = useState("");

  const [state, setState] = useState({
    refreshFlag: true,
    bundle: null,
    bundleRows: [],
    formData: { bundleId: Number(match.params.bundleId) }
  });

  const { refreshFlag, bundle, bundleRows, formData } = state;

  const pageRef = useRef();
  const api = useApi();

  const bundleFetch = useFetch("/v1/bundles/" + match.params.bundleId, "", refreshFlag);
  const bundleRowsFetch = useFetch("/v1/bundles/" + match.params.bundleId + "/rows", "", refreshFlag);

  const loading = bundleFetch.loading || bundleRowsFetch.loading;
  const error = bundleFetch.error || bundleRowsFetch.error;

  const columns = [
    formatMessage({
      id: "name",
      defaultMessage: "Name"
    }) +
      " / " +
      formatMessage({
        id: "url",
        defaultMessage: "URL"
      }),
    formatMessage({
      id: "stream.name",
      defaultMessage: "Stream Name / Key"
    }),
    formatMessage({
      id: "outputformat",
      defaultMessage: "Output Format"
    }) +
      " / " +
      formatMessage({
        id: "resolution",
        defaultMessage: "Resolution"
      }),
    formatMessage({
      id: "content",
      defaultMessage: "Content"
    }),
    formatMessage({
      id: "audio",
      defaultMessage: "Audio"
    }),
    formatMessage({
      id: "locale",
      defaultMessage: "Locale"
    }),
    formatMessage({
      id: "provider",
      defaultMessage: "Provider"
    }),
    formatMessage({
      id: "sourcenumber",
      defaultMessage: "Source Number"
    }),
    ""
  ];

  const bundleData = bundleFetch.data;
  const bundlesData = bundleRowsFetch.data;

  useEffect(() => {
    if (loading) {
      return;
    }
    if (bundleData && bundlesData) {
      setState((prev) => ({
        ...prev,
        refreshFlag: false,
        bundle: bundleData.data,
        bundleRows: bundlesData?.data || []
      }));
    }
  }, [loading, bundleData, bundlesData, searchText]);

  function reloadData() {
    setState((prev) => ({
      ...prev,
      refreshFlag: true
    }));
  }

  const pageDisplayTitle =
    bundle?.name ||
    formatMessage({
      id: "bundle.items",
      defaultMessage: "Bundle Items"
    });

  const filteredRows = useMemo(() => {
    if (searchText !== "") {
      return bundleRows.filter((result) => {
        for (let column of SEARCH_COLUMNS) {
          const haystack = `${result[column]}`.toUpperCase();
          const matching = haystack.indexOf(searchText.toUpperCase()) !== -1;
          if (matching) {
            return true;
          }
        }
        return false;
      });
    }
    return bundleRows;
  }, [searchText, bundleRows]);

  const rows = filteredRows.map((result, index) => (
    <BundleItemRow
      key={index}
      itemIndex={index}
      result={result}
      updateState={updateState}
      pageRef={pageRef}
      setRefreshFlag={(flag) => setState((prev) => ({ ...prev, refreshFlag: flag }))}
    />
  ));

  const pageTitle = formatMessage({
    id: "bundle.items",
    defaultMessage: "Bundle Items"
  });

  return (
    <React.Fragment>
      {loading && <Spinner size="md" color="orange" />}
      {!loading && (
        <PageDisplay
          page="bundle.items"
          title={pageTitle}
          error={error}
          pageTitle={pageTitle}
          ref={pageRef}
          history={history}
          layout={layout}
          setLayout={setLayout}
        >
          <SearchFilter doSearch={setSearchText} title={pageDisplayTitle} />
          <TableDisplay
            table="bundle.items"
            containerClass="table-responsive"
            columns={columns}
            rows={rows}
            loading={loading}
          />
          <hr />
          <FormBuilder
            api={api}
            form="AddBundleRow"
            action={reloadData}
            formSetup={formSetup}
            formData={formData}
            pageRef={pageRef}
            dataId={match.params.bundleId}
          />
        </PageDisplay>
      )}
    </React.Fragment>
  );
};

export default injectIntl(BundleItems);
