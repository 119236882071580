// See https://www.science.co.il/language/Locale-codes.php
const LOCALE = [
  ["be-BY", "Belarusian (Belarus)"],
  ["bs-BA", "Bosnian (Bosnia and Herzegovina)"],
  ["bg-BG", "Bulgarian (Bulgaria)"],
  ["zh-CN", "Chinese (China)"],
  ["zh-HK", "Chinese (Hong Kong SAR)"],
  ["zh-MO", "Chinese (Macau SAR)"],
  ["zh-SG", "Chinese (Singapore)"],
  ["hr-HR", "Croatian (Croatia)"],
  ["cs-CZ", "Czech (Czech Republic)"],
  ["da-DK", "Danish (Denmark)"],
  ["nl-NL", "Dutch (Netherlands)"],
  ["en-EN", "English"],
  ["en-AU", "English (Australia)"],
  ["en-GB", "English (United Kingdom)"],
  ["en-US", "English (United States)"],
  ["et-EE", "Estonian (Estonia)"],
  ["fi-FI", "Finnish (Finland)"],
  ["fr-FR", "French (France)"],
  ["de-DE", "German (Germany)"],
  ["el-GR", "Greek (Greece)"],
  ["he-IL", "Hebrew (Israel)"],
  ["hu-HU", "Hungarian (Hungary)"],
  ["is-IS", "Icelandic (Iceland)"],
  ["id-ID", "Indonesian (Indonesia)"],
  ["it-IT", "Italian (Italy)"],
  ["ja-JP", "Japanese (Japan)"],
  ["ko-KR", "Korean (South Korea)"],
  ["lv-LV", "Latvian (Latvia)"],
  ["lt-LT", "Lithuanian (Lithuania)"],
  ["mk-MK", "Macedonian (Macedonia)"],
  ["ms-MY", "Malay (Malaysia)"],
  ["mt-MT", "Maltese (Malta)"],
  ["nn-NO", "Norwegian Nynorsk (Norway)"],
  ["pl-PL", "Polish (Poland)"],
  ["pt-BR", "Portuguese (Brazil)"],
  ["pt-GW", "Portuguese (Guinea-Bissau)"],
  ["pt-MZ", "Portuguese (Mozambique)"],
  ["pt-PT", "Portuguese (Portugal)"],
  ["ro-RO", "Romanian (Romania)"],
  ["ru-RU", "Russian (Russia)"],
  ["sr-SR", "Serbian (Serbia)"],
  ["sk-SK", "Slovak (Slovakia)"],
  ["sl-SI", "Slovenian (Slovenia)"],
  ["es-MX", "Spanish (Mexico)"],
  ["es-ES", "Spanish (Spain)"],
  ["sv-SE", "Swedish (Sweden)"],
  ["th-TH", "Thai (Thailand)"],
  ["tr-TR", "Turkish (Turkey)"],
  ["uk-UA", "Ukrainian (Ukraine)"],
  ["vi-VN", "Vietnamese (Vietnam)"],
  ["yo-NG", "Yoruba (Nigeria)"],
  ["zu-ZA", "Zulu (South Africa)"]
];

// See https://www.loc.gov/standards/iso639-2/php/code_list.php - Terminology (not Bibliographic!)
const LOCALE_ISO6392_MAPPINGS = {
  be: "bel",
  en: "eng",
  bs: "bos",
  bg: "bul",
  zh: "zho",
  hr: "hrv",
  cs: "cze",
  da: "dan",
  nl: "dut",
  et: "est",
  fi: "fin",
  fr: "fra",
  de: "deu",
  el: "ell",
  he: "heb",
  hu: "hun",
  is: "isl",
  id: "ind",
  it: "ita",
  ja: "jpn",
  ko: "kor",
  lv: "lav",
  lt: "lit",
  mk: "mkd",
  ms: "msa",
  mt: "mlt",
  nn: "nor",
  pl: "pol",
  pt: "por",
  ro: "ron",
  ru: "rus",
  sk: "slk",
  sl: "slv",
  es: "spa",
  sr: "srp",
  sv: "swe",
  th: "tha",
  tr: "tur",
  uk: "ukr",
  vi: "vie",
  yo: "yor",
  zu: "zul"
};

const getISO6392LanguageFromLocale = (locale, defaultLanguage) => {
  let iso6392Language = defaultLanguage || "eng";
  if (locale) {
    const [lang, _] = locale.split("-");
    if (typeof LOCALE_ISO6392_MAPPINGS[lang] !== "undefined") {
      iso6392Language = LOCALE_ISO6392_MAPPINGS[lang];
    } else {
      console.warn("No ISO6392 mapping found for locale", locale, "defaulting to", iso6392Language);
    }
  } else {
    console.warn("No locale provided - defaulting to", iso6392Language);
  }
  return iso6392Language;
};

const getISO6392Languages = () => {
  const mappings = {};
  let list = [];
  LOCALE.forEach(([locale, language]) => {
    const [lang, _] = locale.split("-");
    if (typeof mappings[lang] === "undefined") {
      // Take first match, first term
      const code = LOCALE_ISO6392_MAPPINGS[lang];
      if (typeof code !== "undefined") {
        if (typeof mappings[code] === "undefined") {
          mappings[code] = language.split(" ")[0];
          list.push({ language: mappings[code], iso6392: code, locale: lang });
        }
      }
    }
  });
  list = list.sort((a, b) => a.language.localeCompare(b.language));
  return { mappings, list };
};

const LOCALE_OPTIONS = LOCALE.map((cc) => ({
  text: `${cc[1]} - (${cc[0]})`,
  import: `${cc[1]} - (${cc[0]})`,
  value: cc[0]
}));

export { LOCALE, LOCALE_OPTIONS, LOCALE_ISO6392_MAPPINGS, getISO6392LanguageFromLocale, getISO6392Languages };
