import { LOCALE_OPTIONS } from "../../Config/locales";
import { OUTPUT_FORMATS, computeMuxRate } from "../../Config/output";

import {
  outputResolutionOptionsProvider,
  inputSourceOptionsProvider,
  audioSourcesOptionsProvider,
  feedTypeOptionsProvider,
  programContentOptionsProvider,
} from "../../Config/providers";

const formSetup = {
  forms: {
    NewBundle: {
      title: "Add Bundle",
      titletranslation: "bundle.add",
      endpoint: "v1/bundles",
      parameters: [],
      method: "POST",
      successmessage: "New Bundle added successfully.",
      errormessage: "Unable to add bundle",
      successmessagetranslation: "bundle.add.success",
      link: {
        show: true,
        message: "View Bundle",
        path: "/configuration/bundles/",
        parameters: []
      },
      submit: "Add New Bundle",
      submittranslation: "bundle.add",
      fields: [
        {
          name: "name",
          size: "col-sm-12 col",
          label: "Bundle Name:",
          labeltranslation: "bundle.name",
          type: "text",
          placeholder: "",
          required: true,
          initial: null,
          errors: {
            required: "Bundle Name Is Required"
          }
        }
      ]
    },
    EditBundle: {
      title: "",
      endpoint: "v1/bundles/#0",
      parameters: ["bundleId"],
      method: "PUT",
      successmessage: "Bundle updated successfully.",
      errormessage: "Unable to update bundle.",
      successmessagetranslation: "bundle.update.success",
      link: {
        show: false,
        message: "View Bundle",
        path: "/configuration/bundles/",
        parameters: []
      },
      submit: "Update Bundle",
      submittranslation: "bundle.update",
      fields: [
        {
          name: "name",
          size: "col-sm-12 col",
          label: "Bundle Name:",
          labeltranslation: "bundle.name",
          type: "text",
          placeholder: "",
          required: true,
          initial: "name",
          errors: {
            required: "Bundle Name Is Required"
          }
        }
      ]
    },
    AddBundleRow: {
      title: "Add Bundle Item",
      titletranslation: "bundle.item.add",
      endpoint: "v1/bundles/#0/rows",
      parameters: ["bundleId"],
      method: "POST",
      successmessage: "Bundle Item Added",
      errormessage: "Unable to add item to bundle",
      successmessagetranslation: "bundle.item.added.success",
      submit: "Add Bundle Item",
      submittranslation: "bundle.item.add",
      fields: [
        {
          name: "feedType",
          size: "col-md-6 col",
          label: "Feed Type:",
          labeltranslation: "feedType",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##PRIMARY",
          options: feedTypeOptionsProvider,
          errors: {
            required: "Feed Type Is Required"
          }
        },
        {
          name: "content",
          size: "col-md-6 col",
          label: "Content:",
          labeltranslation: "content",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##PROGRAM",
          options: programContentOptionsProvider,
          errors: {
            required: "Content Is Required"
          }
        },
        {
          name: "provider",
          size: "col-md-4 col",
          label: "Provider:",
          labeltranslation: "provider",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##KEEMOTION",
          options: inputSourceOptionsProvider,
          errors: {
            required: "Provider Is Required"
          }
        },
        {
          name: "locale",
          size: "col-md-4 col",
          label: "Locale:",
          labeltranslation: "locale",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##en-EN",
          options: LOCALE_OPTIONS,
          errors: {
            required: "Locale Is Required"
          }
        },
        {
          name: "sourceNumber",
          size: "col-sm-4 col",
          label: "Source Number:",
          labeltranslation: "sourcenumber",
          type: "number",
          placeholder: "",
          required: true,
          hidden: false,
          initial: "##1",
          min: 0,
          errors: {
            required: "Source Number Is Required"
          }
        },
        {
          name: "bundleId",
          size: "col-sm-8 col",
          label: "Bundle Id:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "dataId",
          errors: {
            required: "bundle Id Is Required"
          }
        },
        {
          name: "audio",
          size: "col-md-4 col",
          label: "Audio:",
          labeltranslation: "audio",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##BOTH",
          options: audioSourcesOptionsProvider,
          errors: {
            required: "Audio Is Required"
          }
        },
        {
          name: "outputFormat",
          size: "col-md-2 col",
          label: "Output Format:",
          labeltranslation: "outputformat",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##RTMP",
          options: OUTPUT_FORMATS,
          errors: {
            required: "Output Format Is Required"
          }
        },
        {
          name: "outputURL",
          size: "col-md-6 col",
          label: "Output URL:",
          labeltranslation: "outputurl",
          type: "text",
          placeholder: "",
          required: (field, values) => ["RTMP", "SRT"].includes(values.outputFormat),
          condition: "outputFormat",
          conditionValue: (field, values) => ["RTMP", "SRT"].includes(field.value),
          initial: "outputURL",
          validate: "streamURL",
          errors: {
            required: "Output URL Is Required",
            validate: "Expected valid value"
          }
        },
        {
          name: "outputResolution",
          size: "col-md-4 col",
          label: "Output Resolution:",
          labeltranslation: "outputresolution",
          type: "select",
          placeholder: "",
          required: (field, values) => ["RTMP", "SRT"].includes(values.outputFormat),
          initial: "##2000",
          options: outputResolutionOptionsProvider,
          errors: {
            required: "Output Resolution Is Required"
          },
          condition: "outputFormat",
          conditionValue: (field, values) => ["RTMP", "SRT"].includes(field.value)
        },
        {
          name: "muxRate",
          size: "col-md-2 col",
          label: "Mux rate:",
          labeltranslation: "mux.rate",
          type: "number",
          placeholder: "in bps",
          required: (field, values) => values.outputFormat === "SRT",
          initial: "muxRate",
          min: 0,
          computeWatch: ["outputFormat", "outputResolution"],
          compute: computeMuxRate,
          errors: {
            required: "Mux Rate Is Required"
          },
          condition: "outputFormat",
          conditionValue: "SRT"
        },
        {
          name: "streamName",
          size: "col-md-3 col",
          label: "Stream Name / Key:",
          labeltranslation: "stream.name",
          type: "text",
          placeholder: "",
          required: (field, values) => values.outputFormat === "RTMP",
          initial: "streamName",
          validate: "streamName",
          errors: {
            validate: "Allowed characters are letters, numbers and the following symbols: . : / & _ ? = ; - @ \\"
          },
          condition: "outputFormat",
          conditionValue: "RTMP"
        },
        {
          name: "subscriptionName",
          size: "col-md-3 col",
          label: "Subscription Name",
          labeltranslation: "subscription.name",
          type: "text",
          placeholder: "",
          required: true,
          initial: "subscriptionName"
        }
      ]
    },
    EditBundleRow: {
      title: "Update Bundle Item",
      titletranslation: "bundle.item.update",
      endpoint: "v1/bundles/#0/rows/#1",
      parameters: ["bundleId", "bundleRowId"],
      method: "PUT",
      successmessage: "Bundle Item Updated",
      successmessage: "Unable to update bundle item",
      successmessagetranslation: "bundle.item.updated",
      submit: "Update Bundle Item",
      submittranslation: "bundle.item.update",
      fields: [
        {
          name: "feedType",
          size: "col-md-6 col",
          label: "Feed Type:",
          labeltranslation: "feedType",
          type: "select",
          placeholder: "",
          required: true,
          initial: "feedType",
          options: feedTypeOptionsProvider,
          errors: {
            required: "Feed Type Is Required"
          }
        },
        {
          name: "content",
          size: "col-md-6 col",
          label: "Content:",
          labeltranslation: "content",
          type: "select",
          placeholder: "",
          required: true,
          initial: "content",
          options: programContentOptionsProvider,
          errors: {
            required: "Content Is Required"
          }
        },
        {
          name: "provider",
          size: "col-md-4 col",
          label: "Provider:",
          labeltranslation: "provider",
          type: "select",
          placeholder: "",
          required: true,
          initial: "provider",
          options: inputSourceOptionsProvider,
          errors: {
            required: "Provider Is Required"
          }
        },
        {
          name: "locale",
          size: "col-md-4 col",
          label: "Locale:",
          labeltranslation: "locale",
          type: "select",
          placeholder: "",
          required: true,
          initial: "locale",
          options: LOCALE_OPTIONS,
          errors: {
            required: "Locale Is Required"
          }
        },
        {
          name: "sourceNumber",
          size: "col-sm-4 col",
          label: "Source Number:",
          labeltranslation: "sourcenumber",
          type: "number",
          placeholder: "",
          required: true,
          hidden: false,
          initial: "sourceNumber",
          min: 0,
          errors: {
            required: "Source Number Is Required"
          }
        },
        {
          name: "bundleId",
          size: "col-sm-8 col",
          label: "Bundle Id:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "bundleId",
          errors: {
            required: "bundle Id Is Required"
          }
        },
        {
          name: "audio",
          size: "col-md-4 col",
          label: "Audio:",
          labeltranslation: "audio",
          type: "select",
          placeholder: "",
          required: true,
          initial: "audio",
          options: audioSourcesOptionsProvider,
          errors: {
            required: "Audio Is Required"
          }
        },
        {
          name: "outputFormat",
          size: "col-md-2 col",
          label: "Output Format:",
          labeltranslation: "outputformat",
          type: "select",
          placeholder: "",
          required: true,
          initial: "outputFormat",
          options: OUTPUT_FORMATS,
          errors: {
            required: "Output Format Is Required"
          }
        },
        {
          name: "outputURL",
          size: "col-md-6 col",
          label: "Output URL:",
          labeltranslation: "outputurl",
          type: "text",
          placeholder: "",
          required: (field, values) => ["RTMP", "SRT"].includes(values.outputFormat),
          condition: "outputFormat",
          conditionValue: (field, values) => ["RTMP", "SRT"].includes(field.value),
          initial: "outputURL",
          validate: "streamURL",
          errors: {
            required: "Output URL Is Required",
            validate: "Expected valid value"
          }
        },
        {
          name: "outputResolution",
          size: "col-md-4 col",
          label: "Output Resolution:",
          labeltranslation: "outputresolution",
          type: "select",
          placeholder: "",
          required: (field, values) => ["RTMP", "SRT"].includes(values.outputFormat),
          initial: "outputResolution",
          options: outputResolutionOptionsProvider,
          errors: {
            required: "Output Resolution Is Required"
          },
          condition: "outputFormat",
          conditionValue: (field, values) => ["RTMP", "SRT"].includes(field.value)
        },
        {
          name: "muxRate",
          size: "col-md-2 col",
          label: "Mux rate:",
          labeltranslation: "mux.rate",
          type: "number",
          placeholder: "in bps",
          required: (field, values) => values.outputFormat === "SRT",
          initial: "muxRate",
          min: 0,
          computeWatch: ["outputFormat", "outputResolution"],
          compute: computeMuxRate,
          errors: {
            required: "Mux Rate Is Required"
          },
          condition: "outputFormat",
          conditionValue: "SRT"
        },
        {
          name: "streamName",
          size: "col-md-3 col",
          label: "Stream Name / Key:",
          labeltranslation: "stream.name",
          type: "text",
          placeholder: "",
          required: (field, values) => values.outputFormat === "RTMP",
          initial: "streamName",
          validate: "streamName",
          errors: {
            validate: "Allowed characters are letters, numbers and the following symbols: . : / & _ ? = ; - @ \\"
          },
          condition: "outputFormat",
          conditionValue: "RTMP"
        },
        {
          name: "subscriptionName",
          size: "col-md-3 col",
          label: "Subscription Name",
          labeltranslation: "subscription.name",
          type: "text",
          placeholder: "",
          required: true,
          initial: "subscriptionName"
        }
      ]
    }
  }
};

export default formSetup;
