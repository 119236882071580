import React, { useCallback, useState, useEffect } from "react";
import { Button } from "reactstrap";
import { injectIntl } from "react-intl";

import { FormBuilder } from "@atriumsports/coreui";

import { useApi } from "../Base/Hooks/api";
import { useDelete } from "../Base/Hooks/delete";
import Modal from "../Base/Modal/Modal";

import formSetup from "./formSetup";
import { useUserAccessRestrictions } from "./utils";

const FixtureSubscriptionRow = (props) => {
  const { result, pageRef, fixtureId, organizationId, sport, seasonId, intl, refreshRows, customerId, isStaffScope } =
    props;

  const api = useApi();

  const { formatMessage } = intl;
  const [rowData, setRowData] = useState(null);
  const [deleteModalText, setDeleteModalText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [editSaved, setEditSaved] = useState(false);
  const [refreshDeleteData, setRefreshDeleteData] = useState(false);
  const [subscriptionDeleteData, setSubscriptionDeleteData] = useState(null);

  const { deleteError, deleteLoading, deleteData } = useDelete(
    "/v1/" +
      sport +
      "/organizations/" +
      organizationId +
      "/fixtures/" +
      fixtureId +
      "/output/" +
      subscriptionDeleteData,
    "",
    refreshDeleteData
  );

  useEffect(() => {
    if (result) {
      if (!showEdit || editSaved) {
        result.fixtureId = fixtureId;
        result.customerId = String(customerId);
        result.organizationId = organizationId;
        setRowData({ ...result });
        setEditSaved(false);
      }
    }
    // eslint-disable-next-line
  }, [result]);

  function showDeleteSubscription(param, item) {
    setDeleteModalText(
      formatMessage(
        {
          id: "subscription.delete.confirm",
          defaultMessage: "You are about to delete subscription " + param + ". Is this OK?"
        },
        { item: param }
      )
    );
    setDeleteModalOpen(true);
    setDeleteItemId(item);
  }

  function cancelModal() {
    setDeleteModalOpen(false);
  }

  function refreshOnSave() {
    refreshRows();
    setEditSaved(true);
  }

  function deleteSubscription() {
    setSubscriptionDeleteData(deleteItemId);
    setRefreshDeleteData(true);
    setDeleteModalOpen(false);
  }

  useEffect(() => {
    if (deleteError) {
      pageRef.current.addFlash(
        formatMessage({
          id: "delete.failed",
          defaultMessage: "Delete Failed"
        }),
        +deleteError,
        "danger",
        10
      );
    } else {
      if (deleteData) {
        pageRef.current.addFlash(
          formatMessage({
            id: "subscription.delete.success",
            defaultMessage: "Subscription deleted successfully"
          }),
          "success",
          10
        );
        setIsDeleted(true);
        refreshRows();
      }
    }
    setSubscriptionDeleteData(null);
    setRefreshDeleteData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteData, deleteError, pageRef]);

  const adjustedFormSetup = useUserAccessRestrictions(formSetup, isStaffScope);

  const onCustomAction = useCallback(
    (action, formik) => {
      if (action.action === "subscriptions.edit.close") {
        setShowEdit(false);
      }
    },
    [setShowEdit]
  );

  return (
    <React.Fragment>
      {rowData && (
        <React.Fragment key={rowData.subscriptionId}>
          <tr
            className={
              "valid-" + rowData.valid + " deleting-" + deleteLoading + " deleted-" + isDeleted + " editing-" + showEdit
            }
          >
            <td>
              {rowData.outputURL && (
                <div className="subscriptionName">
                  {rowData.outputURL.includes("facebook") && <i className="fab fa-facebook-square"></i>}
                  {rowData.outputURL.includes("youtube") && <i className="fab fa-youtube"></i>}
                  {rowData.outputURL.includes("twitch") && <i className="fab fa-twitch"></i>}
                  {rowData.outputURL.includes("pcsp") && <i className="fab fa-periscope"></i>}
                  {rowData.outputURL.includes("pscp") && <i className="fab fa-periscope"></i>}
                  {rowData.outputURL.includes("instagram") && <i className="fab fa-instagram"></i>}
                  {rowData.subscriptionName}
                </div>
              )}
              <div className="subscriptionURL">{rowData.outputURL}</div>
            </td>
            <td>{rowData.streamName}</td>
            <td>
              <div>{rowData.outputFormat}</div>
              <div>{rowData.outputResolution}</div>
            </td>
            <td>
              {rowData.content}
              <br />
              {rowData.feedType}
            </td>
            <td>{rowData.audio}</td>
            <td>
              {rowData.locale}
              <Modal
                isOpen={deleteModalOpen}
                heading={formatMessage({
                  id: "subscription.delete",
                  defaultMessage: "Delete Subscription?"
                })}
                text={deleteModalText}
                actionText={formatMessage({
                  id: "delete",
                  defaultMessage: "Delete"
                })}
                cancelText={formatMessage({
                  id: "cancel",
                  defaultMessage: "Cancel"
                })}
                destructive
                action={deleteSubscription}
                cancel={cancelModal}
              />
            </td>
            <td>
              <Button color="outline-primary" onClick={(e) => setShowEdit(true)} disabled={showEdit}>
                <i className="fas fa-pen"></i>
              </Button>{" "}
              <Button
                color="outline-danger"
                onClick={(e) => showDeleteSubscription(rowData.subscriptionName, rowData.subscriptionId, e)}
                disabled={showEdit}
              >
                <i className="fas fa-minus"></i>
              </Button>
            </td>
          </tr>

          {showEdit && (
            <tr className="update-subscription" data-subscription-id={rowData.subscriptionId}>
              <td colSpan="8" className="update-subscription-form">
                <div className="form-inline-edit-pointer">
                  <i className="fa fa-caret-down" aria-hidden="true"></i>
                </div>
                <FormBuilder
                  api={api}
                  form="EditSubscription"
                  action={refreshOnSave}
                  formSetup={adjustedFormSetup}
                  pageRef={pageRef}
                  formData={rowData}
                  onCustomAction={onCustomAction}
                />
              </td>
            </tr>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default injectIntl(FixtureSubscriptionRow);
