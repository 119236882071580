import { OUTPUT_PROFILES } from "./output";

export const outputResolutionOptionsProvider = (field, values) => {
  if (values.outputFormat === "SRT") {
    return OUTPUT_PROFILES.filter((p) => p.formats === "*" || (p.formats || []).includes("SRT"));
  }
  return OUTPUT_PROFILES.filter((p) => p.formats === "*" || (p.formats || []).includes("RTMP"));
};

export const integrationsOptionsProvider = (field, values, formData) => {
  return formData.integrationOptions
    .map((integration) => {
      return {
        key: integration.integrationId,
        label: integration.name,
        disabled: !integration.active,
        data: integration,
        isEqualTo: (value) => integration.integrationId === value.integrationId,
        encode: () => ({ integrationId: integration.integrationId }),
        children: integration.templates.map((template) => {
          return {
            key: `${template.integrationId}_${template.templateId}`,
            label: template.name,
            disabled: false,
            data: template,
            isEqualTo: (value) =>
              template.integrationId === value.integrationId && template.templateId === value.templateId,
            encode: () => ({ integrationId: template.integrationId, templateId: template.templateId })
          };
        })
      };
    })
    .filter((it) => it.children.length > 0);
};

export const inputSourceOptionsProvider = (field, value) => {
  return [
    {
      title: "Automatic camera system",
      import: "Keemotion",
      text: "Keemotion",
      value: "KEEMOTION"
    },
    {
      title: "Other camera sources",
      import: "Manual",
      text: "Manual",
      value: "MANUAL"
    },
    {
      title: "5stream / Cloud mix",
      import: "5stream",
      text: "5stream",
      value: "5STREAM"
    },
    {
      title: "AV Sportradar",
      import: "AV Sportradar",
      text: "AV Sportradar",
      value: "AV_SPORTRADAR"
    },
    {
      title: "Media transform",
      import: "Transform",
      text: "Transform",
      value: "TRANSFORM"
    },
    {
      title: "Local source",
      import: "Local",
      text: "Local",
      value: "LOCAL"
    },
    {
      title: "For testing and development",
      import: "Test",
      text: "Test",
      value: "Test Provider"
    }
  ];
};

export const audioSourcesOptionsProvider = (field, value) => {
  return [
    {
      text: "Ambience / Commentary Combined",
      import: "Ambience / Commentary Combined",
      value: "BOTH",
      translation: "audio.combined"
    },
    {
      text: "Commentary Only",
      import: "Commentary Only",
      value: "COMMENTARY",
      translation: "audio.commentary"
    },
    {
      text: "Ambience Only",
      import: "Ambience Only",
      value: "AMBIENCE",
      translation: "audio.ambience"
    },
    {
      text: "Ambience left, Commentary right",
      import: "Ambience left, Commentary right",
      value: "BOTH_SPLIT_LR",
      translation: "audio.split"
    }
  ];
};

export const feedTypeOptionsProvider = (field, value) => {
  return [
    {
      text: "Additional Angle",
      import: "Additional Angle",
      translation: "feedType.additional",
      value: "ADDITIONAL_ANGLE"
    },
    {
      text: "Low Latency",
      import: "Low Latency",
      translation: "feedType.lowlatency",
      value: "LOW_LATENCY"
    },
    {
      text: "Primary",
      import: "Primary",
      translation: "feedType.primary",
      value: "PRIMARY"
    }
  ];
};

export const programContentOptionsProvider = (field, value) => {
  return [
    {
      text: "Clean (no enhancement)",
      import: "Clean - no enhancement",
      translation: "content.clean",
      value: "CLEAN"
    },
    {
      text: "Enhanced Output (with overlays)",
      import: "Enhanced Output - with overlays",
      translation: "content.enhanced",
      value: "PROGRAM"
    }
  ];
};
