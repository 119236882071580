import { useMemo, useContext } from "react";
import { EnvironmentContext, environments } from "../../../Config/EnvironmentContext";
import { useToken } from "./token";

export const useApi = () => {
  const { token } = useToken();
  const env = useContext(EnvironmentContext);
  const baseUrl = environments.location[env];
  const tokenType = token?.tokenType;
  const tokenValue = token?.token;
  const authorizedFetch = useMemo(() => {
    if (!baseUrl || !tokenType || !tokenValue) {
      return null;
    }
    return (init, info) => {
      const defaultHeaders = {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(info?.headers || {})
      };
      if (tokenType && tokenValue) {
        const parts = [];
        // capitalize
        parts.push(tokenType.charAt(0).toUpperCase() + tokenType.substr(1).toLowerCase());
        parts.push(tokenValue);
        const Authorization = parts.join(" ");
        if (Authorization) {
          defaultHeaders.Authorization = Authorization;
        }
      }
      let initObject = init;
      if (typeof initObject === "string") {
        if (initObject.indexOf(baseUrl) === -1) {
          initObject = `${baseUrl}${init}`;
        }
      } else {
        throw new Error("Authorized fetch does not support complex request info as first argument");
      }
      const requestObject = {
        ...(info || {}),
        headers: defaultHeaders,
        cors: true
      };
      if (requestObject.data) {
        requestObject.body = JSON.stringify(requestObject.data);
        requestObject.method = requestObject.method || "POST";
        delete requestObject.data;
      }
      return fetch(initObject, requestObject);
    };
  }, [baseUrl, tokenType, tokenValue]);
  return { baseUrl, token, fetch: authorizedFetch };
};
