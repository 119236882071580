import React, { useState, useEffect, useCallback } from "react";

import FixtureDetails from "./FixtureDetails";
import FixtureSummary from "./FixtureSummary";
import { FixtureSubscriptions } from "./FixtureSubscriptions";
import { FixtureIntegrations } from "./FixtureIntegrations";
import FixtureStreams from "./FixtureStreams";
import FixtureInputs from "./FixtureInputs";
import FixtureVideoStreamConfig from "./FixtureVideoStreamConfig";

import "./FixtureRow.scss";

const FixtureRow = (props) => {
  const {
    itemIndex,
    result,
    selectRow,
    pageRef,
    organizationId,
    seasonId,
    integrationOptions,
    setRefreshFlag,
    setSilentLoading,
    customerId,
    isStaffScope,
    sport,
    onBundledAdded
  } = props;
  const [inputsOpen, setInputsOpen] = useState(false);
  const [subscriptionsOpen, setSubscriptionsOpen] = useState(false);
  const [integrationsOpen, setIntegrationsOpen] = useState(false);
  const [streamsOpen, setStreamsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  function handleChange(e) {
    selectRow(itemIndex, e.target.checked);
    setIsSelected(e.target.checked);
  }

  useEffect(() => {
    if (props) {
      if (result.selected) {
        setIsSelected(true);
      } else {
        setIsSelected(false);
      }
    }
    // eslint-disable-next-line
  }, [props]);

  return (
    <tr key={itemIndex} className="fixture-row" data-fixture-id={result.fixtureId} id={`fixture-${result.fixtureId}`}>
      <td className="fixture-select">
        <div className="checkbox">
          <label>
            <input type="checkbox" onChange={handleChange} checked={isSelected} />
            <span className="cr">
              <i className="cr-icon fa fa-check" />
            </span>
          </label>
        </div>
      </td>
      <td className="row">
        <FixtureDetails data={result} />
        <div className="col-lg-4">
          <div className="fixture-details-column">
            <FixtureSummary
              data={result}
              isOpen={subscriptionsOpen}
              inputsOpen={inputsOpen}
              streamsOpen={streamsOpen}
              integrationsOpen={integrationsOpen}
              setInputsOpen={setInputsOpen}
              setStreamsOpen={setStreamsOpen}
              setSubscriptionsOpen={setSubscriptionsOpen}
              setIntegrationsOpen={setIntegrationsOpen}
            />
            <FixtureVideoStreamConfig
              data={result}
              pageRef={pageRef}
              organizationId={organizationId}
              setRefreshFlag={setRefreshFlag}
              isStaffScope={isStaffScope}
            />
          </div>
        </div>
        {subscriptionsOpen && (
          <FixtureSubscriptions
            organizationId={organizationId}
            sport={sport}
            seasonId={seasonId}
            customerId={customerId}
            fixtureData={result}
            integrationOptions={integrationOptions}
            isStaffScope={isStaffScope}
            setRefreshFlag={setRefreshFlag}
            setSilentLoading={setSilentLoading}
            pageRef={pageRef}
            isOpen={subscriptionsOpen}
            setSubscriptionsOpen={setSubscriptionsOpen}
            onBundledAdded={onBundledAdded}
          />
        )}
        {inputsOpen && (
          <FixtureInputs
            organizationId={organizationId}
            sport={sport}
            seasonId={seasonId}
            customerId={customerId}
            fixtureData={result}
            pageRef={pageRef}
            inputsOpen={inputsOpen}
            setRefreshFlag={setRefreshFlag}
            setInputsOpen={setInputsOpen}
          />
        )}
        {streamsOpen && (
          <FixtureStreams
            organizationId={organizationId}
            sport={sport}
            seasonId={seasonId}
            customerId={customerId}
            fixtureData={result}
            pageRef={pageRef}
            streamsOpen={streamsOpen}
            setRefreshFlag={setRefreshFlag}
            setStreamsOpen={setStreamsOpen}
          />
        )}
        {integrationsOpen && (
          <FixtureIntegrations
            organizationId={organizationId}
            sport={sport}
            seasonId={seasonId}
            customerId={customerId}
            fixtureData={result}
            pageRef={pageRef}
            integrationOptions={integrationOptions}
            isStaffScope={isStaffScope}
            isOpen={integrationsOpen}
            setIntegrationsOpen={setIntegrationsOpen}
            setRefreshFlag={setRefreshFlag}
          />
        )}
      </td>
    </tr>
  );
};

export default FixtureRow;
