var formSetup = {
  forms: {
    StartVideoStream: {
      title: "Start VideoStream",
      titletranslation: "videostream.start",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/videostream/start",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "POST",
      successmessage: "VideoStream Starting",
      successmessagetranslation: "videostream.starting",
      submit: "Start",
      submittranslation: "start",
      submitconfirm: "Are you sure you want to start the video stream?",
      submitconfirmtranslation: "start.confirm",
      // headingIcon: "fas fa-check-circle",
      submitIcon: "fas fa-play",
      // submitClass: "btn-danger",
      formClass: "videostream-start",
      fields: [
        {
          name: "locale",
          size: "col-sm-8 col",
          label: "Locale:",
          type: "text",
          placeholder: "Locale",
          required: true,
          hidden: true,
          initial: "locale",
          errors: {
            required: "locale Is Required"
          }
        },
        {
          name: "platformProvider",
          size: "col-sm-8 col",
          label: "platformProvider:",
          type: "text",
          placeholder: "platformProvider",
          required: true,
          hidden: true,
          initial: "platformProvider",
          errors: {
            required: "platformProvider Is Required"
          }
        }
      ]
    },
    StopVideoStream: {
      title: "Stop VideoStream",
      titletranslation: "videostream.stop",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/videostream/stop",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "POST",
      successmessage: "VideoStream Stopping",
      successmessagetranslation: "videostream.stopping",
      submit: "Stop",
      submittranslation: "stop",
      submitconfirm: "Are you sure you want to stop the video stream?",
      submitconfirmtranslation: "stop.confirm",
      // headingIcon: "fas fa-check-circle",
      submitIcon: "fas fa-stop",
      // submitClass: "btn-danger",
      formClass: "videostream-stop",
      fields: []
    },
    ResetVideoStream: {
      title: "Reset VideoStream",
      titletranslation: "videostream.reset",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/videostream/reset",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "POST",
      successmessage: "VideoStream Resetting",
      successmessagetranslation: "videostream.resetting",
      submit: "Reset",
      submittranslation: "reset",
      submitconfirm: "Are you sure you want to reset the video stream?",
      submitconfirmtranslation: "reset.confirm",
      // headingIcon: "fas fa-check-circle",
      submitIcon: "fas fa-clock",
      // submitClass: "btn-danger",
      formClass: "videostream-reset",
      fields: []
    },
    DisconnectVideoStream: {
      title: "Disconnect VideoStream",
      titletranslation: "videostream.disconnect",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/videostream/config",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "PUT",
      successmessage: "VideoStream Disconnecting",
      successmessagetranslation: "videostream.disconnecting",
      submit: "Disconnect",
      submittranslation: "disconnect",
      submitconfirm: "Are you sure you want to disconnect?",
      submitconfirmtranslation: "disconnect.confirm",
      // headingIcon: "fas fa-check-circle",
      submitIcon: "fas fa-calendar-times",
      // submitClass: "btn-danger",
      formClass: "videostream-disconnect",
      fields: [
        {
          name: "locale",
          size: "col-sm-8 col",
          label: "Locale:",
          type: "text",
          placeholder: "Locale",
          required: true,
          hidden: true,
          initial: "locale",
          errors: {
            required: "locale Is Required"
          }
        },
        {
          name: "platformProvider",
          size: "col-sm-8 col",
          label: "platformProvider:",
          type: "text",
          placeholder: "platformProvider",
          required: true,
          hidden: true,
          initial: "platformProvider",
          errors: {
            required: "platformProvider Is Required"
          }
        }
      ]
    },
    HideOverlay: {
      title: "Hide Overlay",
      titletranslation: "overlay.hide",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/videostream/overlay",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "POST",
      successmessage: "Hiding Overlay",
      successmessagetranslation: "overlay.hiding",
      submit: "Hide",
      submittranslation: "hide",
      // headingIcon: "fas fa-check-circle",
      submitIcon: "far fa-eye-slash",
      // submitClass: "btn-danger",
      formClass: "overlay-hide",
      fields: [
        {
          name: "action",
          size: "col-sm-8 col",
          label: "action:",
          type: "text",
          placeholder: "action",
          required: true,
          hidden: true,
          initial: "hideaction",
          errors: {
            required: "action Is Required"
          }
        }
      ]
    },
    ShowOverlay: {
      title: "Show Overlay",
      titletranslation: "overlay.show",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/videostream/overlay",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "POST",
      successmessage: "Showing Overlay",
      successmessagetranslation: "overlay.showing",
      submit: "Show",
      submittranslation: "show",
      submitIcon: "far fa-eye",
      formClass: "overlay-show",
      fields: [
        {
          name: "action",
          size: "col-sm-8 col",
          label: "action:",
          type: "text",
          placeholder: "action",
          required: true,
          hidden: true,
          initial: "showaction",
          errors: {
            required: "action Is Required"
          }
        }
      ]
    }
  }
};
export default formSetup;
