import React, { useCallback, useState } from "react";
import { Container } from "reactstrap";

import { FormBuilder } from "@atriumsports/coreui";

import { useApi } from "../Base/Hooks/api";

import AddSubscriptionFixtureList from "./AddSubscriptionParts/AddSubscriptionFixtureList";
import { useUserAccessRestrictions } from "./utils";
import formSetup from "./formSetup";

const AddSubscriptions = (props) => {
  const {
    formTitle,
    form,
    setAddFormOpen,
    pageRef,
    data,
    initialFormData,
    customerId,
    organizationId,
    isStaffScope,
    disabled,
    disabledMessage,
    onSubscriptionAdded
  } = props;
  const [subscriptionOptions, setSubscriptionOptions] = useState();
  const [formDisabled, setFormDisabled] = useState(false);
  const [subscriptionFixtures, setSubscriptionFixtures] = useState([]);

  const api = useApi();

  const formData = { customerId, ...(initialFormData || {}) };

  function startSubscriptionAdd(values) {
    setSubscriptionOptions(values);
    setFormDisabled(true);
    const selectedFixtures = (data || []).filter(function (fixture) {
      return fixture.selected === true;
    });
    setSubscriptionFixtures(selectedFixtures);
  }

  const onCustomAction = useCallback(
    (action, formik) => {
      if (action.action === "subscriptions.add.close") {
        setAddFormOpen(false);
        setFormDisabled(false);
        setSubscriptionOptions(null);
        setSubscriptionFixtures(null);
      }
    },
    [setAddFormOpen]
  );

  const adjustedFormSetup = useUserAccessRestrictions(formSetup, isStaffScope);

  return (
    <Container className={"subscription-container disabled-" + formDisabled}>
      <FormBuilder
        title={formTitle}
        api={api}
        form={form || "AddSubscription"}
        formSetup={adjustedFormSetup}
        action={startSubscriptionAdd}
        formData={formData}
        pageRef={pageRef}
        disabled={disabled}
        disabledMessage={disabledMessage}
        onCustomAction={onCustomAction}
      />
      {subscriptionFixtures && subscriptionOptions && (
        <AddSubscriptionFixtureList
          options={subscriptionOptions}
          data={subscriptionFixtures}
          organizationId={organizationId}
          onSubscriptionAdded={onSubscriptionAdded}
        />
      )}
    </Container>
  );
};

export default AddSubscriptions;
