import React from "react";
import { Badge } from "reactstrap";

const FeedRow = (props) => {
  const { feed, setPreviewDisplay } = props;
  return (
    <tr key={feed.id} className="feed-row" onClick={(e) => setPreviewDisplay(feed.name)}>
      <td>
        <Badge className={feed.status}>{feed.status}</Badge>
      </td>
      <td>
        <strong>{feed.title}</strong>
        {feed.location}
      </td>
    </tr>
  );
};

export default FeedRow;
