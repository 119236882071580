import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useApi } from "../Base/Hooks/api";
import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import formSetup from "./formSetup";

import { FormBuilder } from "@atriumsports/coreui";

const EditBundleItem = (props) => {
  const { title, history, match, intl } = props;
  const [refreshFlag, setRefreshFlag] = useState(true);

  const [state, setState] = useState({
    keepForm: false,
    bundle: null,
    formData: { bundleId: Number(match.params.bundleId), bundleRowId: Number(match.params.bundleRowId) }
  });

  const api = useApi();

  const bundleFetch = useFetch("/v1/bundles/" + match.params.bundleId, "", refreshFlag);
  const bundleRowFetch = useFetch(
    "/v1/bundles/" + match.params.bundleId + "/rows/" + match.params.bundleRowId,
    "",
    refreshFlag
  );

  const loading = bundleFetch.loading || bundleRowFetch.loading;
  const error = bundleFetch.error || bundleRowFetch.error;

  const { formatMessage } = intl;

  const bundleData = bundleFetch.data;
  const bundleRowData = bundleRowFetch.data;

  useEffect(() => {
    if (loading) {
      return;
    }
    if (bundleData && bundleRowData) {
      setRefreshFlag(false);
      setState((prev) => ({
        ...prev,
        keepForm: true,
        bundle: bundleData?.data,
        formData: {
          ...prev.formData,
          ...bundleRowData.data
        }
      }));
    }
  }, [loading, bundleData, bundleRowData]);

  function reloadData() {
    setRefreshFlag(true);
  }

  const { keepForm, bundle, formData } = state;
  const pageTitle = bundle?.name || title;

  return (
    <React.Fragment>
      {!keepForm && loading && <Spinner size="md" color="orange" />}
      {keepForm && (
        <PageDisplay
          page="bundle.item.edit"
          title={pageTitle}
          error={error}
          pageTitle={formatMessage({
            id: "bundle.item.update",
            defaultMessage: "Update Bundle Item"
          })}
          history={history}
        >
          <FormBuilder
            api={api}
            form="EditBundleRow"
            action={reloadData}
            formSetup={formSetup}
            formData={formData}
            disabled={loading}
          />
        </PageDisplay>
      )}
    </React.Fragment>
  );
};

export default injectIntl(EditBundleItem);
