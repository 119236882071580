import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const OrganizationRow = (props) => {
  const { itemIndex, result } = props;

  function updateLeague(id, name) {
    props.updateState("currentOrganizationId", id);
    props.updateState("currentOrganizationName", name);
  }

  return (
    <tr key={itemIndex}>
      <td>{result.organizationId}</td>
      <td>
        <Link
          to={"/configuration/" + result.sport + "/organizations/" + result.organizationId + "/competitions"}
          onClick={(e) => updateLeague(result.organizationId, result.name)}
          className="link"
        >
          {result.name}
        </Link>
      </td>

      <td className="text-right controls-3">
        <Link
          to={"/configuration/" + result.sport + "/organizations/" + result.organizationId + "/competitions"}
          onClick={(e) => updateLeague(result.organizationId, result.name)}
          className="btn btn-outline-secondary btn-sm btn-action"
        >
          <FormattedMessage id="competitions" defaultMessage="Competitions" description="Competitions" />
          <i className="fas fa-chevron-right" />
        </Link>
      </td>
    </tr>
  );
};

export default OrganizationRow;
