import React from "react";

import MenuBuilder from "../MenuBuilder/MenuBuilder";
import menuItems from "../../Menus/FooterMenuItems";

import "./Footer.scss";

const Footer = (props) => {
  const { setup, token } = props;
  let date = new Date();
  let year = date.getFullYear();

  return (
    <footer>
      <span>
        &copy; {year}
        {setup && setup.copyright && <React.Fragment> - {setup.copyright}</React.Fragment>}
      </span>
      <MenuBuilder
        color="light"
        expand="md"
        light={true}
        className="footer-nav"
        menuItems={menuItems}
        isOpen={true}
        data={props}
        token={token}
      />
    </footer>
  );
};

export default Footer;
