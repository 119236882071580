import React from "react";
import { Badge } from "reactstrap";

const DestinationRow = (props) => {
  const { destination } = props;
  return (
    <tr key={destination.id} className="destination-row">
      <td>
        <Badge className={destination.status}>{destination.status}</Badge>
      </td>
      <td>
        <strong>{destination.title}</strong>
        {destination.rtmp_location}
        <br />
        {destination.stream_name}
      </td>
    </tr>
  );
};

export default DestinationRow;
