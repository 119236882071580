import React, { useState, useEffect, useMemo, useRef } from "react";
import { Container } from "reactstrap";
import { Route, Redirect } from "react-router-dom";
import { useAuth0 } from "./Auth/react-auth0-wrapper";
import { HelmetProvider } from "react-helmet-async";

import { CheckStaffPermissions } from "./Components/Base/Extras/Utilities/CheckScope";

import defaultSetupConfiguration from "./Config/defaultSetupConfiguration";
import { EnvironmentContext } from "./Config/EnvironmentContext";

import Organizations from "./Components/Organizations/Organizations";
import Competitions from "./Components/Competitions/Competitions";
import Seasons from "./Components/Seasons/Seasons";
import Fixtures from "./Components/Fixtures/Fixtures";
import Dashboard from "./Components/Dashboard/Dashboard";
import Bundles from "./Components/Bundles/Bundles";
import EditBundle from "./Components/Bundles/EditBundle";
import BundleItems from "./Components/Bundles/BundleItems";
import EditBundleItem from "./Components/Bundles/EditBundleItem";

import IntegrationsView from "./Components/Integrations/IntegrationsView";
import IntegrationSetup from "./Components/Integrations/IntegrationSetup";
import IntegrationEdit from "./Components/Integrations/IntegrationEdit";
import IntegrationTemplates from "./Components/Integrations/IntegrationTemplates";
import IntegrationTemplateEdit from "./Components/Integrations/IntegrationTemplateEdit";

import headerMenuItems from "./Components/Menus/HeaderMenuItems";
import sideDrawerItems from "./Components/Menus/SideDrawerItems";

import Login from "./Components/Base/Login/Login";
import Header from "./Components/Base/Header/Header";
import Sidedrawer from "./Components/Base/Sidedrawer/Sidedrawer";
import Footer from "./Components/Base/Footer/Footer";
import UserProfile from "./Components/Base/UserProfile/UserProfile";
import LanguageSelect from "./Components/Base/LanguageSelect/LanguageSelect";

import "./App.scss";

import sideLogo from "./Components/Base/Assets/Atrium-Negative.png";

import Features from "./Config/Features";

const App = (props) => {
  const { environment, language, locale } = props;
  const { loading, user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const title = "Video Stream Manager";
  const setup = defaultSetupConfiguration;
  const [mainState, setMainState] = useState({});
  const [token, setToken] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);
  const [products, setProducts] = useState({});
  const [fluidLayout, setFluidLayout] = useState(false);
  const [darkMode, setDarkMode] = useState();

  function updateState(key, data) {
    let thisState = mainState;
    thisState[key] = data;
    setMainState(thisState);
  }

  /** Only [staff-*] scopes should have access to some parts of the application */
  const isStaffScope = useMemo(() => CheckStaffPermissions(token), [token]);

  const logoutWithRedirect = () => {
    setToken(null);
    setHasAccess(false);
    if (token) {
      localStorage.removeItem("productList_" + token.customerId);
    }
    let tokenName = setup.options.subProduct
      ? setup.options.subProduct.replace(/\s/g, "_").toLowerCase() + "_" + setup.options.productCode + "_tokenDetails"
      : setup.options.productCode + "_tokenDetails";
    localStorage.removeItem(tokenName);
    logout({
      returnTo: window.location.origin
    });
  };

  useEffect(() => {
    if (darkMode !== "undefined") {
      if (darkMode === true) {
        document.body.classList.add("darkmode-true");
        document.body.classList.remove("darkmode-false");
      } else {
        document.body.classList.add("darkmode-false");
        document.body.classList.remove("darkmode-true");
      }
    }
    // eslint-disable-next-line
  }, [darkMode]);

  // useEffect(() => {
  //   console.log(user);
  //   // eslint-disable-next-line
  // }, [user]);

  useEffect(() => {
    let storedDarkMode = localStorage.getItem("darkmode") === "true" ? true : false;
    setDarkMode(storedDarkMode);
    // eslint-disable-next-line
  }, []);

  /** Default redirection route, that can be reconfigured if needed */
  const defaultRoute = "/configuration/organizations";

  /**
   * Little helper to reduce redirection boilerplate
   * @param {string} from
   * @param {string} to
   */
  const defaultRedirect = (from, to = defaultRoute) => <Route exact path={from} render={() => <Redirect to={to} />} />;

  /** Group the bundle routes for conditional rendering */
  const renderBundleRoutes = () => {
    return (
      <>
        <Route
          exact
          path={"/configuration/bundles"}
          render={(props) => <Bundles {...props} updateState={updateState} title={title} />}
        />
        <Route
          exact
          path={"/configuration/bundles/:bundleId/edit"}
          render={(props) => <EditBundle {...props} updateState={updateState} title={title} />}
        />
        <Route
          exact
          path={"/configuration/bundles/:bundleId"}
          render={(props) => (
            <BundleItems
              {...props}
              updateState={updateState}
              title={title}
              layout={fluidLayout}
              setLayout={setFluidLayout}
            />
          )}
        />
        <Route
          exact
          path={"/configuration/bundles/:bundleId/edit/:bundleRowId"}
          render={(props) => <EditBundleItem {...props} updateState={updateState} title={title} />}
        />
      </>
    );
  };

  const renderOrganizationRoutes = () => (
    <>
      <Route
        exact
        path={"/configuration/organizations"}
        render={(props) => <Organizations {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/configuration/:sport/organizations/:organizationId/competitions"}
        render={(props) => <Competitions {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/configuration/:sport/organizations/:organizationId/competitions/:competitionId/seasons"}
        render={(props) => <Seasons {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/configuration/:sport/organizations/:organizationId/seasons/:seasonId/fixtures"}
        render={(props) => (
          <Fixtures
            {...props}
            updateState={updateState}
            title={title}
            layout={fluidLayout}
            setLayout={setFluidLayout}
            customerId={token.customerId}
            isStaffScope={isStaffScope}
          />
        )}
      />
    </>
  );

  const renderIntegrationRoutes = () => (
    <>
      <Route
        exact
        path={"/configuration/integrations/:integrationId/edit"}
        render={(props) => <IntegrationEdit {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/configuration/integrations/:integrationId/templates/:templateId/edit"}
        render={(props) => <IntegrationTemplateEdit {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/configuration/integrations/:integrationId/templates"}
        render={(props) => <IntegrationTemplates {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={
          "/configuration/:sport/organizations/:organizationId/seasons/:seasonId/fixtures/:fixtureId/integrations/:integrationId/setup"
        }
        render={(props) => <IntegrationSetup {...props} updateState={updateState} title={title} />}
      />
      <Route
        exact
        path={"/configuration/integrations"}
        render={(props) => <IntegrationsView {...props} updateState={updateState} title={title} />}
      />
    </>
  );

  const renderDashboardRoute = () => (
    <Route
      exact
      path={"/dashboard"}
      render={(props) => (
        <Dashboard
          {...props}
          updateState={updateState}
          title={title}
          layout={fluidLayout}
          setLayout={setFluidLayout}
          isStaffScope={isStaffScope}
          // darkmode={darkMode}
          // setDarkMode={setDarkMode}
        />
      )}
    />
  );

  let integrationsView;
  if (Features.isAvailable("integrations")) {
    integrationsView = <>{isStaffScope ? renderIntegrationRoutes() : defaultRedirect("/dashboard")}</>;
  }

  return (
    <EnvironmentContext.Provider value={environment}>
      <HelmetProvider>
        <Header
          menuItems={headerMenuItems}
          title={title}
          login={loginWithRedirect}
          logout={logoutWithRedirect}
          isAuthenticated={isAuthenticated}
          hasAccess={hasAccess}
          isStaffScope={isStaffScope}
          userprofile={<UserProfile {...props} user={user} />}
          languageselect={<LanguageSelect setup={setup} locale={locale} language={language} />}
        />
        <Sidedrawer
          menuItems={sideDrawerItems}
          logout={logoutWithRedirect}
          isAuthenticated={isAuthenticated}
          hasAccess={hasAccess}
          isStaffScope={isStaffScope}
          products={products}
          logo={sideLogo}
        />
        {!loading && (
          <Container fluid={fluidLayout} className="mainbody">
            <Login
              login={loginWithRedirect}
              setup={setup}
              loading={loading}
              isAuthenticated={isAuthenticated}
              setToken={setToken}
              setHasAccess={setHasAccess}
              token={token}
              setProducts={setProducts}
            />
            {isAuthenticated && token && (
              <React.Fragment>
                {defaultRedirect("/")}
                {renderOrganizationRoutes()}
                {isStaffScope ? renderDashboardRoute() : defaultRedirect("/dashboard")}
                {isStaffScope ? renderBundleRoutes() : defaultRedirect("/dashboard")}
                {integrationsView}
              </React.Fragment>
            )}
          </Container>
        )}
        <Footer setup={setup} />
      </HelmetProvider>
    </EnvironmentContext.Provider>
  );
};

export default App;
