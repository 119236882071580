import React, { useEffect, useState, useRef, useCallback, createRef } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import readXlsxFile from 'read-excel-file'
import { useToasts } from "react-toast-notifications";

import { FormBuilder } from "@atriumsports/coreui";

import { useApi } from "../Base/Hooks/api";
import { useFetch } from "../Base/Hooks/fetch";

import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import SearchFilter from "../Base/Shared/SearchFilter";

import BundleRow from "./BundleRow";
import formSetup from "./formSetup";
import { LOCALE_OPTIONS } from "../../Config/locales";

import {
  inputSourceOptionsProvider,
  audioSourcesOptionsProvider,
  feedTypeOptionsProvider,
  programContentOptionsProvider,
} from "../../Config/providers";

import { OUTPUT_FORMATS, OUTPUT_RESOLUTIONS_ALL,  computeMuxRate } from "../../Config/output";

const IMPORT_SCHEMA = {
  'Bundle name': {
    prop: 'name',
    type: String,
    required: true
  },
  "Provider": {
    prop: "provider",
    type: String,
    required: true,
    oneOf: inputSourceOptionsProvider().map(it => it.import),
    source: inputSourceOptionsProvider()
  },
  "Locale": {
    prop: "locale",
    type: String,
    required: true,
    oneOf: LOCALE_OPTIONS.map(it => it.import),
    source: LOCALE_OPTIONS
  },
  "Source number": {
    prop: "sourceNumber",
    type: String,
    required: true
  },
  "Audio": {
    prop: "audio",
    type: String,
    required: true,
    oneOf: audioSourcesOptionsProvider().map(it => it.import),
    source: audioSourcesOptionsProvider()
  },
  "Feed type": {
    prop: "feedType",
    type: String,
    required: true,
    oneOf: feedTypeOptionsProvider().map(it => it.import),
    source: feedTypeOptionsProvider()
  },
  "Output format": {
    prop: "outputFormat",
    type: String,
    required: true,
    oneOf: OUTPUT_FORMATS.map(it => it.import),
    source: OUTPUT_FORMATS
  },
  "Output URL": {
    prop: "outputURL",
    type: String,
    required: true
  },
  "Output resolution": {
    prop: "outputResolution",
    type: String,
    required: true,
    oneOf: OUTPUT_RESOLUTIONS_ALL.map(it => it.import),
    source: OUTPUT_RESOLUTIONS_ALL
  },
  "Subscription name": {
    prop: "subscriptionName",
    type: String,
    required: true
  },
  "Stream name": {
    prop: "streamName",
    type: String,
    required: true
  },
  "Content": {
    prop: "content",
    type: String,
    required: true,
    oneOf: programContentOptionsProvider().map(it => it.import),
    source: programContentOptionsProvider()
  },
};

export const getOptionByImportKey = (importKey, schemaKey) => {
  const source = IMPORT_SCHEMA[schemaKey]?.source || [];
  const sourceMap = source.reduce((acc, it) => {
    acc[it.import] = it.value;
    return acc;
  }, {});
  return sourceMap[importKey];
}

const importBundles = async (api, file) => {
  const { rows, errors } = await readXlsxFile(file, { sheet: 'Data', schema: IMPORT_SCHEMA });
  const computedRows = rows.map((row) => {
    row.provider = getOptionByImportKey(row.provider, "Provider");
    row.locale = getOptionByImportKey(row.locale, "Locale");
    row.audio = getOptionByImportKey(row.audio, "Audio");
    row.feedType = getOptionByImportKey(row.feedType, "Feed type");
    row.outputFormat = getOptionByImportKey(row.outputFormat, "Output format");
    row.outputResolution = getOptionByImportKey(row.outputResolution, "Output resolution");
    row.content = getOptionByImportKey(row.content, "Content");
    row.muxRate = computeMuxRate({}, { outputResolution : row.outputResolution });
    row.sourceNumber = Number(row.sourceNumber);
    return row;
  })
  if (errors.length > 0) {
    console.error("Unable to import", { rows: computedRows, errors });
    throw new Error("Unable to import")
  }
  const bundles = [];
  const bundlesMap = {};
  computedRows.forEach((row) => {
    let bundle = bundlesMap[row.name];
    if (!bundle) {
      bundlesMap[row.name] = {
        name: row.name,
        rows: []
      };
      bundle = bundlesMap[row.name];
      bundles.push(bundle);
    }
    const bundleRow = { ...row };
    delete bundleRow.name;
    bundle.rows.push(bundleRow);
  })
  const result = await api.fetch("/v1/bundles-import", {
    method: "POST",
    body: JSON.stringify(bundles),
  });
  return result;
};

const Bundles = (props) => {
  const { title, updateState, history, intl } = props;
  const { formatMessage } = intl;
  const api = useApi();
  const bundlesClickHandlerRef = createRef();
  const bundlesImportRef = useRef();
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [bundleData, setBundleData] = useState();
  const [isImporting, setIsImporting] = useState(false);
  const pageRef = useRef();
  const { error, loading, data } = useFetch("/v1/bundles", "", refreshFlag);
  const { addToast } = useToasts();
  const columns = [
    formatMessage({
      id: "bundleid",
      defaultMessage: "Bundle Id"
    }),
    formatMessage({
      id: "name",
      defaultMessage: "Name"
    }),
    ""
  ];
  const onImportClick = useCallback(() => {
    const input  = bundlesImportRef.current;
    if (input) {
      input.removeEventListener("change", bundlesClickHandlerRef.current);
      bundlesClickHandlerRef.current = async () => {
        input.removeEventListener("change", bundlesClickHandlerRef.current);
        try {
          setIsImporting(true);
          const imported = await importBundles(api, input.files[0]);
          setIsImporting(false);
          if (imported.ok) {
            addToast("Bundles import completed", {
              appearance: "success",
              autoDismiss: true,
            });
            setRefreshFlag(true)
          } else {
            console.error("Bundle import failed");
            throw new Error("Unable to import");
          }
        } catch (error) {
          setIsImporting(false);
          console.error("Unable to import", error);
          addToast("Bundles import failed", {
            appearance: "error",
            autoDismiss: false,
          });
        }
      };
      input.addEventListener('change', bundlesClickHandlerRef.current);
      input.click();
    }
    // eslint-disable-next-line
  }, [api, addToast]);

  useEffect(() => {
    if (data) {
      let bundles = data !== null ? data.data : [];

      if (searchText !== "") {
        bundles = bundles.filter((result) => {
          return (
            result.name.toUpperCase().indexOf(searchText.toUpperCase()) > -1 ||
            result.bundleId.toString().toUpperCase().indexOf(searchText.toUpperCase()) > -1
          );
        });
      }
      let bundleList = bundles.map((result, index) => (
        <BundleRow
          key={index}
          itemIndex={index}
          result={result}
          updateState={updateState}
          pageRef={pageRef}
          setRefreshFlag={setRefreshFlag}
        />
      ));
      setBundleData(bundleList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  useEffect(() => {
    const input  = bundlesImportRef.current;
    const clickHandler = bundlesClickHandlerRef.current;
    return () => {
      if (input && clickHandler) {
        input.removeEventListener("change", clickHandler);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function reloadData() {
    setRefreshFlag(true);
  }

  return (
    <PageDisplay
      page="bundles"
      title={formatMessage({
        id: "bundles",
        defaultMessage: "Bundles"
      })}
      error={error}
      pageTitle={
        formatMessage({
          id: "bundles",
          defaultMessage: "Bundles"
        }) +
        " - " +
        title
      }
      ref={pageRef}
      actions={
        <>
          {isImporting ? null : <input ref={bundlesImportRef} type="file" id="bundles-import-input" style={{display: "none"}} /> }
          <button disabled={isImporting} onClick={onImportClick} className="btn btn-outline-secondary btn-sm import-button">
            <FormattedMessage id="import.button" defaultMessage="Import" description="Import Button" />
          </button>
        </>
      }
      history={history}
    >
      <SearchFilter doSearch={setSearchText} />
      <TableDisplay
        table="bundles"
        containerClass="table-responsive"
        columns={columns}
        rows={bundleData}
        loading={loading}
      />
      <hr />
      <FormBuilder api={api} form="NewBundle" action={reloadData} formSetup={formSetup} pageRef={pageRef} />
    </PageDisplay>
  );
};

export default injectIntl(Bundles);
