import React, { useEffect, useState } from "react";

import { useApi } from "../Base/Hooks/api";

import formSetup from "./formSetup";

import { FormBuilder } from "@atriumsports/coreui";

const VideoStreamConfig = (props) => {
  const api = useApi();
  const { pageRef, data, organizationId, setRefreshFlag, isStaffScope } = props;
  const [formData, setFormData] = useState(null);
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    if (data) {
      let tempData = {};
      tempData["organizationId"] = organizationId;
      tempData["fixtureId"] = data.fixtureId;
      if (data.configuration[0]) {
        tempData["locale"] = data.configuration[0].locale;
        tempData["platformProvider"] = data.configuration[0].platformProvider;
      }
      setFormData(tempData);
      let fixtureDate = new Date(Date.parse(data.startTimeUTC, "Y-m-d g:i a"));
      let next24 = 1000 * 60 * 60 * 24 + Date.now();
      if (fixtureDate < next24) {
        setShowControls(true);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  function reloadData() {
    setRefreshFlag(true);
  }

  function reloadDataSilent() {
    // setRefreshFlag(true);
  }

  return (
    <div className="fixture-stream">
      {formData && !data.liveVideoAvailable && (
        <FormBuilder
          api={api}
          form="EnableVideoStream"
          formSetup={formSetup}
          action={reloadData}
          formData={formData}
          pageRef={pageRef}
        />
      )}
      {formData && data.liveVideoAvailable && (
        <FormBuilder
          api={api}
          form="DisableVideoStream"
          formSetup={formSetup}
          action={reloadData}
          formData={formData}
          pageRef={pageRef}
        />
      )}
      {isStaffScope && showControls && (
        <div className="videostream-controls">
          <FormBuilder
            api={api}
            form="StartVideoStream"
            formSetup={formSetup}
            action={reloadDataSilent}
            formData={formData}
            pageRef={pageRef}
          />
        </div>
      )}
    </div>
  );
};

export default VideoStreamConfig;
