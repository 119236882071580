import React, { useCallback } from "react";
import { InputGroup, Input, Label, InputGroupAddon } from "reactstrap";
import { injectIntl } from "react-intl";

import "./SearchFilter.scss";

const SearchFilter = (props) => {
  const { doSearch, intl, initial, title } = props;
  const { formatMessage } = intl;
  const onSearch = useCallback(
    (e) => {
      if (doSearch) {
        doSearch(e.target.value);
      }
    },
    [doSearch]
  );
  return (
    <nav className="navbar searchNavbar">
      <div className="navbar-nav mr-auto">{title}</div>
      <form className="form-inline searchForm">
        <InputGroup className="customerSearch">
          <InputGroupAddon addonType="prepend">
            <Label for="search">
              <i className="fas fa-search" />
            </Label>
          </InputGroupAddon>
          <Input
            className="form-control"
            type="search"
            name="search"
            id="search"
            value={initial}
            placeholder={formatMessage({
              id: "search",
              defaultMessage: "search"
            })}
            onChange={onSearch}
          />
        </InputGroup>
      </form>
    </nav>
  );
};

export default injectIntl(SearchFilter);
