import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useApi } from "../Base/Hooks/api";
import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import formSetup from "./formSetup";

import { FormBuilder } from "@atriumsports/coreui";

const IntegrationTemplateEdit = (props) => {
  const { title, history, match, intl } = props;

  const [state, setState] = useState({
    refreshFlag: true,
    integration: null,
    integrationTemplate: null,
    integrationProvidersOptions: []
  });

  const api = useApi();

  const { refreshFlag, integration, integrationTemplate, integrationProvidersOptions } = state;

  const integrationTemplateResourceUrl = `/v1/integrations/${match.params.integrationId}/templates/${match.params.templateId}`;
  const integrationRequest = useFetch(`/v1/integrations/${match.params.integrationId}`, "", refreshFlag);
  const integrationTemplateRequest = useFetch(integrationTemplateResourceUrl, "", refreshFlag);
  const integrationProvidersOptionsRequest = useFetch("/v1/integration/providers", "", refreshFlag);

  const { formatMessage } = intl;

  const isLoading =
    integrationRequest.loading || integrationTemplateRequest.loading || integrationProvidersOptionsRequest.loading;
  const error =
    integrationRequest.error || integrationTemplateRequest.error || integrationProvidersOptionsRequest.error;

  const integrationData = integrationRequest.data;
  const integrationTemplateData = integrationTemplateRequest.data;
  const integrationProvidersOptionsData = integrationProvidersOptionsRequest.data;

  const onFormAction = useCallback(() => {
    setState((prev) => ({ ...prev, refreshFlag: true }));
  }, []);

  const onCustomAction = useCallback(
    async (action, formProps, confirmed) => {
      if (confirmed && action.action === "remove.template") {
        formProps.setSubmitting(true);
        formProps.setError(null);
        try {
          const response = await api.fetch(integrationTemplateResourceUrl, {
            method: "DELETE"
          });
          if (response.ok) {
            setState((prev) => ({ ...prev, integrationTemplate: null }));
            history.goBack();
          } else {
            console.error("Unexpected response", response);
            throw new Error("Unexpected response");
          }
        } catch (error) {
          console.error("Integration template remove internal error", error);
          formProps.setError(`Remove error - ${error.message}`);
        } finally {
          formProps.setSubmitting(false);
        }
      }
    },
    [api, history, integrationTemplateResourceUrl]
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (integrationData?.data && integrationTemplateData?.data && integrationProvidersOptionsData?.data) {
      const integration = integrationData.data;
      const integrationTemplate = integrationTemplateData.data;
      const integrationProvidersOptions = integrationProvidersOptionsData.data;
      setState((prev) => ({
        ...prev,
        refreshFlag: false,
        integration,
        integrationTemplate,
        integrationProvidersOptions
      }));
    }
  }, [isLoading, integrationData, integrationTemplateData, integrationProvidersOptionsData]);

  const pageTitle =
    title ||
    formatMessage({
      id: "integration.template.update",
      defaultMessage: "Update Integration Template"
    });

  const formData = { ...integrationTemplate, integrationProvidersOptions };

  return (
    <PageDisplay
      page="integration.template.edit"
      title={pageTitle}
      error={error}
      pageTitle={pageTitle}
      history={history}
    >
      {integration && (
        <div className="EditIntegrationTemplateView">
          <h4>{integration.name}</h4>
          <FormBuilder
            api={api}
            form="EditIntegrationTemplate"
            action={onFormAction}
            formSetup={formSetup}
            formData={formData}
            onCustomAction={onCustomAction}
          />
          {error}
        </div>
      )}
    </PageDisplay>
  );
};

export default injectIntl(IntegrationTemplateEdit);
