import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const CompetitionRow = (props) => {
  const { itemIndex, result, match } = props;

  return (
    <tr key={itemIndex}>
      <td>
        <Link
          to={
            "/configuration/" +
            match.params.sport +
            "/organizations/" +
            match.params.organizationId +
            "/competitions/" +
            result.competitionId +
            "/seasons"
          }
          className="link"
        >
          {result.competitionName}
        </Link>
      </td>

      <td className="text-right controls-3">
        <Link
          to={
            "/configuration/" +
            match.params.sport +
            "/organizations/" +
            match.params.organizationId +
            "/competitions/" +
            result.competitionId +
            "/seasons"
          }
          className="btn btn-outline-secondary btn-sm btn-action"
        >
          <FormattedMessage id="seasons" defaultMessage="Seasons" description="Seasons" />
          <i className="fas fa-chevron-right" />
        </Link>
      </td>
    </tr>
  );
};

export default CompetitionRow;
