import React, { useCallback, useMemo, useState } from "react";
import { Button } from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";

import { useApi } from "../Base/Hooks/api";
import TableDisplay from "../Base/Shared/TableDisplay";
import Modal from "../Base/Modal/Modal";

const FEATURE_STREAM_DELETE = true;

const FixtureStreams = (props) => {
  const { organizationId, seasonId, customerId, sport, fixtureData, intl, setRefreshFlag } = props;
  const { formatMessage } = intl;
  const [confirmModal, setConfirmModal] = useState(null);

  const api = useApi();

  const columns = [
    formatMessage({
      id: "name",
      defaultMessage: "Name"
    }),
    formatMessage({
      id: "provider",
      defaultMessage: "Provider"
    }),
    formatMessage({
      id: "resolution",
      defaultMessage: "Resolution"
    }),
    formatMessage({
      id: "content",
      defaultMessage: "Content"
    }),
    formatMessage({
      id: "ambience",
      defaultMessage: "Ambience"
    }),
    formatMessage({
      id: "commentary",
      defaultMessage: "Commentary"
    }),
    formatMessage({
      id: "locale",
      defaultMessage: "Locale"
    }),
    formatMessage({
      id: "source",
      defaultMessage: "Source"
    }),
    ""
  ];

  const onRemoveRequestClick = useCallback((stream) => {
    setConfirmModal({ data: stream });
  }, []);
  const onStreamRemoveAccept = useCallback(
    async (stream) => {
      try {
        const fixtureId = fixtureData.fixtureId;
        const serviceUrl = `/v1/${sport}/organizations/${organizationId}/seasons/${seasonId}/fixtures/${fixtureId}/inputs/${stream.videoInputId}`;
        const response = await api.fetch(serviceUrl, {
          method: "DELETE"
        });
        if (!response.ok) {
          console.error("Unexpected response", response);
          throw new Error("Unexpected response");
        }
      } catch (error) {
        console.error("Input remove remove internal error", error);
      } finally {
        setConfirmModal(null);
        setRefreshFlag(true);
      }
    },
    [api, organizationId, seasonId, sport, fixtureData, setRefreshFlag]
  );
  const onStreamRemoveCancel = useCallback(() => {
    setConfirmModal(null);
  }, []);

  const tableData = useMemo(() => {
    if (!fixtureData || !Array.isArray(fixtureData.availableStreams)) {
      return null;
    }
    const streams = fixtureData.availableStreams;
    return streams.map((stream) => {
      const key = Object.values(stream)
        .map((it) => `${it}`)
        .join("_");
      return (
        <tr key={key} data-video-input-id={stream.videoInputId} data-content={stream.content}>
          <td>{stream.name}</td>
          <td>{stream.provider}</td>
          <td>{stream.inputResolution}</td>
          <td>
            {stream.content} {stream.feedType}
          </td>
          <td>{stream.audioAmbience && "Ambience"}</td>
          <td>{stream.audioCommentary && "Commentary"}</td>
          <td width="1">{stream.locale}</td>
          <td width="1">{stream.sourceNumber}</td>
          <td width="1">
            {FEATURE_STREAM_DELETE ? (
              <Button color="btn btn-outline-danger btn-sm" onClick={() => onRemoveRequestClick(stream)}>
                <i className="fas fa-minus" />
              </Button>
            ) : null}
          </td>
        </tr>
      );
    });
  }, [fixtureData, onRemoveRequestClick]);

  return (
    <div className="row col col-md-12">
      <div className={"col col-md-12 streams-table"}>
        <h5>
          <FormattedMessage id="streams.available" defaultMessage="Streams" description="Available Streams" />
        </h5>
        <TableDisplay table="fixture.streams" containerClass="table-responsive" columns={columns} rows={tableData} />
      </div>

      <Modal
        isOpen={!!confirmModal}
        heading={formatMessage({
          id: "input.remove.confirm",
          defaultMessage: "Delete Input"
        })}
        text={formatMessage({
          id: "are.you.sure",
          defaultMessage: "Are you sure?"
        })}
        actionText={formatMessage({
          id: "delete",
          defaultMessage: "Delete"
        })}
        cancelText={formatMessage({
          id: "cancel",
          defaultMessage: "Cancel"
        })}
        destructive
        data={confirmModal ? confirmModal.data : null}
        action={onStreamRemoveAccept}
        cancel={onStreamRemoveCancel}
      />
    </div>
  );
};

export default injectIntl(FixtureStreams);
