import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import Modal from "../Base/Modal/Modal";
import { useDelete } from "../Base/Hooks/delete";

const BundleItemRow = (props) => {
  const { itemIndex, result, pageRef, setRefreshFlag, intl } = props;
  const [deleteModalText, setDeleteModalText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const [refreshDeleteData, setRefreshDeleteData] = useState(false);
  const [bundleItemDeleteData, setBundleItemDeleteData] = useState(null);
  const { deleteError, deleteLoading, deleteData } = useDelete(
    "/v1/bundles/" + result.bundleId + "/rows/" + bundleItemDeleteData,
    "",
    refreshDeleteData
  );
  const { formatMessage } = intl;

  useEffect(() => {
    if (deleteError) {
      pageRef.current.addFlash(
        formatMessage({
          id: "delete.failed",
          defaultMessage: "Delete Failed"
        }),
        +deleteError,
        "danger",
        10
      );
    } else {
      if (deleteData) {
        pageRef.current.addFlash(
          formatMessage({
            id: "bundle.item.delete.success",
            defaultMessage: "Bundle Item deleted successfully"
          }),
          "success",
          10
        );
        setRefreshFlag(true);
      }
    }
    setRefreshDeleteData(false);
    setBundleItemDeleteData(null);
    setRefreshDeleteData(false);
    // eslint-disable-next-line
  }, [deleteData, deleteError, pageRef]);

  function showDeleteBundleItem(param) {
    setDeleteModalText(
      formatMessage(
        {
          id: "bundle.item.delete.confirm",
          defaultMessage: "You are about to delete bundle item " + param + ". Is this OK?"
        },
        { item: param }
      )
    );
    setDeleteModalOpen(true);
    setDeleteItemId(param);
  }

  function cancelModal() {
    setDeleteModalOpen(false);
  }

  function deleteBundleItem() {
    setBundleItemDeleteData(deleteItemId);
    setRefreshDeleteData(true);
    setDeleteModalOpen(false);
  }

  return (
    <React.Fragment key={itemIndex}>
      <tr key={result.subscriptionId} className={"valid-" + result.valid + " deleting-" + deleteLoading}>
        <td>
          {result.outputURL && (
            <div className="subscriptionName">
              {result.outputURL.includes("facebook") && <i className="fab fa-facebook-square"></i>}
              {result.outputURL.includes("youtube") && <i className="fab fa-youtube"></i>}
              {result.outputURL.includes("twitch") && <i className="fab fa-twitch"></i>}
              {result.outputURL.includes("pcsp") && <i className="fab fa-periscope"></i>}
              {result.outputURL.includes("pscp") && <i className="fab fa-periscope"></i>}
              {result.outputURL.includes("instagram") && <i className="fab fa-instagram"></i>}
              {result.subscriptionName}
            </div>
          )}
          <div className="subscriptionURL">{result.outputURL}</div>
        </td>
        <td>{result.streamName}</td>
        <td>
          <div>{result.outputFormat}</div>
          <div>{result.outputResolution}</div>
        </td>
        <td>
          {result.content}
          <br />
          {result.feedType}
        </td>
        <td>{result.audio}</td>
        <td>{result.locale}</td>
        <td>{result.provider}</td>
        <td>{result.sourceNumber}</td>
        <td className="controls-3">
          <Link
            to={"/configuration/bundles/" + result.bundleId + "/edit/" + result.bundleRowId}
            className="btn btn-outline-primary btn-sm"
          >
            <i className="fas fa-pen" />
          </Link>{" "}
          <Button outline color="danger" size="sm" onClick={(e) => showDeleteBundleItem(result.bundleRowId, e)}>
            <i className="fas fa-minus" />
          </Button>
          <Modal
            modal="bundle.item.delete"
            isOpen={deleteModalOpen}
            heading={formatMessage({
              id: "bundle.item.delete",
              defaultMessage: "Delete Bundle?"
            })}
            text={deleteModalText}
            actionText={formatMessage({
              id: "delete",
              defaultMessage: "Delete"
            })}
            cancelText={formatMessage({
              id: "cancel",
              defaultMessage: "Cancel"
            })}
            destructive
            action={deleteBundleItem}
            cancel={cancelModal}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

export default injectIntl(BundleItemRow);
