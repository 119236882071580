import React, { useState, useEffect } from "react";

import FeedRow from "./FeedRow";
import TableDisplay from "../../Base/Shared/TableDisplay";

const Feeds = (props) => {
  const { feeds, setPreviewDisplay } = props;
  const [tableData, setTableData] = useState();
  const columns = ["Status", "Feed"];

  useEffect(() => {
    if (feeds) {
      setTableData(null);
      let feedList = feeds.map((feed, index) => (
        <FeedRow key={feed.id} itemIndex={index} feed={feed} setPreviewDisplay={setPreviewDisplay} />
      ));
      setTableData(feedList);
    }
    // eslint-disable-next-line
  }, [feeds]);

  return (
    <React.Fragment>
      <div className="destinationsContainer">
        <TableDisplay
          table="feeds"
          containerClass="table-responsive dashboard-destinations"
          columns={columns}
          rows={tableData}
          fixed={false}
        />
      </div>
    </React.Fragment>
  );
};

export default Feeds;
