import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { FormBuilder } from "@atriumsports/coreui";

import { useApi } from "../Base/Hooks/api";
import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";

import formSetup from "./formSetup";

const EditBundle = (props) => {
  const { title, history, match, intl } = props;
  const [refreshData, setRefreshData] = useState(true);
  const [currentBundle, setCurrentBundle] = useState(null);
  const api = useApi();
  const { error, loading, data } = useFetch("/v1/bundles/" + match.params.bundleId, "", refreshData);
  const { formatMessage } = intl;

  useEffect(() => {
    if (data) {
      setRefreshData(false);
      setCurrentBundle(data.data);
    }
    // eslint-disable-next-line
  }, [data]);

  function reloadData() {
    setRefreshData(true);
  }

  let pageDisplayTitle = formatMessage({
    id: "bundle.update",
    defaultMessage: "Update Bundle"
  });
  if (currentBundle?.name) {
    pageDisplayTitle = `${pageDisplayTitle} - ${currentBundle.name}`;
  }

  return (
    <PageDisplay page="bundle.edit" title={pageDisplayTitle} error={error} pageTitle={title} history={history}>
      {loading && <Spinner size="md" color="orange" />}
      {currentBundle && (
        <div>
          {currentBundle && (
            <FormBuilder
              api={api}
              form="EditBundle"
              action={reloadData}
              formSetup={formSetup}
              formData={currentBundle}
            />
          )}
          {error}
        </div>
      )}
    </PageDisplay>
  );
};

export default injectIntl(EditBundle);
