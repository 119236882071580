import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import DashboardRow from "./DashboardRow";
import { useFetch } from "../Base/Hooks/fetch";
import { useInterval } from "../Base/Hooks/interval";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import FilterDisplay from "../Base/Shared/FilterDisplay";
import { useFilterData } from "../Base/Hooks/filterdata";
import "./Dashboard.scss";

const Dashboard = (props) => {
  const { title, updateState, history, intl, layout, setLayout, darkmode, setDarkMode, isStaffScope } = props;
  const { formatMessage } = intl;
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [silentLoading, setSilentLoading] = useState(false);
  const [masterData, setMasterData] = useState([]);
  const [refreshRows, setRefreshRows] = useState(true);
  const [tableData, setTableData] = useState();
  const [showOnlyRunning, setShowOnlyRunning] = useState(false);
  const columns = null;
  const pageRef = useRef();

  const { error, loading, data } = useFetch("/v1/dashboard", "", refreshFlag);

  const [filterFields, setFilterFields] = useState({
    organizationName: "",
    venueName: "",
    teamHome: "",
    teamAway: ""
  });
  const { filteredData } = useFilterData(masterData, filterFields);

  useEffect(() => {
    setMasterData(data?.data || []);
    setRefreshFlag(false);
  }, [data]);

  function handleFilter(field, value) {
    let newFilter = {
      ...filterFields,
      [field]: value
    };
    setFilterFields(newFilter);
    setRefreshRows(true);
  }

  useInterval(() => {
    setRefreshFlag(true);
    setSilentLoading(true);
  }, 10000);

  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      filteredData.forEach((fixture, index) => {
        let matchDate = fixture.startTimeLocal.split("T");
        filteredData[index].date = matchDate[0];
        if (fixture.competitors.length > 1) {
          if (fixture.competitors[0].isHome) {
            filteredData[index].teamHome = fixture.competitors[0].entityName;
            filteredData[index].teamAway = fixture.competitors[1].entityName;
          } else {
            filteredData[index].teamHome = fixture.competitors[1].entityName;
            filteredData[index].teamAway = fixture.competitors[0].entityName;
          }
        }
      });
      setTableData(null);
      let matchList = filteredData.map((data, index) => (
        <React.Fragment key={data.fixtureId}>
          {(!showOnlyRunning || (data.video && data.video.engineStatus === "running")) && (
            <DashboardRow
              itemIndex={index}
              data={data}
              updateState={updateState}
              pageRef={pageRef}
              setRefreshFlag={setRefreshFlag}
              isStaffScope={isStaffScope}
            />
          )}
        </React.Fragment>
      ));
      setTableData(matchList);
      setRefreshFlag(false);
      setSilentLoading(false);
    }
    // eslint-disable-next-line
  }, [filteredData, refreshRows, showOnlyRunning]);

  function toggleSelect(e) {
    let value = e.target.checked;
    setSilentLoading(false);
    setShowOnlyRunning(value);
    setRefreshFlag(false);
  }

  return (
    <React.Fragment>
      <PageDisplay
        title={formatMessage({
          id: "livematches",
          defaultMessage: "Live Matches"
        })}
        error={error}
        pageTitle={
          formatMessage({
            id: "livematches",
            defaultMessage: "Live Matches"
          }) +
          " - " +
          title
        }
        ref={pageRef}
        history={history}
        layout={layout}
        setLayout={setLayout}
        darkmode={darkmode}
        setDarkMode={setDarkMode}
      >
        {masterData.length > 0 && (
          <React.Fragment>
            <div className="checkbox inline">
              <label>
                <input type="checkbox" onChange={toggleSelect} />
                <span className="cr">
                  <i className="cr-icon fa fa-check" />
                </span>
                <FormattedMessage
                  id="show.runningonly"
                  defaultMessage="Show Running Only"
                  description="Show Running Only"
                />
              </label>
            </div>
            <FilterDisplay
              data={masterData}
              display={formatMessage({
                id: "organizations.all",
                defaultMessage: "All Organizations"
              })}
              value="organizationName"
              selectAction={handleFilter}
            />
            <FilterDisplay
              data={masterData}
              display={formatMessage({
                id: "venues.all",
                defaultMessage: "All Venues"
              })}
              value="venueName"
              selectAction={handleFilter}
            />
          </React.Fragment>
        )}
        <TableDisplay
          table="dashboard"
          containerClass="table-responsive live-table"
          columns={columns}
          rows={tableData}
          loading={loading && !silentLoading}
          fixed={true}
        />
        {loading}
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(Dashboard);
