import { OUTPUT_FORMATS } from "../../Config/output";

import { getISO6392Languages } from "../../Config/locales";

import {
  feedTypeOptionsProvider,
  programContentOptionsProvider,
  inputSourceOptionsProvider,
  outputResolutionOptionsProvider
} from "../../Config/providers";

const config_decoder = (values) => {
  // Takes all keys from config key and flattens them into the original object
  const encoding = { ...values };
  const { config } = encoding;
  delete encoding.config;
  Object.entries(config || {}).forEach(([key, value]) => (encoding[key] = value));
  return encoding;
};

const integration_encoder = (values) => {
  const encoding = { ...values };
  const wrap = ["client_id", "client_secret", "organization_api_key"];
  encoding.config = wrap.reduce((acc, it) => {
    acc[it] = encoding[it];
    delete encoding[it];
    return acc;
  }, {});
  return encoding;
};

const integration_template_decoder = (values) => {
  // Takes all keys from config key and flattens them into the original object
  const encoding = { ...values };
  const { config } = encoding;
  delete encoding.config;
  const { language, stream } = config || {};
  encoding.language = language;
  Object.entries(stream || {}).forEach(([key, value]) => (encoding[key] = value));
  return encoding;
};

const integration_template_encoder = (values) => {
  const encoding = { ...values };
  const wrap = ["subscriptionName", "locale", "provider", "feedType", "content", "sourceNumber", "outputResolution"];
  const stream = wrap.reduce((acc, it) => {
    acc[it] = encoding[it];
    delete encoding[it];
    return acc;
  }, {});
  delete encoding.language;
  encoding.config = { language: values.language, stream };
  return encoding;
};

const providerTypesOptionsProvider = (field, values, formData) => {
  const options = formData.integrationProvidersOptions.map((p) => ({
    text: p.name,
    value: p.name,
    disabled: !p.enabled
  }));
  return [
    {
      value: "",
      text: "-- select --"
    }
  ].concat(options);
};

const cloudRegionsOptionsProvider = (field, values, formData) => {
  const source = formData.integrationProvidersOptions || [];
  const spalkProvider = source.find((it) => it.name === "Spalk");
  const spalkProviderOptions = (spalkProvider?.settings?.regions || []).map((it) => ({
    value: it.region,
    text: it.name,
    disabled: it.mapping !== null
  }));
  return [
    {
      value: "",
      text: "-- select --"
    }
  ].concat(spalkProviderOptions);
};

const languagesOptionsProvider = () => {
  const { list } = getISO6392Languages();
  const languages = list.map((item) => ({
    value: item.iso6392,
    text: item.language
  }));
  return [
    {
      value: "",
      text: "-- select --"
    }
  ].concat(languages);
};

const integrationInputSourceOptionsProvider = (field, value) => {
  // exclude commentaries from being commentated
  const items = inputSourceOptionsProvider(field, value);
  return items.filter((it) => it.value !== "TRANSFORM");
};

const formSetup = {
  forms: {
    NewIntegration: {
      title: "New Integration",
      titletranslation: "integration.create",
      endpoint: "v1/integrations",
      parameters: [],
      method: "POST",
      successmessage: "New Integration added successfully.",
      successmessagetranslation: "integration.add.success",
      link: {
        show: true,
        message: "View Integration",
        path: "/configuration/integrations/",
        parameters: []
      },
      submit: "Add integration",
      submittranslation: "integration.add",
      fields: [
        {
          name: "name",
          size: "col-sm-8 col",
          label: "Name:",
          labeltranslation: "integration.name",
          type: "text",
          placeholder: "",
          required: true,
          initial: null,
          errors: {}
        },
        {
          name: "provider",
          size: "col-sm-8 col",
          label: "Provider:",
          labeltranslation: "integration.provider",
          type: "select",
          required: true,
          initial: "##SPALK",
          options: providerTypesOptionsProvider,
          errors: {}
        },
        {
          name: "client_id",
          size: "col-sm-8 col",
          label: "Client Id:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: false,
          initial: null,
          errors: {}
        },
        {
          name: "client_secret",
          size: "col-sm-8 col",
          label: "Client Secret:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: false,
          initial: null,
          errors: {},
          help: "This value will be encrypted and never read back"
        },
        {
          name: "organization_api_key",
          size: "col-sm-8 col",
          label: "Organization Api Key:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: false,
          initial: null,
          errors: {},
          help: "This value will be encrypted and never read back"
        },
        {
          name: "active",
          size: "col-sm-8 col",
          label: "Active",
          type: "checkbox",
          placeholder: "",
          required: false,
          hidden: false,
          initial: null,
          default: true,
          errors: {}
        }
      ],
      encoder: integration_encoder
    },
    EditIntegration: {
      title: "Edit integration",
      titletranslation: "integration.edit",
      endpoint: "v1/integrations/#0",
      parameters: ["integrationId"],
      method: "PUT",
      successmessage: "Integration updated successfully.",
      successmessagetranslation: "integration.update.success",
      link: {
        show: false,
        message: "View Integration",
        path: "/configuration/integrations/",
        parameters: []
      },
      submit: "Update Integration",
      submittranslation: "integration.update",
      fields: [
        {
          name: "name",
          size: "col-sm-8 col",
          label: "Name:",
          labeltranslation: "integration.name",
          type: "text",
          placeholder: "",
          required: true,
          readOnly: true,
          initial: "name",
          errors: {}
        },
        {
          name: "provider",
          size: "col-sm-8 col",
          label: "Provider:",
          labeltranslation: "integration.provider",
          type: "text",
          required: true,
          readOnly: true,
          initial: "provider",
          errors: {}
        },
        {
          name: "client_id",
          size: "col-sm-8 col",
          label: "Client Id:",
          type: "text",
          placeholder: "",
          required: false,
          hidden: false,
          initial: null,
          errors: {}
        },
        {
          name: "client_secret",
          size: "col-sm-8 col",
          label: "Client Secret:",
          type: "password",
          placeholder: "**********",
          required: false,
          hidden: false,
          initial: null,
          errors: {},
          help: "Leave unchanged to keep existing value"
        },
        {
          name: "organization_api_key",
          size: "col-sm-8 col",
          label: "Organization Api Key:",
          type: "password",
          placeholder: "**********",
          required: false,
          hidden: false,
          initial: null,
          errors: {},
          help: "Leave unchanged to keep existing value"
        },
        {
          name: "active",
          size: "col-sm-8 col",
          label: "Active",
          type: "checkbox",
          placeholder: "",
          required: false,
          hidden: false,
          initial: null,
          errors: {}
        }
      ],
      customActions: [
        {
          action: "test.integration",
          label: "Test Integration",
          color: "primary"
        }
      ],
      decoder: config_decoder,
      encoder: integration_encoder
    },
    IntegrationSetupCreate: {
      title: "Integration setup",
      titletranslation: "integration.setup",
      endpoint: "v1/integrations/#0/setup",
      parameters: ["integrationId"],
      method: "POST",
      fields: [
        {
          name: "sport",
          type: "hidden",
          required: true,
          hidden: true,
          initial: "sport"
        },
        {
          name: "organizationId",
          type: "hidden",
          required: true,
          hidden: true,
          initial: "organizationId"
        },
        {
          name: "seasonId",
          type: "hidden",
          required: true,
          hidden: true,
          initial: "seasonId"
        },
        {
          name: "fixtureId",
          type: "hidden",
          required: true,
          hidden: true,
          initial: "fixtureId"
        },
        {
          name: "subscriptionId",
          type: "hidden",
          required: true,
          hidden: true,
          initial: "subscriptionId"
        },
        {
          name: "title",
          size: "col-sm-8 col",
          label: "Title",
          labeltranslation: "integration.setup.title",
          help: "A human readable name for the event. This is used when emailing invited users.",
          type: "text",
          placeholder: "",
          required: true,
          initial: "title"
        },
        {
          name: "language",
          size: "col-sm-8 col",
          label: "Language:",
          labeltranslation: "integration.setup.language",
          help: "Spalk commentator language, see ISO 639-2 standard - inferred from destination / subscription locale.",
          type: "select",
          required: true,
          initial: "language",
          options: languagesOptionsProvider
        },
        {
          name: "region",
          size: "col-sm-8 col",
          label: "Region:",
          labeltranslation: "integration.setup.region",
          help: "Spalk ingest server AWS region - only supported regions can be used.",
          type: "select",
          required: true,
          initial: "region",
          options: cloudRegionsOptionsProvider
        }
      ],
      submit: "Create",
      submittranslation: "integration.setup"
    },
    IntegrationSetupUpdate: {
      title: "Integration setup",
      titletranslation: "integration.setup",
      endpoint: "v1/integrations/#0/setup",
      parameters: ["integrationId"],
      method: "POST",
      fields: [
        {
          name: "sport",
          type: "hidden",
          required: true,
          hidden: true,
          initial: "sport"
        },
        {
          name: "organizationId",
          type: "hidden",
          required: true,
          hidden: true,
          initial: "organizationId"
        },
        {
          name: "seasonId",
          type: "hidden",
          required: true,
          hidden: true,
          initial: "seasonId"
        },
        {
          name: "fixtureId",
          type: "hidden",
          required: true,
          hidden: true,
          initial: "fixtureId"
        },
        {
          name: "subscriptionId",
          type: "hidden",
          required: true,
          hidden: true,
          initial: "subscriptionId"
        },
        {
          name: "title",
          size: "col-sm-8 col",
          label: "Title",
          labeltranslation: "integration.setup.title",
          help: "A human readable name for the event. This is used when emailing invited users.",
          type: "text",
          placeholder: "",
          required: true,
          initial: "title"
        },
        {
          name: "language",
          size: "col-sm-8 col",
          label: "Language:",
          labeltranslation: "integration.setup.language",
          help: "Spalk commentator language, see ISO 639-2 standard - inferred from destination / subscription locale.",
          type: "select",
          required: true,
          initial: "language",
          options: languagesOptionsProvider
        },
        {
          name: "region",
          size: "col-sm-8 col",
          label: "Region:",
          labeltranslation: "integration.setup.region",
          help: "Spalk ingest server AWS region - only supported regions can be used.",
          type: "select",
          required: true,
          initial: "region",
          options: cloudRegionsOptionsProvider
        },
        {
          name: "inputURL",
          size: "col-sm-8 col",
          label: "Input URL",
          labeltranslation: "integration.setup.inputURL",
          help: "Spalk ingest address where clean slate fixture subscription / destination stream is expected to be sent.",
          type: "text",
          placeholder: "",
          required: false,
          readOnly: true,
          clipboard: true,
          initial: "inputURL"
        },
        {
          name: "commentatorAppURL",
          size: "col-sm-8 col",
          label: "Commentator application URL",
          labeltranslation: "integration.setup.commentatorAppURL",
          help: "Spalk tokenized URL for anonymous access to the commentator studio.",
          type: "text",
          placeholder: "",
          required: false,
          readOnly: true,
          clipboard: true,
          openURL: true,
          initial: "commentatorAppURL"
        }
      ],
      submit: "Update",
      submittranslation: "integration.setup.update",
      customActions: [
        {
          action: "remove.integration",
          label: "Remove",
          labeltranslation: "integration.setup.remove",
          color: "outline-danger",
          icon: "fas fa-times",
          confirm: true
        }
      ]
    },
    // integration templates
    NewIntegrationTemplate: {
      title: "Integration template",
      titletranslation: "integration.template",
      endpoint: "v1/integrations/#0/templates",
      parameters: ["integrationId"],
      method: "POST",
      fields: [
        // row 0
        {
          name: "name",
          size: "col-sm-12 col",
          label: "Name",
          labeltranslation: "integration.template.name",
          help: "Use a name that allows easy identification of the purpose of this template.",
          type: "text",
          placeholder: "",
          required: true,
          initial: ""
        },
        // row 1
        {
          name: "language",
          size: "col-sm-12 col",
          label: "Language:",
          labeltranslation: "integration.template.language",
          help: "Audio commentator language.",
          type: "select",
          required: true,
          initial: "",
          options: languagesOptionsProvider
        },
        // row 2
        {
          name: "subscriptionName",
          size: "col-sm-3 col",
          label: "Subscription Name",
          labeltranslation: "subscriptionName",
          type: "text",
          placeholder: "",
          required: true,
          initial: ""
        },
        {
          name: "provider",
          size: "col-sm-2 col",
          label: "Provider:",
          labeltranslation: "provider",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##KEEMOTION",
          options: integrationInputSourceOptionsProvider
        },
        {
          name: "feedType",
          size: "col-sm-3 col",
          label: "Feed Type:",
          labeltranslation: "feedType",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##PRIMARY",
          options: feedTypeOptionsProvider
        },
        {
          name: "content",
          size: "col-sm-4 col",
          label: "Content:",
          labeltranslation: "content",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##PROGRAM",
          options: programContentOptionsProvider
        },
        // row 3
        {
          name: "outputResolution",
          size: "col-sm-8 col",
          label: "Output Resolution:",
          labeltranslation: "outputresolution",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##1080",
          options: outputResolutionOptionsProvider
        },
        {
          name: "sourceNumber",
          size: "col-sm-2 col",
          label: "Source Number:",
          labeltranslation: "sourcenumber",
          type: "number",
          placeholder: "",
          required: true,
          hidden: false,
          initial: "##1",
          min: 0
        },
        {
          name: "locale",
          size: "col-sm-2 col",
          label: "Locale:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: false,
          initial: "##en-EN"
        }
      ],
      submit: "Create Template",
      submittranslation: "integration.template.create",
      encoder: integration_template_encoder
    },
    EditIntegrationTemplate: {
      title: "Integration template",
      titletranslation: "integration.template",
      endpoint: "v1/integrations/#0/templates/#1",
      parameters: ["integrationId", "templateId"],
      method: "PUT",
      fields: [
        // row 0
        {
          name: "name",
          size: "col-sm-12 col",
          label: "Name",
          labeltranslation: "integration.template.name",
          help: "Use a name that allows easy identification of the purpose of this template.",
          type: "text",
          placeholder: "",
          required: true,
          initial: "name"
        },
        // row 1
        {
          name: "language",
          size: "col-sm-12 col",
          label: "Language:",
          labeltranslation: "integration.template.language",
          help: "Audio commentator language.",
          type: "select",
          required: true,
          initial: "language",
          options: languagesOptionsProvider
        },
        // row 2
        {
          name: "subscriptionName",
          size: "col-sm-3 col",
          label: "Subscription Name",
          labeltranslation: "subscriptionName",
          type: "text",
          placeholder: "",
          required: true,
          initial: "subscriptionName"
        },
        {
          name: "provider",
          size: "col-sm-2 col",
          label: "Provider:",
          labeltranslation: "provider",
          type: "select",
          placeholder: "",
          required: true,
          initial: "provider",
          options: integrationInputSourceOptionsProvider
        },
        {
          name: "feedType",
          size: "col-sm-3 col",
          label: "Feed Type:",
          labeltranslation: "feedType",
          type: "select",
          placeholder: "",
          required: true,
          initial: "feedType",
          options: feedTypeOptionsProvider
        },
        {
          name: "content",
          size: "col-sm-4 col",
          label: "Content:",
          labeltranslation: "content",
          type: "select",
          placeholder: "",
          required: true,
          initial: "content",
          options: programContentOptionsProvider
        },
        // row 3
        {
          name: "outputResolution",
          size: "col-sm-8 col",
          label: "Output Resolution:",
          labeltranslation: "outputresolution",
          type: "select",
          placeholder: "",
          required: true,
          initial: "outputResolution",
          options: outputResolutionOptionsProvider
        },
        {
          name: "sourceNumber",
          size: "col-sm-2 col",
          label: "Source Number:",
          labeltranslation: "sourcenumber",
          type: "number",
          placeholder: "",
          required: true,
          hidden: false,
          initial: "sourceNumber",
          min: 0
        },
        {
          name: "locale",
          size: "col-sm-2 col",
          label: "Locale:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: false,
          initial: "locale"
        }
      ],
      submit: "Update Template",
      submittranslation: "integration.template.update",
      customActions: [
        {
          action: "remove.template",
          label: "Remove",
          labeltranslation: "integration.template.remove",
          color: "outline-danger",
          icon: "fas fa-times",
          confirm: true
        }
      ],
      decoder: integration_template_decoder,
      encoder: integration_template_encoder
    }
  }
};

export default formSetup;
