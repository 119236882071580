import React, { useCallback, useMemo, useState } from "react";
import { Button } from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";

import { useApi } from "../Base/Hooks/api";
import TableDisplay from "../Base/Shared/TableDisplay";
import Modal from "../Base/Modal/Modal";

const FEATURE_INPUT_DELETE = true;

const FixtureInputs = (props) => {
  const { organizationId, seasonId, customerId, sport, fixtureData, intl, setRefreshFlag } = props;
  const { formatMessage } = intl;
  const [confirmModal, setConfirmModal] = useState(null);

  const api = useApi();

  const columns = [
    formatMessage({
      id: "name",
      defaultMessage: "Name"
    }),
    formatMessage({
      id: "provider",
      defaultMessage: "Provider"
    }),
    formatMessage({
      id: "resolution",
      defaultMessage: "Resolution"
    }),
    formatMessage({
      id: "content",
      defaultMessage: "Content"
    }),
    formatMessage({
      id: "inputURL",
      defaultMessage: "Input URL"
    }),
    formatMessage({
      id: "locale",
      defaultMessage: "Locale"
    }),
    formatMessage({
      id: "source",
      defaultMessage: "Source"
    }),
    ""
  ];

  const onRemoveRequestClick = useCallback((stream) => {
    setConfirmModal({ data: stream });
  }, []);
  const onInputRemoveAccept = useCallback(
    async (stream) => {
      try {
        const fixtureId = fixtureData.fixtureId;
        const serviceUrl = `/v1/${sport}/organizations/${organizationId}/seasons/${seasonId}/fixtures/${fixtureId}/inputs/${stream.videoInputId}`;
        const response = await api.fetch(serviceUrl, {
          method: "DELETE"
        });
        if (!response.ok) {
          console.error("Unexpected response", response);
          throw new Error("Unexpected response");
        }
      } catch (error) {
        console.error("Input remove remove internal error", error);
      } finally {
        setConfirmModal(null);
        setRefreshFlag(true);
      }
    },
    [api, organizationId, seasonId, sport, fixtureData, setRefreshFlag]
  );
  const onInputRemoveCancel = useCallback(() => {
    setConfirmModal(null);
  }, []);

  const tableData = useMemo(() => {
    if (!fixtureData || !Array.isArray(fixtureData.inputs)) {
      return null;
    }
    const inputs = fixtureData.inputs;
    return inputs.map((input) => {
      const key = input.videoInputId;
      return (
        <tr key={key} data-video-input-id={input.videoInputId} data-content={input.content}>
          <td>{input.name}</td>
          <td>{input.provider}</td>
          <td>{input.inputResolution}</td>
          <td>
            {input.content} {input.feedType}
          </td>
          <td>{input.inputURL || "- n/a -"}</td>
          <td width="1">{input.locale}</td>
          <td width="1">{input.sourceNumber}</td>
          <td width="1">
            {FEATURE_INPUT_DELETE ? (
              <Button color="btn btn-outline-danger btn-sm" onClick={() => onRemoveRequestClick(input)}>
                <i className="fas fa-minus" />
              </Button>
            ) : null}
          </td>
        </tr>
      );
    });
  }, [fixtureData, onRemoveRequestClick]);

  return (
    <div className="row col col-md-12">
      <div className={"col col-md-12 inputs-table"}>
        <h5>
          <FormattedMessage id="inputs.available" defaultMessage="Inputs" description="Available Inputs" />
        </h5>
        <TableDisplay table="fixture.inputs" containerClass="table-responsive" columns={columns} rows={tableData} />
      </div>

      <Modal
        isOpen={!!confirmModal}
        heading={formatMessage({
          id: "input.remove.confirm",
          defaultMessage: "Delete Input"
        })}
        text={formatMessage({
          id: "are.you.sure",
          defaultMessage: "Are you sure?"
        })}
        actionText={formatMessage({
          id: "delete",
          defaultMessage: "Delete"
        })}
        cancelText={formatMessage({
          id: "cancel",
          defaultMessage: "Cancel"
        })}
        destructive
        data={confirmModal ? confirmModal.data : null}
        action={onInputRemoveAccept}
        cancel={onInputRemoveCancel}
      />
    </div>
  );
};

export default injectIntl(FixtureInputs);
