import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";

import OrganizationRow from "./OrganizationRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import SearchFilter from "../Base/Shared/SearchFilter";

const Organizations = (props) => {
  const { title, updateState, history, intl } = props;
  const { formatMessage } = intl;
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [organizationData, setOrganizationData] = useState();
  const pageRef = useRef();
  const { error, loading, data } = useFetch("/v1/all-organizations", "", refreshFlag);
  const columns = [
    formatMessage({
      id: "organizationid",
      defaultMessage: "Organization Id"
    }),
    formatMessage({
      id: "name",
      defaultMessage: "Name"
    }),
    ""
  ];

  useEffect(() => {
    if (data) {
      let organizations = data !== null ? data.data : [];

      if (searchText !== "") {
        organizations = organizations.filter((result) => {
          return (
            result.name.toUpperCase().indexOf(searchText.toUpperCase()) > -1 ||
            result.organizationId.toUpperCase().indexOf(searchText.toUpperCase()) > -1
          );
        });
      }
      let organizationList = organizations.map((result, index) => (
        <OrganizationRow key={index} itemIndex={index} result={result} updateState={updateState} />
      ));
      setOrganizationData(organizationList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  return (
    <PageDisplay
      title={formatMessage({
        id: "organizations",
        defaultMessage: "Organizations"
      })}
      error={error}
      pageTitle={
        formatMessage({
          id: "organizations",
          defaultMessage: "Organizations"
        }) +
        " - " +
        title
      }
      ref={pageRef}
      history={history}
    >
      <SearchFilter doSearch={setSearchText} />
      <TableDisplay
        table="organizations"
        containerClass="table-responsive"
        columns={columns}
        rows={organizationData}
        loading={loading}
      />
    </PageDisplay>
  );
};

export default injectIntl(Organizations);
