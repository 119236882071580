import React, { useState, useEffect } from "react";
import { Spinner, Table } from "reactstrap";
import { injectIntl, FormattedMessage } from "react-intl";

import "./TableDisplay.scss";

const TableDisplay = injectIntl((props) => {
  const { table, containerClass, columns, rows, loading, fixed = false } = props;
  const [headerData, setHeaderData] = useState([]);

  const tableRows = Array.isArray(rows) ? rows : [];

  useEffect(() => {
    if (columns) {
      const headerColumns = columns.map((column, index) => <th key={index}>{column}</th>);
      setHeaderData(headerColumns);
    }
  }, [columns]);

  const colSpan = headerData.length;

  let content;
  if (loading) {
    content = (
      <tr className="no-records">
        <td colSpan={colSpan}>
          {loading ? (
            <div className="TableDisplayPendingIndicator">
              <Spinner size="md" color="orange" />
            </div>
          ) : (
            <FormattedMessage id="table.empty" defaultMessage="No records found" />
          )}
        </td>
      </tr>
    );
  } else {
    if (tableRows.length === 0) {
      content = (
        <tr className="no-records">
          <td colSpan={colSpan}>
            <FormattedMessage id="table.empty" defaultMessage="No records found" />
          </td>
        </tr>
      );
    } else {
      content = tableRows;
    }
  }

  return (
    <div className={"TableDisplay " + containerClass + " disabled-" + loading + " fixed-" + fixed}>
      <Table striped hover data-table={table || ""}>
        <thead>
          <tr>{headerData}</tr>
        </thead>
        {fixed && (
          <colgroup>
            <col span="1" className="fixed-column" />
          </colgroup>
        )}
        <tbody>{content}</tbody>
      </Table>
    </div>
  );
});

export default TableDisplay;
