export const SRT_LOW = 0.25;
export const SRT_HIGH = 0.3;

export const calculateMuxRate = (profile) => {
  let muxRate;
  if (profile.bitrate) {
    const low = profile.bitrate + profile.bitrate * SRT_LOW;
    const high = profile.bitrate + profile.bitrate * SRT_HIGH;
    muxRate = Math.ceil((low + high) / 2) * 1000; // average - in bytes per second
  }
  return muxRate;
};

export const computeMuxRate = (field, values) => {
  let value = field.default;
  const matching = OUTPUT_PROFILES.find((it) => it.key === values.outputResolution);
  if (matching && matching.muxRate) {
    value = matching.muxRate;
  }
  return value;
};

export const OUTPUT_FORMATS = [
  { text: "RTMP", value: "RTMP" },
  { text: "RTMP Pull", value: "RTMP_PULL" },
  { text: "HLS", value: "HLS" },
  { text: "SRT", value: "SRT" }
].map((it) => {
  return {...it, import: it.text};
});

// See https://www.vdocipher.com/blog/video-bandwidth-explanation
export const OUTPUT_PROFILES = [
  {
    key: "1080",
    width: 1920,
    height: 1080,
    bitrate: 5000,
    framerate: 30,
    title: "Full HD:",
    suffix: "1080p",
    formats: ["RTMP"]
  },
  {
    key: "720",
    width: 1280,
    height: 720,
    bitrate: 2500,
    framerate: 30,
    title: "HD:",
    suffix: "720p",
    formats: ["RTMP"]
  },
  {
    key: "288",
    width: 512,
    height: 288,
    bitrate: 700,
    framerate: 30,
    title: "LDTV:",
    formats: ["RTMP"]
  },
  {
    key: "1920x1080@4000k",
    width: 1920,
    height: 1080,
    bitrate: 4000,
    framerate: 30,
    title: "Youtube:",
    formats: ["RTMP"]
  },
  {
    key: "1920x1080@5000k30fps",
    width: 1920,
    height: 1080,
    bitrate: 5000,
    framerate: 30,
    title: "Custom TTL",
    formats: ["RTMP"]
  },
  {
    key: "2000",
    bitrate: 8000,
    framerate: 30,
    title: "Best available",
    formats: "*",
    skipTextGeneration: true
  },
  {
    key: "1920x1080@4000k",
    width: 1920,
    height: 1080,
    bitrate: 4000,
    framerate: 30,
    title: "ADV: Full HD",
    formats: ["RTMP"]
  },
  {
    key: "1920x1080@5000k30fps",
    width: 1920,
    height: 1080,
    bitrate: 5000,
    framerate: 30,
    title: "ADV: Full HD",
    formats: ["RTMP"]
  },
  {
    key: "1920x1080@4500k",
    width: 1920,
    height: 1080,
    bitrate: 4500,
    framerate: 30,
    title: "ADV: Full HD",
    formats: ["RTMP"]
  },
  {
    key: "1920x1080@4000k25fps",
    width: 1920,
    height: 1080,
    bitrate: 4000,
    framerate: 25,
    title: "ADV: Full HD",
    formats: ["RTMP"]
  },
  {
    key: "1280x720@3000k",
    width: 1280,
    height: 720,
    bitrate: 3000,
    framerate: 30,
    title: "ADV: HD",
    formats: ["RTMP"]
  },
  {
    key: "1280x720@3000k25fps",
    width: 1280,
    height: 720,
    bitrate: 3000,
    framerate: 25,
    title: "ADV: HD",
    formats: ["RTMP"]
  },
  {
    key: "1280x720@2000k",
    width: 1280,
    height: 720,
    bitrate: 2000,
    framerate: 30,
    title: "ADV: HD",
    formats: ["RTMP"]
  },
  {
    key: "640x320@850k",
    width: 640,
    height: 320,
    bitrate: 850,
    framerate: 30,
    title: "ADV: SD",
    formats: ["RTMP"]
  },
  {
    key: "DESKTOP_768x432@1000k",
    width: 768,
    height: 432,
    bitrate: 1000,
    framerate: 30,
    title: "ADV: Desktop",
    formats: ["RTMP"]
  },
  {
    key: "DESKTOP_512x288@500k",
    width: 512,
    height: 288,
    bitrate: 500,
    framerate: 30,
    title: "ADV: Desktop",
    formats: ["RTMP"]
  },
  {
    key: "DESKTOP_384x216@280k",
    width: 384,
    height: 216,
    bitrate: 280,
    framerate: 30,
    title: "ADV: Desktop",
    formats: ["RTMP"]
  },
  {
    key: "MOBILE_768x432@1000k",
    width: 768,
    height: 432,
    bitrate: 1000,
    framerate: 30,
    title: "ADV: Mobile",
    formats: ["RTMP"]
  },
  {
    key: "MOBILE_480x270@464k",
    width: 480,
    height: 270,
    bitrate: 464,
    framerate: 30,
    title: "ADV: Mobile",
    formats: ["RTMP"]
  },
  {
    key: "MOBILE_320x180@232k",
    width: 320,
    height: 180,
    bitrate: 232,
    title: "ADV: Mobile",
    formats: ["RTMP"]
  },
  {
    key: "MOBILE_320x180@102k",
    width: 320,
    height: 180,
    bitrate: 102,
    framerate: 30,
    title: "ADV: Mobile",
    formats: ["RTMP"]
  },
  {
    key: "MOBILE_768x432@1000k_BUFFERED",
    width: 768,
    height: 432,
    bitrate: 1000,
    framerate: 30,
    title: "ADV: Mobile BUFFERED",
    formats: ["RTMP"]
  },
  {
    key: "MOBILE_480x270@464k_BUFFERED",
    width: 480,
    height: 270,
    bitrate: 464,
    title: "ADV: Mobile BUFFERED",
    formats: ["RTMP"]
  },
  {
    key: "MOBILE_320x180@232k_BUFFERED",
    width: 320,
    height: 180,
    bitrate: 232,
    framerate: 30,
    title: "ADV: Mobile BUFFERED",
    formats: ["RTMP"]
  },
  {
    key: "MOBILE_320x180@102k_BUFFERED",
    width: 320,
    height: 180,
    bitrate: 102,
    framerate: 30,
    title: "ADV: Mobile BUFFERED",
    formats: ["RTMP"]
  },
  {
    key: "1080i50@10M",
    height: 1080,
    bitrate: 10000,
    framerate: 30,
    title: "SRT: 1080i50",
    formats: ["SRT"]
  },
  {
    key: "1080p25@10M",
    height: 1080,
    bitrate: 10000,
    framerate: 30,
    title: "SRT: 1080p25",
    formats: ["SRT"]
  },
  {
    key: "1080p50@10M",
    height: 1080,
    bitrate: 10000,
    framerate: 30,
    title: "SRT: 1080p50",
    formats: ["SRT"]
  },
  {
    key: "1920x1080@10k25fps",
    width: 1920,
    height: 1080,
    bitrate: 10000,
    framerate: 25,
    title: "Custom - 1920x1080@10k25fps",
    formats: ["RTMP", "SRT"]
  }
].map((profile) => {
  let text = profile.text;
  if (!text) {
    if (profile.skipTextGeneration) {
      text = profile.title;
    } else {
      const parts = [];
      if (profile.title) {
        parts.push(profile.title);
      }
      if (profile.width && profile.height) {
        parts.push(`${profile.width}x${profile.height}`);
      }
      if (profile.suffix) {
        parts.push(`(${profile.suffix})`);
      }
      if (profile.bitrate) {
        const unit = profile.bitrate >= 10000 ? "m" : "k";
        const value = profile.bitrate >= 10000 ? Math.ceil(profile.bitrate / 1000) : profile.bitrate;
        parts.push(`${value}${unit}`);
      }
      if (profile.framerate) {
        parts.push(`${profile.framerate}fps`);
      }
      text = parts.join(" ");
    }
  } else {
    text = profile.title;
  }
  return {
    ...profile,
    muxRate: calculateMuxRate(profile),
    // Backwards compatibility
    text,
    import: text,
    value: profile.key
  };
});

export const OUTPUT_RESOLUTIONS_ALL = OUTPUT_PROFILES.reduce((acc, it) => {
  acc.push(it);
  return acc;
}, []);

// const OUTPUT_RESOLUTIONS_ALL_CSV = OUTPUT_RESOLUTIONS_ALL.map(it => `${it.import}`).join("\n");
