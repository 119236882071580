import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import Features from "../../Config/Features";

import { FixtureSubscriptionsToggle } from "./FixtureSubscriptions";

const FixtureSummary = (props) => {
  const {
    data,
    isOpen,
    setSubscriptionsOpen,
    integrationsOpen,
    setIntegrationsOpen,
    streamsOpen,
    setStreamsOpen,
    inputsOpen,
    setInputsOpen
  } = props;
  const [hasInvalids, setHasInvalids] = useState(false);

  useEffect(() => {
    if (data) {
      data.subscriptions.forEach((subscription) => {
        if (subscription.valid === false) {
          setHasInvalids(true);
        }
      });
    }
  }, [data]);

  let integrationsSummary;
  if (Features.isAvailable("integrations")) {
    integrationsSummary = (
      <div className="avail-integrations">
        <span className="badge-pill badge badge-success">{data.integrations.length}</span>
        <FormattedMessage
          id="fixture.integrations.available"
          defaultMessage="Integrations"
          description="Available Integrations"
        />
        {!integrationsOpen && (
          <Button onClick={() => setIntegrationsOpen(true)} data-action="toggle.integrations">
            <FormattedMessage id="view" defaultMessage="view" description="view" />
          </Button>
        )}
        {integrationsOpen && (
          <Button onClick={() => setIntegrationsOpen(false)} data-action="toggle.integrations">
            <FormattedMessage id="hide" defaultMessage="hide" description="view" />
          </Button>
        )}
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="fixture-subscriptions">
        <FixtureSubscriptionsToggle
          subscriptions={data.subscriptions || []}
          subscriptionsOpen={isOpen}
          setSubscriptionsOpen={setSubscriptionsOpen}
        />

        <div className="avail-inputs">
          <span className="badge-pill badge badge-success">{data.availableStreams.length}</span>
          <FormattedMessage id="inputs.available" defaultMessage="Inputs" description="Available Inputs" />
          {!inputsOpen && (
            <Button onClick={() => setInputsOpen(true)} data-action="toggle.inputs">
              <FormattedMessage id="view" defaultMessage="view" description="view" />
            </Button>
          )}
          {inputsOpen && (
            <Button onClick={() => setInputsOpen(false)} data-action="toggle.inputs">
              <FormattedMessage id="hide" defaultMessage="hide" description="view" />
            </Button>
          )}
        </div>

        {hasInvalids && (
          <div className="invalid-subscriptions">
            <i className="fas fas fa-exclamation-triangle"></i>
            <FormattedMessage
              id="subscriptions.invalid"
              defaultMessage="Invalid Subscriptions"
              description="Invalid Subscriptions"
            />
          </div>
        )}

        {data.availableStreams.length > 0 && (
          <div className="avail-streams">
            <span className="badge-pill badge badge-success">{data.availableStreams.length}</span>
            <FormattedMessage id="streams.available" defaultMessage="Streams" description="Available Streams" />
            {!streamsOpen && (
              <Button onClick={() => setStreamsOpen(true)} data-action="toggle.streams">
                <FormattedMessage id="view" defaultMessage="view" description="view" />
              </Button>
            )}
            {streamsOpen && (
              <Button onClick={() => setStreamsOpen(false)} data-action="toggle.streams">
                <FormattedMessage id="hide" defaultMessage="hide" description="view" />
              </Button>
            )}
          </div>
        )}

        {data.availableStreams.length === 0 && (
          <span className="no-streams">
            <i className="fas fa-exclamation-circle"></i>{" "}
            <FormattedMessage
              id="streams.available.none"
              defaultMessage="No Available Streams"
              description="No Available Streams"
            />
          </span>
        )}

        {integrationsSummary}
      </div>
    </React.Fragment>
  );
};

export default FixtureSummary;
