import React, { useCallback } from "react";
import { Container } from "reactstrap";
import { FormBuilder } from "@atriumsports/coreui";

import { useApi } from "../Base/Hooks/api";

import formSetup from "./formSetup";

const AddIntegrations = (props) => {
  const {
    setAddFormOpen,
    pageRef,
    data,
    sport,
    organizationId,
    customerId,
    seasonId,
    integrationOptions,
    disabled,
    disabledMessage,
    setRefreshFlag
  } = props;

  const api = useApi();

  const formData = {
    sport,
    organizationId,
    customerId,
    seasonId,
    integrationOptions,
    fixtures: data.filter((it) => it.selected)
  };

  const onCustomAction = useCallback(
    (action, formik) => {
      if (action.action === "integrations.add.close") {
        setAddFormOpen(false);
      }
    },
    [setAddFormOpen]
  );

  const reloadData = useCallback(() => setRefreshFlag(true), [setRefreshFlag]);

  return (
    <Container className="integrations-container">
      <FormBuilder
        api={api}
        form="AddIntegrations"
        formSetup={formSetup}
        formData={formData}
        pageRef={pageRef}
        disabled={disabled}
        action={reloadData}
        disabledMessage={disabledMessage}
        onCustomAction={onCustomAction}
      />
    </Container>
  );
};

export default AddIntegrations;
