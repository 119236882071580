import React, { useEffect, useState, useRef, useCallback } from "react";
import { Container, Row, Button } from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";
import { useInterval } from "../Base/Hooks/interval";
import FixtureRow from "./FixtureRow";
import InputsRow from "./InputsRow";
import AddSubscriptions from "./AddSubscriptions";
import AddIntegrations from "./AddIntegrations";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import FilterDisplay from "../Base/Shared/FilterDisplay";
import { useFilterData } from "../Base/Hooks/filterdata";
import "./Fixtures.scss";
import Features from "../../Config/Features";

const Fixtures = (props) => {
  const { title, updateState, history, match, intl, layout, setLayout, customerId, isStaffScope } = props;
  const { formatMessage } = intl;
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [silentLoading, setSilentLoading] = useState(false);
  const [refreshRows, setRefreshRows] = useState(true);
  const [masterData, setMasterData] = useState(null);
  const [tableData, setTableData] = useState();
  const [inputsData, setInputsData] = useState();
  const [integrationOptions, setIntegrationsOptions] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [withSubscriptionForm, setWithSubscriptionForm] = useState(false);
  const [withIntegrationsForm, setWithIntegrationsForm] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [seasonName, setSeasonName] = useState("");
  const pageRef = useRef();
  const scrollToFixtureById = useRef("");

  const fixturesFetch = useFetch(
    "/v1/" +
      match.params.sport +
      "/organizations/" +
      match.params.organizationId +
      "/seasons/" +
      match.params.seasonId +
      "/fixtures",
    "",
    refreshFlag
  );
  const integrationsFetch = useFetch("/v1/integrations?include=templates", "", refreshFlag);

  const loading = fixturesFetch.loading || integrationsFetch.loading;
  const error = fixturesFetch.error || integrationsFetch.error;

  const [filterFields, setFilterFields] = useState({
    date: "",
    venueName: "",
    teamHome: "",
    teamAway: ""
  });
  const { filteredData } = useFilterData(initialData, filterFields);
  const columns = null;

  useInterval(() => {
    // setRefreshFlag(true);
    // setSilentLoading(true);
  }, 20000);

  function handleFilter(field, value) {
    let newFilter = {
      ...filterFields,
      [field]: value
    };
    setFilterFields(newFilter);
    setRefreshRows(true);
  }

  function selectRow(index, value) {
    let tempInitialData = initialData;
    tempInitialData.forEach((row, rowIndex) => {
      if (row.fixtureId === filteredData[index].fixtureId) {
        tempInitialData[rowIndex].selected = value;
        filteredData[index].selected = value;
      }
    });
    setInitialData(tempInitialData);
    setRefreshRows(true);
  }
  function toggleInputs() {
    setShowInputs(!showInputs);
  }
  function toggleSelect(e) {
    let value = e.target.checked;
    let tempData = filteredData;
    tempData.forEach((row, rowIndex) => {
      filteredData[rowIndex].selected = value;
    });
    setMasterData(tempData);
    setRefreshRows(true);
  }

  const fixturesData = fixturesFetch.data;
  const integrationsData = integrationsFetch.data;

  useEffect(() => {
    if (loading) {
      // console.debug("Data is loading");
      return;
    }
    const data = fixturesData;
    if (data && data.data && data.data[0]) {
      let initialData = data !== null ? data.data : [];
      setSeasonName(data.data[0].seasonName);
      initialData.forEach((fixture, index) => {
        let matchDate = fixture.startTimeLocal.split("T");
        initialData[index].date = matchDate[0];
        if (filteredData) {
          let filteredItem = filteredData.find((fixture) => fixture.fixtureId === initialData[index].fixtureId);
          if (filteredItem) {
            initialData[index].selected = filteredItem.selected;
          } else {
            initialData[index].selected = false;
          }
        }
        if (fixture.competitors.length > 1) {
          if (fixture.competitors[0].isHome) {
            initialData[index].teamHome = fixture.competitors[0].entityName;
            initialData[index].teamAway = fixture.competitors[1].entityName;
          } else {
            initialData[index].teamHome = fixture.competitors[1].entityName;
            initialData[index].teamAway = fixture.competitors[0].entityName;
          }
        }
      });
      setInitialData(initialData);
      setIntegrationsOptions(integrationsData?.data || []);
      setRefreshFlag(false);
      setSilentLoading(false);
    }
    if (scrollToFixtureById.current) {
      const fixtureId = scrollToFixtureById.current;
      scrollToFixtureById.current = null;
      try {
        const element = document.querySelector(`#fixture-${fixtureId}`);
        element.scrollIntoView();
        if (!element.classList.contains("blinkFixtureRow")) {
          element.classList.add("blinkFixtureRow");
        }
      } catch (error) {
        console.error("Unable to scroll to element", error.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, fixturesData, integrationsData]);

  useEffect(() => {
    if (filteredData) {
      setMasterData(filteredData);
      setRefreshRows(true);
    }
    // eslint-disable-next-line
  }, [filteredData]);

  const onBundledAdded = useCallback((fixtureId) => {
    console.debug("Bundle added for fixture", fixtureId);
    scrollToFixtureById.current = fixtureId;
  }, []);

  const onSubscriptionAdded = useCallback((fixtureId, value, response) => {
    console.debug("Subscription added for fixture", fixtureId, value, response);
    setSilentLoading(true);
    setRefreshFlag(true);
  }, []);

  useEffect(() => {
    if (masterData && refreshRows) {
      setTableData(null);
      let matchList = masterData.map((result, index) => (
        <React.Fragment key={result.fixtureId}>
          <FixtureRow
            itemIndex={index}
            result={result}
            updateState={updateState}
            selectRow={selectRow}
            pageRef={pageRef}
            integrationOptions={integrationOptions}
            organizationId={match.params.organizationId}
            seasonId={match.params.seasonId}
            sport={match.params.sport}
            setRefreshFlag={setRefreshFlag}
            setSilentLoading={setSilentLoading}
            customerId={customerId}
            isStaffScope={isStaffScope}
            onBundledAdded={onBundledAdded}
          />
        </React.Fragment>
      ));
      setTableData(matchList);

      setInputsData(null);
      let inputsList = masterData.map((result, index) => (
        <React.Fragment key={result.fixtureId}>
          <InputsRow itemIndex={index} result={result} />
        </React.Fragment>
      ));
      setInputsData(inputsList);
      setRefreshRows(false);
    }
    // eslint-disable-next-line
  }, [masterData, integrationOptions, refreshRows, showInputs]);

  const hasSelectedFixtures = (initialData || []).filter((it) => it.selected).length === 0;
  let integrationsButton;

  if (Features.isAvailable("integrations")) {
    integrationsButton = (
      <Button color="outline-secondary" onClick={() => setWithIntegrationsForm(true)}>
        <FormattedMessage
          id="fixture.integrations.add"
          defaultMessage="Add Integration/s"
          description="Add Integration/s"
        />
        <i className="far fa-check-square"></i>
      </Button>
    );
  }

  return (
    <React.Fragment>
      <PageDisplay
        title={
          seasonName +
          " " +
          formatMessage({
            id: "fixtures",
            defaultMessage: "Fixtures"
          })
        }
        error={error}
        pageTitle={
          formatMessage({
            id: "fixtures",
            defaultMessage: "Fixtures"
          }) +
          " - " +
          title
        }
        ref={pageRef}
        history={history}
        layout={layout}
        setLayout={setLayout}
      >
        {(!loading || (loading && silentLoading)) && (
          <React.Fragment>
            <FilterDisplay
              data={initialData}
              display={formatMessage({
                id: "venues.all",
                defaultMessage: "All Venues"
              })}
              value="venueName"
              selectAction={handleFilter}
            />
            <FilterDisplay
              data={initialData}
              display={formatMessage({
                id: "dates.all",
                defaultMessage: "All Dates"
              })}
              value="date"
              selectAction={handleFilter}
            />
            <FilterDisplay
              data={initialData}
              display={formatMessage({
                id: "teams.home.all",
                defaultMessage: "All Home Teams"
              })}
              value="teamHome"
              selectAction={handleFilter}
            />
            <FilterDisplay
              data={initialData}
              display={formatMessage({
                id: "teams.away.all",
                defaultMessage: "All Away Teams"
              })}
              value="teamAway"
              selectAction={handleFilter}
            />
          </React.Fragment>
        )}
        {(!loading || (loading && silentLoading)) && (
          <Container className="table-header">
            <Row>
              <div className="checkbox" data-checkbox="fixtures.select-all">
                <label>
                  <input
                    name="fixtures.select-all"
                    type="checkbox"
                    onChange={toggleSelect}
                    // checked={isSelected}
                  />
                  <span className="cr">
                    <i className="cr-icon fa fa-check" />
                  </span>
                  <FormattedMessage
                    id="selectdeselect"
                    defaultMessage="Select / Deselect All"
                    description="Select / Deselect All"
                  />
                </label>
              </div>
              <span>
                <Button color="outline-secondary" onClick={() => toggleInputs()}>
                  {!showInputs && (
                    <FormattedMessage id="inputs.show" defaultMessage="Show Inputs" description="Show Inputs" />
                  )}{" "}
                  {showInputs && (
                    <FormattedMessage id="inputs.hide" defaultMessage="Hide Inputs" description="Hide Inputs" />
                  )}
                </Button>
                <Button color="outline-secondary" onClick={() => setWithSubscriptionForm(true)}>
                  <FormattedMessage
                    id="subscriptions.add"
                    defaultMessage="Add Subscription/s"
                    description="Add Subscription/s"
                  />
                  <i className="far fa-check-square"></i>
                </Button>
                {integrationsButton}
              </span>
            </Row>
          </Container>
        )}
        {withSubscriptionForm && (
          <AddSubscriptions
            pageRef={pageRef}
            sport={match.params.sport}
            customerId={customerId}
            organizationId={match.params.organizationId}
            seasonId={match.params.seasonId}
            setAddFormOpen={setWithSubscriptionForm}
            setRefreshFlag={setRefreshFlag}
            data={masterData}
            isStaffScope={isStaffScope}
            disabled={hasSelectedFixtures}
            disabledMessage={formatMessage({
              id: "fixtures.selected.required",
              defaultMessage: "At least one fixture must be selected"
            })}
            onSubscriptionAdded={onSubscriptionAdded}
          />
        )}
        {withIntegrationsForm && (
          <AddIntegrations
            pageRef={pageRef}
            sport={match.params.sport}
            customerId={customerId}
            organizationId={match.params.organizationId}
            seasonId={match.params.seasonId}
            setAddFormOpen={setWithIntegrationsForm}
            setRefreshFlag={setRefreshFlag}
            data={masterData}
            integrationOptions={integrationOptions}
            isStaffScope={isStaffScope}
            disabled={hasSelectedFixtures}
            disabledMessage={formatMessage({
              id: "fixtures.selected.required",
              defaultMessage: "At least one fixture must be selected"
            })}
          />
        )}
        {!showInputs && (
          <TableDisplay
            table="fixtures"
            containerClass="table-responsive fixture-table"
            columns={columns}
            rows={tableData}
            loading={loading && !silentLoading}
            fixed={true}
          />
        )}
        {showInputs && (
          <TableDisplay
            table="fixture.inputs"
            containerClass="table-responsive inputs-table"
            columns={columns}
            rows={inputsData}
            loading={loading && !silentLoading}
            fixed={true}
          />
        )}
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(Fixtures);
