import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Badge } from "reactstrap";

import { FormBuilder } from "@atriumsports/coreui";

import { useApi } from "../Base/Hooks/api";
import formSetup from "./formSetup";
import DashboardImage from "./Parts/DashboardImage";
import DashboardIframe from "./Parts/DashboardIframe";
import Destinations from "./Destinations/Destinations";
import Feeds from "./Feeds/Feeds";
import ReactHlsPlayer from 'react-hls-player';

const DashboardRow = (props) => {
  const { data, pageRef, isStaffScope } = props;
  const [previewDisplay, setPreviewDisplay] = useState("preview");
  const [formData, setFormData] = useState(null);
  const [feeds, setFeeds] = useState([]);
  const [destinationCounts, setDestinationCounts] = useState({
    running: 0,
    stopped: 0
  });
  const [feedCounts, setFeedCounts] = useState({
    running: 0,
    stopped: 0
  });
  const [showControls, setShowControls] = useState(false);
  const [finishTime, setFinishTime] = useState(null);
  const [startTimeUser, setStartTimeUser] = useState();
  const [showExpand, setShowExpand] = useState(false);
  const [hideFormData, setHideFormData] = useState(null);
  const [showFormData, setShowFormData] = useState(null);
  const api = useApi();

  useEffect(() => {
    if (data) {
      if (data.video && data.video.feeds) {
        let feedList = data.video.feeds;
        let runningFeeds = 0;
        let stoppedFeeds = 0;
        feedList.forEach((feed, index) => {
          feedList[index]["name"] = feed.type + " " + feed.id;
          if (feed.status === "running") {
            runningFeeds = runningFeeds + 1;
          } else {
            stoppedFeeds = stoppedFeeds + 1;
          }
        });
        setFeedCounts({
          running: runningFeeds,
          stopped: stoppedFeeds
        });

        setFeeds(feedList);

        if (data.video.destination) {
          let destinationList = data.video.destination;
          let runningDestinations = 0;
          let stoppedDestinations = 0;
          destinationList.forEach((destination) => {
            if (destination.status === "running") {
              runningDestinations = runningDestinations + 1;
            } else {
              stoppedDestinations = stoppedDestinations + 1;
            }
          });
          setDestinationCounts({
            running: runningDestinations,
            stopped: stoppedDestinations
          });
        }
        let tempData = {};
        tempData["organizationId"] = data.organizationId;
        tempData["fixtureId"] = data.fixtureId;
        tempData["locale"] = data.locale;
        tempData["platformProvider"] = data.platformProvider;
        tempData["sport"] = data.sport;
        setFormData(tempData);

        tempData["hideaction"] = "HIDE";
        tempData["showaction"] = "SHOW";
        setHideFormData(tempData);
        setShowFormData(tempData);
      }
      let finishDate = new Date(data.finishTime + "-0000");
      var now = new Date();
      let diff = finishDate - now;
      let minutesUntilStart = Math.floor(diff / 1000 / 60);
      let hours = Math.floor(minutesUntilStart / 60);
      let minutes = minutesUntilStart % 60;
      setFinishTime(String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0"));
      let startTime = new Date(data.startTimeUTC + "-0000");
      let formatted_date =
        startTime.getFullYear() +
        "-" +
        String(startTime.getMonth() + 1).padStart(2, "0") +
        "-" +
        startTime.getDate() +
        " " +
        startTime.getHours() +
        ":" +
        String(startTime.getMinutes()).padStart(2, "0") +
        ":00";
      setStartTimeUser(formatted_date);
    }
  }, [data]);

  /** Boolean flag for rendering status-related indicators */
  const canShowStatuses = () => data && data.video && data.video.engineStatus === "running";

  const renderDestinationStatus = () => (
    <div className="destinationsStatus">
      {canShowStatuses() && (
        <span onClick={(e) => setPreviewDisplay("destinations")}>
          <i className="fas fa-external-link-alt"></i>
          <strong>
            <FormattedMessage id="destinations" defaultMessage="destinations" description="destinations" />:
          </strong>
          {destinationCounts.running > 0 && <Badge color="success">{destinationCounts.running}</Badge>}
          {destinationCounts.stopped > 0 && <Badge color="danger">{destinationCounts.stopped}</Badge>}
        </span>
      )}
    </div>
  );

  const renderMatchControlToggle = () =>
    isStaffScope && (
      <div className={"showControls visible-" + showControls} onClick={(e) => setShowControls(!showControls)}>
        {
          <span>
            {!showControls && <i className="fas fa-angle-up"></i>}
            {showControls && <i className="fas fa-angle-down"></i>}
          </span>
        }
      </div>
    );

  const renderFeedStatus = () => (
    <div className="feedsStatus">
      {canShowStatuses() && (
        <span onClick={(e) => setPreviewDisplay("feeds")}>
          <i className="fas fa-external-link-alt"></i>
          <strong>
            <FormattedMessage id="feeds" defaultMessage="feeds" description="feeds" />:
          </strong>
          {feedCounts.running > 0 && <Badge color="success">{feedCounts.running}</Badge>}
          {feedCounts.stopped > 0 && <Badge color="danger">{feedCounts.stopped}</Badge>}
        </span>
      )}
    </div>
  );

  const renderBottomSection = () =>
    /** Do not render the section if there is nothing to be shown*/
    (canShowStatuses() || isStaffScope) && (
      <div className="bottom-section">
        {renderDestinationStatus()}
        {renderMatchControlToggle()}
        {renderFeedStatus()}
      </div>
    );
  return (
    <React.Fragment>
      <tr className={"liveRow expand-" + showExpand}>
        <td>
          <div className="liveMatchContainer">
            <h5>{data.competitors && data.teamHome + " v. " + data.teamAway}</h5>
            <h6>
              {data.organizationName} @ {data.venueName}
            </h6>
            <small>
              <strong>
                <FormattedMessage id="time.match" defaultMessage="Match Time" description="Match Time" />:{" "}
              </strong>
              {data.startTimeLocal.replace("T", " ")}
            </small>
            <small>
              <strong>
                {" "}
                <FormattedMessage id="time.your" defaultMessage="Your Time" description="Your Time" />:{" "}
              </strong>
              {String(startTimeUser)}
            </small>
            <div className="previewContainer">
              {data && data.video && data.video.engineStatus === "running" && (
                <div className="previewContainerDisplays">
                  <span className="expand-toggle" onClick={(e) => setShowExpand(!showExpand)}>
                    {!showExpand && <i className="fas fa-expand-arrows-alt"></i>}
                    {showExpand && <i className="fas fa-compress-arrows-alt"></i>}
                  </span>
                  {previewDisplay === "preview" && feeds && (
                    <div>
                      <DashboardImage data={data.video} image="programPreview" defaultImage="preview" />
                    </div>
                  )}
                  {feeds.map((feed, index) => (
                    <React.Fragment key={index}>
                      {previewDisplay === feed.name && (
                        <div>
                          <DashboardImage data={feed} image="preview" defaultImage="bars" />
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                  {previewDisplay === "program" && (
                    <div>
                      {data.video.monitorEmbed ? (
                        <DashboardIframe data={data} />
                      ) : data.video.monitorURL ? (
                        <ReactHlsPlayer
                          src={data.video.monitorURL}
                          autoPlay={true}
                          controls={false}
                          width="100%"
                          height="auto"
                        />
                      ) : (
                        <p>No preview available</p>
                      )}
                    </div>
                  )}
                  {previewDisplay === "evo" && feeds && (
                    <div>
                      <DashboardImage data={data.video} image="gfxPreview" defaultImage="evo" />
                    </div>
                  )}
                  {previewDisplay === "info" && (
                    <div className="infoPanel">
                      <span>
                        <strong>fixtureId: </strong>
                        {data.fixtureId}
                      </span>
                      <span>
                        <strong>eventId: </strong>
                        {data.providerEventId}
                      </span>
                      <span>
                        <strong>status: </strong>
                        {data.video.status}
                      </span>
                      <span>
                        <strong>engineStatus: </strong>
                        {data.video.engineStatus}
                      </span>
                      <span>
                        <strong>ip: </strong>
                        {data.video.ip}
                      </span>
                      <span>
                        <strong>runningTime: </strong>
                        {data.video.runningTime}
                      </span>
                      <span>
                        <strong>address: </strong>
                        {data.video.address}
                      </span>
                      <span>
                        <strong>locale: </strong>
                        {data.locale}
                      </span>
                    </div>
                  )}
                  {previewDisplay === "destinations" && feeds && (
                    <div>
                      <Destinations destinations={data.video.destination} />
                    </div>
                  )}
                  {previewDisplay === "feeds" && feeds && (
                    <div>
                      <Feeds feeds={data.video.feeds} setPreviewDisplay={setPreviewDisplay} />
                    </div>
                  )}
                </div>
              )}
              {data && data.video && data.video.engineStatus === "running" && (
                <div className="previewControls">
                  <p
                    className={previewDisplay === "preview" ? "active " : ""}
                    onClick={(e) => setPreviewDisplay("preview")}
                  >
                    <FormattedMessage id="preview" defaultMessage="preview" description="preview" />
                  </p>
                  <p
                    className={previewDisplay === "program" ? "active " : ""}
                    onClick={(e) => setPreviewDisplay("program")}
                  >
                    <FormattedMessage id="program" defaultMessage="program" description="program" />
                  </p>
                  {feeds.map((feed, index) => (
                    <p
                      key={index}
                      className={(previewDisplay === feed.name ? "active " : "") + feed.status}
                      onClick={(e) => setPreviewDisplay(feed.name)}
                    >
                      {feed.name}
                    </p>
                  ))}
                  <p className={previewDisplay === "evo" ? "active " : ""} onClick={(e) => setPreviewDisplay("evo")}>
                    EVO
                  </p>
                  <p
                    className={previewDisplay === "info" ? "info active " : "info"}
                    onClick={(e) => setPreviewDisplay("info")}
                  >
                    <i className="fas fa-info"></i>
                  </p>
                </div>
              )}
              {data && data.video && data.video.engineStatus === "running" && (
                <div className="infoContainer">
                  {data.video && (
                    <div className="infoVideo">
                      {data.video && data.video.resolution} / {data.video && data.video.fps}fps
                    </div>
                  )}
                </div>
              )}
              {data && data.forceStatus === "override" && (
                <div className="automationInfo">
                  <FormattedMessage id="disconnected" defaultMessage="disconnected" description="disconnected" />
                </div>
              )}
              {data && data.video && data.video.engineStatus === "running" && data.forceStatus !== "override" && (
                <div className="automationType">
                  <span>{data.automationType}</span>
                  <span>{finishTime}</span>
                </div>
              )}
              {data && data.video && data.video.engineStatus !== "running" && (
                <span className="engineStatus">{data.video.engineStatus}</span>
              )}
            </div>
            {showControls && (
              <div className="matchControls">
                {formData && (
                  <React.Fragment>
                    <div className="videostream-controls">
                      {data && data.video && data.video.engineStatus !== "running" && (
                        <FormBuilder
                          api={api}
                          form="StartVideoStream"
                          formSetup={formSetup}
                          formData={formData}
                          pageRef={pageRef}
                        />
                      )}
                      {data && data.video && data.video.engineStatus !== "stopped" && (
                        <FormBuilder
                          api={api}
                          form="StopVideoStream"
                          formSetup={formSetup}
                          formData={formData}
                          pageRef={pageRef}
                        />
                      )}
                      {data && data.video && (
                        <FormBuilder
                          api={api}
                          form="ResetVideoStream"
                          formSetup={formSetup}
                          formData={formData}
                          pageRef={pageRef}
                        />
                      )}
                      {data && data.forceStatus !== "force_stop" && (
                        <FormBuilder
                          api={api}
                          form="DisconnectVideoStream"
                          formSetup={formSetup}
                          formData={formData}
                          pageRef={pageRef}
                        />
                      )}
                    </div>
                    <div className="overlay-controls">
                      {data && data.platformProvider === "5STREAM" && (
                        <>
                          <FormBuilder
                            api={api}
                            form="ShowOverlay"
                            formSetup={formSetup}
                            formData={showFormData}
                            pageRef={pageRef}
                          />
                          <FormBuilder
                            api={api}
                            form="HideOverlay"
                            formSetup={formSetup}
                            formData={hideFormData}
                            pageRef={pageRef}
                          />
                        </>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
            {renderBottomSection()}
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default DashboardRow;
