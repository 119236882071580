import React, { useState, useEffect } from "react";

import DestinationRow from "./DestinationRow";
import TableDisplay from "../../Base/Shared/TableDisplay";

const Destinations = (props) => {
  const { destinations } = props;
  const [tableData, setTableData] = useState();
  const columns = ["Destination", "Status"];

  useEffect(() => {
    if (destinations) {
      setTableData(null);
      let destinationList = destinations.map((destination, index) => (
        <DestinationRow key={destination.id} itemIndex={index} destination={destination} />
      ));
      setTableData(destinationList);
    }
    // eslint-disable-next-line
  }, [destinations]);

  return (
    <React.Fragment>
      <div className="destinationsContainer">
        <TableDisplay
          table="destinations"
          containerClass="table-responsive dashboard-destinations"
          columns={columns}
          rows={tableData}
          fixed={false}
        />
      </div>
    </React.Fragment>
  );
};

export default Destinations;
