import { ENVIRONMENT } from "./Environment";

const FeaturesMap = {
  integrations: {
    available: ENVIRONMENT !== "prod"
  }
};

const Features = {
  isAvailable: (name) => {
    let flag = false;
    if (typeof FeaturesMap[name] !== "undefined") {
      flag = FeaturesMap[name].available;
    }
    return flag;
  }
};

export default Features;
