import React, { useCallback, useEffect, useState } from "react";
import { Alert, Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { FormBuilder } from "@atriumsports/coreui";

// import { getISO6392LanguageFromLocale } from "../../Config/locales";

import { useApi } from "../Base/Hooks/api";
import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import formSetup from "./formSetup";

import "./IntegrationSetup.scss";

const getSpalkAWSRegionFromFixture = (fixture, regions) => {
  let match = "";
  // Take AWS region from fixture venue
  const fixtureRegion = fixture?.providerInfo?.awsRegion;
  if (fixtureRegion) {
    const spalkProvider = regions.find((it) => it.name === "Spalk");
    const spalkProviderRegions = spalkProvider?.settings?.regions || [];
    const region = spalkProviderRegions.find((it) => it.region === fixtureRegion);
    if (region) {
      match = region.mapping || region.region;
    } else {
      console.error("Fixture region not supported by Spalk known region mappings", fixtureRegion);
    }
  }
  // console.debug("Located Spalk AWS region mapping for fixture region", fixtureRegion, "as", match);
  return match;
};

const IntegrationSetup = (props) => {
  const { title, history, match, intl } = props;

  const { formatMessage } = intl;

  const [state, setState] = useState({
    refreshFlag: true,
    integrations: [],
    integrationProvidersOptions: [],
    integrationOptions: [],
    integration: null,
    fixture: null
  });

  const { refreshFlag, fixture, integration, integrationProvidersOptions } = state;
  const { sport, organizationId, seasonId, fixtureId, integrationId } = match?.params || {};

  const api = useApi();

  const fixtureRequest = useFetch(
    "/v1/" + sport + "/organizations/" + organizationId + "/seasons/" + seasonId + "/fixtures/" + fixtureId,
    "",
    refreshFlag
  );
  const fixtureIntegrationsRequest = useFetch(`/v1/integrations/fixtures/${fixtureId}/integrations`, "", refreshFlag);
  const integrationOptionsRequest = useFetch("/v1/integrations", "", refreshFlag);
  const integrationProvidersOptionsRequest = useFetch("/v1/integration/providers", "", refreshFlag);

  const isLoading =
    fixtureRequest.loading ||
    fixtureIntegrationsRequest.loading ||
    integrationOptionsRequest.loading ||
    integrationProvidersOptionsRequest.loading;

  const error =
    fixtureRequest.error ||
    fixtureIntegrationsRequest.error ||
    integrationOptionsRequest.error ||
    integrationProvidersOptionsRequest.error;

  const fixtureData = fixtureRequest.data;
  const fixtureIntegrationsData = fixtureIntegrationsRequest.data;
  const integrationOptionsData = integrationOptionsRequest.data;
  const integrationProvidersOptionsData = integrationProvidersOptionsRequest.data;

  const onSubmitComplete = useCallback(() => {
    setState((prev) => ({
      ...prev,
      refreshFlag: true
    }));
  }, []);

  const onCustomAction = useCallback(
    async (action, formProps, confirmed) => {
      if (confirmed && action.action === "remove.integration") {
        formProps.setSubmitting(true);
        try {
          const response = await api.fetch(`/v1/integrations/${integrationId}/setup`, {
            method: "DELETE",
            data: {
              fixtureId
            }
          });
          if (response.ok) {
            setState((prev) => ({ ...prev, integration: { ...prev.integration, setup: null } }));
          } else {
            console.error("Unexpected response", response);
          }
        } catch (error) {
          console.error("Integration setup remove internal error", error);
        } finally {
          formProps.setSubmitting(false);
        }
      }
    },
    [api, integrationId, fixtureId]
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (
      fixtureData?.data &&
      fixtureIntegrationsData?.data &&
      integrationOptionsData?.data &&
      integrationProvidersOptionsData?.data
    ) {
      const fixture = fixtureData.data;
      const integrationOptions = integrationOptionsData.data;
      const integrations = fixtureIntegrationsData.data;
      const integrationProvidersOptions = integrationProvidersOptionsData.data;
      const integrationSetup = integrations.find((it) => Number(it.integrationId) === Number(integrationId));
      const integrationDefinition = integrationOptions.find((it) => Number(it.integrationId) === Number(integrationId));
      const integration = {
        integrationId: Number(integrationId),
        setup: null,
        ...(integrationSetup || {}),
        ...(integrationDefinition || {})
      };
      setState((prev) => ({
        ...prev,
        refreshFlag: false,
        fixture,
        integrationOptions,
        integrationProvidersOptions,
        integrations,
        integration
      }));
    }
  }, [
    isLoading,
    fixtureData,
    integrationOptionsData,
    fixtureIntegrationsData,
    integrationProvidersOptionsData,
    integrationId
  ]);

  const pageTitle =
    title +
    " - " +
    formatMessage({
      id: "fixture.integration.setup",
      defaultMessage: "Setup Integration"
    });
  const competitorsTitle = (fixture?.competitors || []).map((it) => it.entityName).join(" vs ");
  const seasonTitle = fixture?.seasonName || "";

  const hasIntegrationSetup = !!(integration && integration.setup);

  const form = hasIntegrationSetup ? "IntegrationSetupUpdate" : "IntegrationSetupCreate";
  const formData = hasIntegrationSetup
    ? {
        sport,
        organizationId,
        seasonId,
        fixtureId,
        title: integration?.setup.values.Event.Title,
        language: integration?.setup.values.Team.Language,
        region: integration?.setup.values.Event.Region,
        inputURL: integration?.setup.values.Input.URL,
        commentatorAppURL: integration?.setup.values.Team.AnonymousAccessUri,
        integrationProvidersOptions
      }
    : {
        sport,
        organizationId,
        seasonId,
        fixtureId,
        title: competitorsTitle,
        // language: subscription ? getISO6392LanguageFromLocale(subscription.locale, "eng") : "eng",
        language: "eng",
        region: fixture ? getSpalkAWSRegionFromFixture(fixture, integrationProvidersOptions) : "",
        integrationProvidersOptions
      };

  return (
    <PageDisplay page="integration.setup" title={pageTitle} error={error} pageTitle={pageTitle} history={history}>
      {integration && (
        <div>
          <h4>{integration.name}</h4>
          {competitorsTitle && <h5>{competitorsTitle}</h5>}
          {seasonTitle && <h6>{seasonTitle}</h6>}
          <FormBuilder
            api={api}
            action={onSubmitComplete}
            form={form}
            formSetup={formSetup}
            formData={formData}
            formMessage={
              integration.setup ? (
                <Alert color="success">
                  <i className="fas fa-thumbs-up" /> &nbsp;
                  {formatMessage({
                    id: "fixture.integration.setup.present",
                    defaultMessage:
                      "Complete integration setup was found, update and cancel it manually or wait for the automation."
                  })}
                </Alert>
              ) : (
                <Alert color="warning">
                  <i className="fas fa-exclamation-triangle" /> &nbsp;
                  {formatMessage({
                    id: "fixture.integration.setup.missing",
                    defaultMessage:
                      "No integration setup could be found, click to define one manually or wait for the automation."
                  })}
                </Alert>
              )
            }
            onCustomAction={onCustomAction}
          />
          {error}
        </div>
      )}
    </PageDisplay>
  );
};

export default injectIntl(IntegrationSetup);
