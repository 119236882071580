import React, { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { Button, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";

import { FormBuilder } from "@atriumsports/coreui";

import { useApi } from "../Base/Hooks/api";
import { useFetch } from "../Base/Hooks/fetch";
import Modal from "../Base/Modal/Modal";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import SearchFilter from "../Base/Shared/SearchFilter";
import { getISO6392Languages } from "../../Config/locales";

import "./IntegrationTemplates.scss";
import formSetup from "./formSetup";

const IntegrationTemplatesListItem = injectIntl(({ integration, template, languages, intl, onRemove }) => {
  const { formatMessage } = intl;
  const language = languages.mappings[template.config.language];
  const onRemoveRequestClick = useCallback(() => {
    if (onRemove) {
      onRemove(template);
    }
  }, [onRemove, template]);
  return (
    <tr data-integration-template-id={template.templateId}>
      <td width="50%">{template.name}</td>
      <td>{language}</td>
      <td width="50%">{Object.values(template.config.stream).join(" / ")}</td>
      <td className="controls-1">
        <Link
          title={formatMessage({ id: "integration.template.edit", defaultMessage: "Edit integration template" })}
          to={`/configuration/integrations/${integration.integrationId}/templates/${template.templateId}/edit`}
          className="btn btn-outline-primary btn-sm"
        >
          <i className="fas fa-pen" />
        </Link>
        <Button color="btn btn-outline-danger btn-sm" onClick={onRemoveRequestClick}>
          <i className="fas fa-minus" />
        </Button>
      </td>
    </tr>
  );
});

const IntegrationTemplates = (props) => {
  const { title, history, match, intl } = props;
  const { formatMessage } = intl;

  const pageRef = useRef();
  const api = useApi();

  const [state, setState] = useState({
    refreshFlag: true,
    searchText: "",
    integration: null,
    integrationTemplates: [],
    integrationProvidersOptions: [],
    confirmModal: null
  });

  const { refreshFlag, searchText, integration, integrationTemplates, integrationProvidersOptions, confirmModal } =
    state;

  const integrationRequest = useFetch(`/v1/integrations/${match.params.integrationId}`, "", refreshFlag);
  const integrationTemplatesRequest = useFetch(
    `/v1/integrations/${match.params.integrationId}/templates`,
    "",
    refreshFlag
  );
  const integrationProvidersOptionsRequest = useFetch("/v1/integration/providers", "", refreshFlag);

  const isLoading =
    integrationRequest.loading || integrationTemplatesRequest.loading || integrationProvidersOptionsRequest.loading;
  const error =
    integrationRequest.error || integrationTemplatesRequest.error || integrationProvidersOptionsRequest.error;

  const integrationData = integrationRequest.data;
  const integrationTemplatesData = integrationTemplatesRequest.data;
  const integrationProvidersOptionsData = integrationProvidersOptionsRequest.data;

  const onSubmitComplete = useCallback(() => {
    setState((prev) => ({
      ...prev,
      refreshFlag: true
    }));
  }, []);

  const onSearchChange = useCallback((text) => {
    setState((prev) => ({
      ...prev,
      searchText: text
    }));
  }, []);

  const onIntegrationTemplateRemove = useCallback((template) => {
    setState((prev) => ({ ...prev, confirmModal: { data: template } }));
  }, []);
  const onIntegrationTemplateRemoveAccept = useCallback(
    async (template) => {
      try {
        const integrationTemplateResourceUrl = `/v1/integrations/${template.integrationId}/templates/${template.templateId}`;
        const response = await api.fetch(integrationTemplateResourceUrl, {
          method: "DELETE"
        });
        if (!response.ok) {
          console.error("Unexpected response", response);
          throw new Error("Unexpected response");
        }
      } catch (error) {
        console.error("Integration template remove internal error", error);
      } finally {
        setState((prev) => ({ ...prev, refreshFlag: true, confirmModal: null }));
      }
    },
    [api]
  );
  const onIntegrationTemplateRemoveCancel = useCallback(() => {
    setState((prev) => ({ ...prev, confirmModal: null }));
  }, []);

  const columns = [
    formatMessage({
      id: "integration.template.name",
      defaultMessage: "Name"
    }),
    formatMessage({
      id: "integration.template.language",
      defaultMessage: "Language"
    }),
    formatMessage({
      id: "integration.template.stream",
      defaultMessage: "Stream"
    }),
    ""
  ];

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (integrationData?.data && integrationTemplatesData?.data && integrationProvidersOptionsData?.data) {
      const integration = integrationData.data;
      const integrationTemplates = integrationTemplatesData.data;
      const integrationProvidersOptions = integrationProvidersOptionsData.data;
      setState((prev) => ({
        ...prev,
        refreshFlag: false,
        integration,
        integrationTemplates,
        integrationProvidersOptions
      }));
    }
  }, [isLoading, integrationData, integrationTemplatesData, integrationProvidersOptionsData]);

  const formData = { integrationProvidersOptions };

  let filteredIntegrationTemplates = integrationTemplates;
  if (!isLoading) {
    if (searchText !== "") {
      const needle = searchText.toUpperCase();
      filteredIntegrationTemplates = integrationTemplates.filter((integrationTemplate) => {
        return (
          integrationTemplate.name.toUpperCase().includes(needle) ||
          integrationTemplate.language.toUpperCase().includes(needle)
        );
      });
    }
  }

  const IntegrationTemplatesRows = useMemo(() => {
    const languages = getISO6392Languages();
    const providerOptions = integrationProvidersOptions.find((it) => it.name === integration.provider) || {
      settings: {}
    };
    // Avoids find in a loop by creating map in advance
    providerOptions.settings.regionsMap = {};
    if (providerOptions?.settings?.regions) {
      providerOptions.settings.regionsMap = providerOptions.settings.regions.reduce((acc, it) => {
        acc[it.region] = it;
        return acc;
      }, {});
    }
    return filteredIntegrationTemplates.map((template, index) => (
      <IntegrationTemplatesListItem
        key={template.templateId}
        itemIndex={index}
        integration={integration}
        integrationProviderOptions={providerOptions}
        template={template}
        languages={languages}
        pageRef={pageRef}
        onRemove={onIntegrationTemplateRemove}
      />
    ));
  }, [filteredIntegrationTemplates, integration, integrationProvidersOptions, onIntegrationTemplateRemove]);

  const searchTitle = integration?.name;

  return (
    <PageDisplay
      page="integration.templates"
      title={formatMessage({
        id: "integration.templates",
        defaultMessage: "Integration Templates"
      })}
      error={error}
      pageTitle={
        formatMessage({
          id: "integration.templates",
          defaultMessage: "Integration Templates"
        }) +
        " - " +
        title
      }
      ref={pageRef}
      history={history}
    >
      <SearchFilter title={searchTitle} doSearch={onSearchChange} />

      <TableDisplay
        table="integration.templates"
        containerClass="table-responsive integration-templates-list"
        columns={columns}
        rows={IntegrationTemplatesRows}
        loading={isLoading}
      />
      <hr />

      <FormBuilder
        api={api}
        form="NewIntegrationTemplate"
        action={onSubmitComplete}
        formSetup={formSetup}
        formData={formData}
        pageRef={pageRef}
        intl={intl}
      />

      <Modal
        isOpen={!!confirmModal}
        heading={formatMessage({
          id: "integration.template.remove.confirm",
          defaultMessage: "Delete Integration Template"
        })}
        text={formatMessage({
          id: "are.you.sure",
          defaultMessage: "Are you sure?"
        })}
        actionText={formatMessage({
          id: "delete",
          defaultMessage: "Delete"
        })}
        cancelText={formatMessage({
          id: "cancel",
          defaultMessage: "Cancel"
        })}
        destructive
        data={confirmModal ? confirmModal.data : null}
        action={onIntegrationTemplateRemoveAccept}
        cancel={onIntegrationTemplateRemoveCancel}
      />
    </PageDisplay>
  );
};

export default injectIntl(IntegrationTemplates);
