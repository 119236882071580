import React from "react";
import { FormattedMessage } from "react-intl";

import "./FixtureDetails.scss";

const FixtureDetails = (props) => {
  const { data } = props;

  return (
    <React.Fragment>
      <div className="fixture-details col-lg-8">
        <div className="fixture-debug col-md-12">
          <span data-field="fixtureId">
            <i className="fas fa-key"></i> {data.fixtureId}
          </span>
        </div>
        <div className="fixture-competitors col-md-6">
          <h5>
            <FormattedMessage id="team.home" defaultMessage="Home Team" description="Home Team" />
          </h5>
          <span>{data.teamHome}</span>
          <h5>
            <FormattedMessage id="team.away" defaultMessage="Away Team" description="Away Team" />
          </h5>
          <span>{data.teamAway}</span>
        </div>
        <div className="fixture-date col-md-6">
          <h5>
            <FormattedMessage id="venue" defaultMessage="Venue" description="Venue" />
          </h5>
          <span>{data.venueName}</span>
          <h5>
            <FormattedMessage id="time.start" defaultMessage="Start Time" description="Start Time" />
          </h5>
          <span>{data.startTimeLocal.replace("T", " ").replace(":00", "")}</span>
          <span className="fixture-timezone">{data.timezone}</span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FixtureDetails;
