import React, { useCallback, useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";

import { useApi } from "../Base/Hooks/api";
import { useFetch } from "../Base/Hooks/fetch";
import formSetup from "./formSetup";

import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import SearchFilter from "../Base/Shared/SearchFilter";

import { FormBuilder } from "@atriumsports/coreui";

import "./IntegrationsView.scss";

const IntegrationsListItem = injectIntl(({ integration, intl }) => {
  const { formatMessage } = intl;
  return (
    <tr key={integration.integrationId} data-integration-id={integration.integrationId}>
      <td width="100%">
        <Link to={`/configuration/integrations/${integration.integrationId}/edit`} className="link">
          {integration.name}
        </Link>
      </td>
      <td>
        <span className={`badge badge-${integration.active ? "success" : "danger"}`}>
          {integration.active
            ? formatMessage({ id: "integration.active", defaultMessage: "Active" })
            : formatMessage({ id: "integration.inactive", defaultMessage: "Inactive" })}
        </span>
      </td>
      <td>
        <strong>{integration.provider}</strong>
      </td>
      <td className="controls-1">
        <Link
          title={formatMessage({ id: "integration.templates", defaultMessage: "Manage integration templates" })}
          to={`/configuration/integrations/${integration.integrationId}/templates`}
          className="btn btn-outline-secondary btn-sm"
          data-action="integration.templates"
        >
          <i className="fas fa-list" />
        </Link>
        <Link
          title={formatMessage({ id: "integration.edit", defaultMessage: "Edit integration" })}
          to={`/configuration/integrations/${integration.integrationId}/edit`}
          className="btn btn-outline-primary btn-sm"
          data-action="integration.edit"
        >
          <i className="fas fa-pen" />
        </Link>
      </td>
    </tr>
  );
});

const IntegrationsView = (props) => {
  const { title, updateState, history, intl } = props;
  const { formatMessage } = intl;

  const pageRef = useRef();
  const api = useApi();

  const [state, setState] = useState({
    refreshFlag: true,
    searchText: "",
    integrations: [],
    integrationProvidersOptions: []
  });

  const { refreshFlag, searchText, integrations, integrationProvidersOptions } = state;

  const integrationsRequest = useFetch("/v1/integrations", "", refreshFlag);
  const integrationProvidersOptionsRequest = useFetch("/v1/integration/providers", "", refreshFlag);

  const isLoading = integrationsRequest.loading || integrationProvidersOptionsRequest.loading;
  const error = integrationsRequest.error || integrationProvidersOptionsRequest.error;

  const integrationsData = integrationsRequest.data;
  const integrationProvidersOptionsData = integrationProvidersOptionsRequest.data;

  const onSubmitComplete = useCallback(() => {
    setState((prev) => ({
      ...prev,
      refreshFlag: true
    }));
  }, []);

  const onSearchChange = useCallback((text) => {
    setState((prev) => ({
      ...prev,
      searchText: text
    }));
  }, []);

  const columns = [
    formatMessage({
      id: "integration.name",
      defaultMessage: "Name"
    }),
    "",
    formatMessage({
      id: "integration.provider",
      defaultMessage: "Provider"
    }),
    ""
  ];

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (integrationsData?.data && integrationProvidersOptionsData?.data) {
      const integrations = integrationsData.data;
      const integrationProvidersOptions = integrationProvidersOptionsData.data;
      setState((prev) => ({
        ...prev,
        refreshFlag: false,
        integrations,
        integrationProvidersOptions
      }));
    }
  }, [isLoading, integrationsData, integrationProvidersOptionsData]);

  const formData = { integrationProvidersOptions };

  let filteredIntegrations = integrations;
  if (!isLoading) {
    if (searchText !== "") {
      const needle = searchText.toUpperCase();
      filteredIntegrations = integrations.filter((integration) => {
        return integration.name.toUpperCase().includes(needle) || integration.provider.toUpperCase().includes(needle);
      });
    }
  }

  const IntegrationRows = filteredIntegrations.map((integration, index) => (
    <IntegrationsListItem
      key={integration.integrationId}
      itemIndex={index}
      integration={integration}
      updateState={updateState}
      pageRef={pageRef}
    />
  ));

  return (
    <PageDisplay
      page="integrations"
      title={formatMessage({
        id: "integrations",
        defaultMessage: "Integrations"
      })}
      error={error}
      pageTitle={
        formatMessage({
          id: "integrations",
          defaultMessage: "Integrations"
        }) +
        " - " +
        title
      }
      ref={pageRef}
      history={history}
    >
      <SearchFilter doSearch={onSearchChange} />
      <TableDisplay
        table="integrations"
        containerClass="table-responsive integrations-list"
        columns={columns}
        rows={IntegrationRows}
        loading={isLoading}
      />
      <hr />

      <FormBuilder
        api={api}
        form="NewIntegration"
        action={onSubmitComplete}
        formSetup={formSetup}
        formData={formData}
        pageRef={pageRef}
        intl={intl}
      />
    </PageDisplay>
  );
};

export default injectIntl(IntegrationsView);
