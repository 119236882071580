import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "reactstrap";

import { useInterval } from "../../Base/Hooks/interval";
import { EnvironmentContext, environments } from "../../../Config/EnvironmentContext";
import bars from "../Images/bars.jpg";
import notfound from "../Images/notfound.png";
import preview from "../Images/preview.jpg";

const maxImageSize = {
  width: 1000,
  height: 562,
};

const DashboardImage = (props) => {
  const { data, image, defaultImage } = props;
  const [displayImage, setDisplayImage] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [silentLoading, setSilentLoading] = useState(false);
  const env = useContext(EnvironmentContext);

  useEffect(() => {
    if (data) {
      checkImage(image, defaultImage);
    }
    // eslint-disable-next-line
  }, [data]);

  useInterval(() => {
    if (data) {
      setSilentLoading(true);
      checkImage(image, defaultImage);
    }
  }, 10000);

  const checkImage = (image, defaultImage) => {
    setIsLoading(true);

    const img = new Image();

    if (data[image]) {
      img.src = data[image];
    } else if (data.monitorURL) {
      const format = "image/jpeg";
      const tempThumbnailUrl = new URL(environments.thumbnailer[env] + "/generate");
      tempThumbnailUrl.searchParams.set("playlistUrl", data.monitorURL);
      tempThumbnailUrl.searchParams.set("width", maxImageSize.width);
      tempThumbnailUrl.searchParams.set("height", maxImageSize.height);
      tempThumbnailUrl.searchParams.set("mime", format);
      tempThumbnailUrl.searchParams.set("time", -4);
      tempThumbnailUrl.searchParams.set("timestamp", Date.now());
      img.src = tempThumbnailUrl.toString();
    }

    img.onload = function () {
      setDisplayImage(img.src + "?t=" + Date.now());
      setDisplayName(null);
      setIsLoading(false);
    };

    img.onerror = function () {
      if (defaultImage === "bars") {
        setDisplayImage(bars);
        setDisplayName(data.name);
      } else {
        if (defaultImage === "preview") {
          setDisplayImage(preview);
        } else {
          setDisplayImage(notfound);
        }
        setDisplayName(null);
      }
      setIsLoading(false);
      setSilentLoading(false);
    };
  };

  return (
    <React.Fragment>
      {(!isLoading || silentLoading) && (
        <React.Fragment>
          <img src={displayImage} alt={image} />
          {displayName && <span className="displayName">{displayName}</span>}
        </React.Fragment>
      )}
      {isLoading && !silentLoading && <Spinner size="md" color="white" />}
    </React.Fragment>
  );
};

export default DashboardImage;
