import React, { useEffect, useState, useRef } from "react";
import { Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useFetch } from "../Base/Hooks/fetch";

import CompetitionRow from "./CompetitionRow";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import TableDisplay from "../Base/Shared/TableDisplay";
import SearchFilter from "../Base/Shared/SearchFilter";

const Competitions = (props) => {
  const { title, updateState, history, match, intl } = props;
  const { formatMessage } = intl;
  const [pageTitle, setPageTitle] = useState("");
  const [searchText, setSearchText] = useState("");
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [tableData, setTableData] = useState();
  const pageRef = useRef();
  const { error, loading, data } = useFetch(
    "/v1/" + match.params.sport + "/organizations/" + match.params.organizationId + "/competitions",
    "",
    refreshFlag
  );
  const columns = [
    formatMessage({
      id: "competitionname",
      defaultMessage: "Competition Name"
    }),
    ""
  ];

  useEffect(() => {
    if (data) {
      if (data && data.data.length > 0) {
        setPageTitle(data.data[0].organizationName);
      }
      let filteredData = data !== null ? data.data : [];

      if (searchText !== "") {
        filteredData = filteredData.filter((result) => {
          return result.competitionName.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
        });
      }
      let tableList = filteredData.map((result, index) => (
        <CompetitionRow key={index} itemIndex={index} result={result} updateState={updateState} match={match} />
      ));
      setTableData(tableList);
      setRefreshFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText]);

  return (
    <React.Fragment>
      <PageDisplay
        title={
          pageTitle +
          " " +
          formatMessage({
            id: "competitions",
            defaultMessage: "Competitions"
          })
        }
        error={error}
        pageTitle={
          pageTitle +
          formatMessage({
            id: "competitions",
            defaultMessage: "Competitions"
          }) +
          " - " +
          title
        }
        ref={pageRef}
        history={history}
      >
        <SearchFilter doSearch={setSearchText} />
        <TableDisplay
          table="competitions"
          containerClass="table-responsive"
          columns={columns}
          rows={tableData}
          loading={loading}
        />
      </PageDisplay>
    </React.Fragment>
  );
};

export default injectIntl(Competitions);
