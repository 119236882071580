import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { Alert, Spinner } from "reactstrap";
import { injectIntl } from "react-intl";

import { useApi } from "../Base/Hooks/api";
import { useFetch } from "../Base/Hooks/fetch";
import PageDisplay from "../Base/PageDisplay/PageDisplay";
import formSetup from "./formSetup";

import { FormBuilder } from "@atriumsports/coreui";

const IntegrationEdit = (props) => {
  const { title, history, match, intl } = props;

  const [state, setState] = useState({
    refreshFlag: true,
    integration: null,
    integrationTest: null,
    integrationProvidersOptions: []
  });

  const api = useApi();

  const { refreshFlag, integration, integrationProvidersOptions, integrationTest } = state;

  const integrationRequest = useFetch(`/v1/integrations/${match.params.integrationId}`, "", refreshFlag);
  const integrationProvidersOptionsRequest = useFetch("/v1/integration/providers", "", refreshFlag);

  const { formatMessage } = intl;

  const isLoading = integrationRequest.loading || integrationProvidersOptionsRequest.loading;
  const error = integrationRequest.error || integrationProvidersOptionsRequest.error;

  const integrationData = integrationRequest.data;
  const integrationProvidersOptionsData = integrationProvidersOptionsRequest.data;

  const onCustomAction = useCallback(
    async (action, formik) => {
      formik.setSubmitting(true);
      try {
        const response = await api.fetch(`/v1/integrations/${match.params.integrationId}/test`, { method: "POST" });
        const body = await response.json();
        if (response.ok) {
          setState((prev) => ({ ...prev, integrationTest: body.data }));
        } else {
          setState((prev) => ({ ...prev, integrationTest: { success: false, message: body.error.message } }));
        }
      } catch (error) {
        setState((prev) => ({ ...prev, integrationTest: { success: false, message: error } }));
      } finally {
        formik.setSubmitting(false);
      }
    },
    [api, match.params.integrationId]
  );

  const onFormAction = useCallback(() => {
    setState((prev) => ({ ...prev, refreshFlag: true }));
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (integrationData?.data && integrationProvidersOptionsData?.data) {
      const integration = integrationData.data;
      const integrationProvidersOptions = integrationProvidersOptionsData.data;
      setState((prev) => ({
        ...prev,
        refreshFlag: false,
        integration,
        integrationProvidersOptions
      }));
    }
  }, [isLoading, integrationData, integrationProvidersOptionsData]);

  const formMessage = useMemo(() => {
    if (!integrationTest) {
      return null;
    }
    if (integrationTest.success) {
      return (
        <Alert color="success">
          <i className="fa fa-check" aria-hidden="true"></i> &nbsp;
          {formatMessage({
            id: "integration.test.success",
            defaultMessage: "Integration test successful"
          })}{" "}
          &nbsp; &mdash; &nbsp; <code>{JSON.stringify(integrationTest.message, null, 2)}</code>
        </Alert>
      );
    }
    return (
      <Alert color="danger">
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> &nbsp;
        {formatMessage({
          id: "integration.test.error",
          defaultMessage: "Integration test failure"
        })}
        &nbsp; &mdash; &nbsp; <code>{JSON.stringify(integrationTest.message, null, 2)}</code>
      </Alert>
    );
  }, [integrationTest, formatMessage]);

  const pageTitle =
    title +
    " - " +
    formatMessage({
      id: "integration.update",
      defaultMessage: "Update Integration"
    });

  const formData = { ...integration, integrationProvidersOptions };

  return (
    <PageDisplay
      page="integration.edit"
      title={integration?.name}
      error={error}
      pageTitle={pageTitle}
      history={history}
    >
      {integration && (
        <div>
          <FormBuilder
            api={api}
            form="EditIntegration"
            action={onFormAction}
            formSetup={formSetup}
            formData={formData}
            onCustomAction={onCustomAction}
            formMessage={formMessage}
          />
          {error}
        </div>
      )}
    </PageDisplay>
  );
};

export default injectIntl(IntegrationEdit);
