import React from "react";
import { Container } from "reactstrap";

import AddSubscriptionFixtureRow from "./AddSubscriptionFixtureRow";

const AddSubscriptionFixtureList = (props) => {
  const { data, options, organizationId, onSubscriptionAdded } = props;

  return (
    <Container>
      {data.map((result, index) => (
        <AddSubscriptionFixtureRow
          key={index}
          itemIndex={index}
          data={result}
          options={options}
          organizationId={organizationId}
          onSubscriptionAdded={onSubscriptionAdded}
        />
      ))}
    </Container>
  );
};

export default AddSubscriptionFixtureList;
