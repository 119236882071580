import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Row } from "reactstrap";

import { useApi } from "../../Base/Hooks/api";
import formSetup from "../formSetup";

import { FormBuilder } from "@atriumsports/coreui";

const AddSubscriptionFixtureRow = (props) => {
  const { data, options, organizationId, itemIndex, onSubscriptionAdded } = props;
  const [subscriptionSettings, setSubscriptionSettings] = useState();
  const [submitSuccess, setSubmitSuccess] = useState();
  const api = useApi();

  const { fixtureId } = data || {};

  useEffect(() => {
    if (data && options) {
      let stream = findStream(options, data);
      let subscriptionSettings = null;
      if (stream) {
        subscriptionSettings = {
          provider: stream.provider,
          locale: stream.locale,
          sourceNumber: stream.sourceNumber,
          feedType: stream.feedType,
          outputFormat: options.outputFormat,
          muxRate: options.muxRate,
          audio: options.audio,
          content: options.content,
          fixtureId: data.fixtureId,
          organizationId: organizationId,
          subscriptionName: options.subscriptionName,
          streamName: options.streamName,
          outputURL: options.outputURL,
          // Default for all formats
          outputResolution: stream.inputResolution
        };
        // Formats with output resolution
        if (["RTMP", "SRT"].includes(options.outputFormat)) {
          // Prevent up-scaling
          if (getResolution(options.outputResolution) <= getResolution(stream.inputResolution)) {
            subscriptionSettings.outputResolution = options.outputResolution;
          }
        }
      } else {
        console.warn("Unable to find stream in list of available streams", { options, streams: data });
      }
      setSubscriptionSettings(subscriptionSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, data]);

  function getResolution(value) {
    let valueParts = value.split("x");
    if (valueParts.length > 1) {
      valueParts = valueParts[1].split("@");
    }
    return parseInt(valueParts[0]);
  }

  function findStream(options, data) {
    let returnStream = null;
    data.availableStreams.forEach((stream) => {
      if (stream.content === options.content && stream.feedType === options.feedType) {
        returnStream = stream;
      }
    });
    return returnStream;
  }

  const onFormAction = useCallback(
    (value, response) => {
      if (response) {
        setSubmitSuccess(true);
        if (onSubscriptionAdded) {
          onSubscriptionAdded(fixtureId, value, response);
        }
      }
    },
    [fixtureId, onSubscriptionAdded]
  );

  return (
    <Row className={"addSubscriptionRow success-" + submitSuccess}>
      <h5>
        {data.teamHome} v. {data.teamAway} ({data.startTimeLocal} @ {data.venueName})
      </h5>
      {subscriptionSettings ? (
        <FormBuilder
          api={api}
          form="SubscriptionRow"
          formSetup={formSetup}
          formIndex={itemIndex}
          action={onFormAction}
          formData={subscriptionSettings}
        />
      ) : (
        <span className="no-streams">
          <FormattedMessage
            id="streams.input.none"
            defaultMessage="No Compatible Input Streams"
            description="No Compatible Input Streams"
          />
        </span>
      )}
    </Row>
  );
};

export default AddSubscriptionFixtureRow;
