import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import Modal from "../Base/Modal/Modal";
import { useDelete } from "../Base/Hooks/delete";

const BundleRow = (props) => {
  const { itemIndex, result, pageRef, setRefreshFlag, intl } = props;
  const [deleteModalText, setDeleteModalText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const [refreshDeleteData, setRefreshDeleteData] = useState(false);
  const [bundleDeleteData, setBundleDeleteData] = useState(null);
  const { deleteError, deleteLoading, deleteData } = useDelete(
    "/v1/bundles/" + bundleDeleteData,
    "",
    refreshDeleteData
  );
  const { formatMessage } = intl;

  useEffect(() => {
    if (deleteError) {
      pageRef.current.addFlash(
        formatMessage({
          id: "delete.failed",
          defaultMessage: "Delete Failed"
        }),
        "danger",
        10
      );
    } else {
      if (deleteData) {
        pageRef.current.addFlash(
          formatMessage({
            id: "bundle.delete.success",
            defaultMessage: "Bundle deleted successfully"
          }),
          "success",
          10
        );
        setRefreshFlag(true);
      }
    }
    setRefreshDeleteData(false);
    setBundleDeleteData(null);
    setRefreshDeleteData(false);
    // eslint-disable-next-line
  }, [deleteData, deleteError, pageRef]);

  function showDeleteBundle(param) {
    setDeleteModalText(
      formatMessage(
        {
          id: "bundle.delete.confirm",
          defaultMessage: "You are about to delete bundle " + param + ". Is this OK?"
        },
        { item: param }
      )
    );
    setDeleteModalOpen(true);
    setDeleteItemId(param);
  }

  function cancelModal() {
    setDeleteModalOpen(false);
  }

  function deleteBundle() {
    setBundleDeleteData(deleteItemId);
    setRefreshDeleteData(true);
    setDeleteModalOpen(false);
  }

  return (
    <tr key={result.bundleId} className={"bundle-row deleting-" + deleteLoading} data-bundle-id={result.bundleId}>
      <td>{result.bundleId}</td>
      <td>
        <Link to={"/configuration/bundles/" + result.bundleId} className="link">
          {result.name}
        </Link>
      </td>

      <td className="text-right controls-3">
        {" "}
        <Link
          to={"/configuration/bundles/" + result.bundleId}
          className="btn btn-outline-secondary btn-sm"
          title="items"
          data-action="bundle.items"
        >
          <i className="fas fa-list" />
        </Link>
        {"  "}
        <Link
          to={"/configuration/bundles/" + result.bundleId + "/edit/"}
          className="btn btn-outline-primary btn-sm"
          title="edit"
          data-action="bundle.edit"
        >
          <i className="fas fa-pen" />
        </Link>{" "}
        <Button
          outline
          color="danger"
          title="delete"
          data-action="bundle.delete"
          size="sm"
          onClick={(e) => showDeleteBundle(result.bundleId, e)}
        >
          <i className="fas fa-minus" />
        </Button>
        <Modal
          modal="bundle.delete"
          isOpen={deleteModalOpen}
          heading={formatMessage({
            id: "bundle.delete",
            defaultMessage: "Delete Bundle?"
          })}
          text={deleteModalText}
          actionText={formatMessage({
            id: "delete",
            defaultMessage: "Delete"
          })}
          cancelText={formatMessage({
            id: "cancel",
            defaultMessage: "Cancel"
          })}
          destructive
          action={deleteBundle}
          cancel={cancelModal}
        />
      </td>
    </tr>
  );
};

export default injectIntl(BundleRow);
