import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Container } from "reactstrap";
import { injectIntl, FormattedMessage } from "react-intl";

import { useApi } from "../Base/Hooks/api";
import { useFetch } from "../Base/Hooks/fetch";
import TableDisplay from "../Base/Shared/TableDisplay";
import Modal from "../Base/Modal/Modal";

import { useUserAccessRestrictions } from "./utils";
import formSetup from "./formSetup";
import { FormBuilder } from "@atriumsports/coreui";

import "./FixtureIntegrations.scss";
import { getISO6392Languages } from "../../Config/locales";

const FIXTURE_INTEGRATION_COMPLETE = "video_output.create.complete";

export const FixtureIntegrations = injectIntl(
  ({
    customerId,
    sport,
    organizationId,
    seasonId,
    fixtureData,
    integrationOptions,
    intl,
    pageRef,
    setRefreshFlag,
    isStaffScope
  }) => {
    const [state, setState] = useState({
      withSubscriptionForm: false,
      subscriptionFormData: {},
      subscriptionIntegration: {},
      deleteModalData: undefined,
      deleteModalOpen: false,
      deleteModalText: "",
      tableData: [],
      refreshFlag: true
    });

    const { formatMessage } = intl;
    const columns = [
      formatMessage({
        id: "integration",
        defaultMessage: "Integration"
      }),
      formatMessage({
        id: "details",
        defaultMessage: "Details"
      })
    ];

    const {
      tableData,
      refreshFlag,
      deleteModalData,
      deleteModalOpen,
      deleteModalText,
      withSubscriptionForm,
      subscriptionFormData,
      subscriptionIntegration
    } = state;
    const { fixtureId } = fixtureData;

    const api = useApi();
    const fixtureIntegrationsRequest = useFetch(
      "/v1/" + sport + "/organizations/" + organizationId + "/fixtures/" + fixtureId + "/integrations",
      "",
      refreshFlag
    );
    const loading = fixtureIntegrationsRequest.loading;
    const error = fixtureIntegrationsRequest.error;
    const fixtureIntegrationsData = fixtureIntegrationsRequest.data;

    const onFixtureIntegrationDestinationAdd = useCallback(
      (integration, setup, config, key) => {
        // console.debug({ integration, setup, config, key });
        setState((prev) => ({
          ...prev,
          withSubscriptionForm: true,
          subscriptionFormData: {
            // Copy of original input properties
            ...config.stream,
            // IMPORTANT - Overrides original stream input merged above with TRANSFORM return stream
            sourceNumber: config.inputSourceNumber,
            provider: config.inputProvider,
            // User configurable
            streamName: [config.stream.subscriptionName, config.language].join("-"),
            subscriptionName: [config.stream.subscriptionName, config.language].join("-"),
            fixtureId
          },
          subscriptionIntegration: {
            integration,
            setup,
            config
          }
        }));
      },
      [fixtureId]
    );

    const onFixtureIntegrationRemove = useCallback(
      (integration, setup, config, key) => {
        setState((prev) => ({
          ...prev,
          deleteModalOpen: true,
          deleteModalData: {
            integration,
            key
          },
          deleteModalText: formatMessage(
            {
              id: "fixture.integration.delete.confirm",
              defaultMessage: 'You are about to delete "{item}" integration. Is this OK?'
            },
            { item: config.name }
          ),
          integration
        }));
      },
      [formatMessage]
    );

    const onDeleteAccept = useCallback(
      async (data, modal) => {
        modal.setPending(true);
        let success = false;
        let errorMessage = "";
        try {
          const response = await api.fetch(
            `/v1/${sport}/organizations/${organizationId}/fixtures/${fixtureId}/integrations/${data.integration.integrationId}`,
            {
              method: "DELETE",
              data: {
                key: data.key
              }
            }
          );
          if (response.ok) {
            success = true;
          } else {
            console.error("Unexpected response", response);
            errorMessage = response.statusText;
          }
        } catch (error) {
          console.error("Integration setup remove internal error", error);
        } finally {
          modal.setPending(false);
          if (success) {
            setState((prev) => ({
              ...prev,
              refreshFlag: true,
              deleteModalOpen: false,
              deleteModalData: null,
              deleteModalText: ""
            }));
          } else {
            setState((prev) => ({
              ...prev,
              deleteModalText: errorMessage
            }));
          }
        }
      },
      [api, sport, organizationId, fixtureId]
    );

    const onDeleteReject = useCallback(() => {
      setState((prev) => ({
        ...prev,
        deleteModalOpen: false,
        deleteModalData: null,
        deleteModalText: ""
      }));
    }, []);

    useEffect(() => {
      if (loading) {
        return;
      }
      if (fixtureIntegrationsData) {
        setState((prev) => ({
          ...prev,
          refreshFlag: false,
          tableData: fixtureIntegrationsData?.data || []
        }));
      }
    }, [loading, fixtureIntegrationsData]);

    const tableRows = useMemo(() => {
      const languages = getISO6392Languages();
      const integrationOptionsMap = integrationOptions.reduce((acc, it) => {
        acc[it.integrationId] = it;
        return acc;
      }, {});
      return tableData.map((it) => {
        const integration = integrationOptionsMap[it.integrationId];
        const setup = it.setup || [];
        return (
          <tr key={integration.integrationId}>
            <td>
              <strong>{integration.name}</strong>
              <br />
              <span className="badge-pill badge badge-primary">{integration.provider}</span>
            </td>
            <td width="100%">
              {setup ? (
                <table className="table fixture-integrations-setup-table">
                  <tbody>
                    {Object.keys(setup).map((key, index) => {
                      const config = setup[key];
                      const language = languages.mappings[config.language];
                      const usable = config.state === FIXTURE_INTEGRATION_COMPLETE;
                      return (
                        <tr key={key} data-integration-setup-key={key}>
                          <td>
                            <div
                              className="fixture-integration-state"
                              data-integration-state={config.state}
                              title={usable ? "" : `Integration invalid state detected ${config.state}`}
                            >
                              {usable ? (
                                <span className="badge badge-pill badge-success">
                                  <i className="fa fa-thumbs-up"></i>
                                </span>
                              ) : (
                                <span className="badge badge-pill badge-danger">
                                  <i className="fa fa-thumbs-down"></i>
                                </span>
                              )}
                            </div>
                          </td>
                          <td>
                            <p>
                              <strong>{index + 1}.</strong> &nbsp; {config.name}
                            </p>
                            <strong>{language}</strong>
                          </td>
                          <td>
                            <a href={config.relation.Team.AnonymousAccessUri} target="_blank">
                              <FormattedMessage
                                id="fixture.integrations.commentator.openApp"
                                defaultMessage="Open commentator app"
                              />
                            </a>
                          </td>
                          <td>
                            <Button
                              color="outline-primary"
                              data-action="fixture.integration.destination.add"
                              onClick={() => onFixtureIntegrationDestinationAdd(integration, setup, config, key)}
                              disabled={!usable}
                              title={usable ? "Send enhanced stream to a destination of choice" : ""}
                            >
                              <i className="fas fa-share"></i>
                              &nbsp; Add destination
                            </Button>
                            &nbsp;
                            <Button
                              color="outline-danger"
                              data-action="fixture.integration.remove"
                              onClick={() => onFixtureIntegrationRemove(integration, setup, config, key)}
                            >
                              <i className="fas fa-minus"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <span>
                  <FormattedMessage id="fixture.integrations.empty" defaultMessage="No integrations have been setup" />
                </span>
              )}
            </td>
          </tr>
        );
      });
    }, [tableData, integrationOptions, onFixtureIntegrationRemove, onFixtureIntegrationDestinationAdd]);

    const onCustomAction = useCallback((action, formik) => {
      if (action.action === "subscriptions.add.close") {
        setState((prev) => ({
          ...prev,
          withSubscriptionForm: false,
          subscriptionFormData: {},
          subscriptionIntegration: {}
        }));
      }
    }, []);
    const adjustedFormSetup = useUserAccessRestrictions(formSetup, isStaffScope);

    return (
      <React.Fragment>
        <div className="row col col-md-12">
          <div className="col col-md-12 fixture-integrations-view">
            <h5>
              <FormattedMessage id="fixture.integrations" defaultMessage="Integrations" />
            </h5>
            <Modal
              preventDismissOnAccept
              isOpen={deleteModalOpen}
              heading={formatMessage({
                id: "fixture.integration.delete",
                defaultMessage: "Delete Fixture Integration?"
              })}
              text={deleteModalText}
              actionText={formatMessage({
                id: "delete",
                defaultMessage: "Delete"
              })}
              cancelText={formatMessage({
                id: "cancel",
                defaultMessage: "Cancel"
              })}
              destructive
              data={deleteModalData}
              action={onDeleteAccept}
              cancel={onDeleteReject}
            />
            <TableDisplay
              table="fixture.integrations"
              containerClass="table-responsive fixture-integrations-table-display"
              columns={columns}
              rows={tableRows}
              loading={loading}
            />
            {withSubscriptionForm ? (
              <Container className="subscription-container">
                <FormBuilder
                  title={
                    formatMessage({
                      id: "subscriptions.add.for",
                      defaultMessage: "Add Subscription for"
                    }) +
                    " " +
                    subscriptionIntegration.config.name
                  }
                  api={api}
                  form="AddSubscriptionForIntegration"
                  formSetup={adjustedFormSetup}
                  formData={subscriptionFormData}
                  pageRef={pageRef}
                  onCustomAction={onCustomAction}
                />
              </Container>
            ) : null}
          </div>
        </div>
        {error}
      </React.Fragment>
    );
  }
);
